import React, {useRef, useState} from "react";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {TipoDeCampoFonteDeDados} from "../../componets/FormularioCampoFonteDados";
import Select from "react-select";
import {TipoDeMascara} from "../../@types/models";
import {ApiService} from "../../services/ApiService";
import {useDispatch, useSelector} from "react-redux";
import {AppRootState} from "../../reducers";
import {ICampoDaFonteDeDados, IFonteDeDados,} from "../../models/FonteDeDados";
import {setFontes} from "../../actions/ProjetoActions";
import {DragDropContext, Draggable, Droppable, DropResult,} from "react-beautiful-dnd";
import {Loading} from "../../componets/Loading";

function CellTextEditor(props: {
    value: string;
    onChange: (text: string) => void;
}) {
    const [showInput, setShowInput] = useState(false);
    const [valor, setValor] = useState(props.value);
    const inputEl = useRef(
        null as any
    ) as React.MutableRefObject<HTMLInputElement>;

    const commit = () => {
        const v = String(valor || "").trim();
        if (v) {
            props.onChange(v);
        } else {
            setValor(props.value);
        }
        setValor(v);
        setShowInput(false);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            commit();
        }
        if (event.key === "Escape") {
            setValor(props.value);
            setShowInput(false);
        }
    };
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
        event.target.select();
    return (
        <>
            {!showInput && (
                <div
                    onClick={() => {
                        setShowInput(true);
                        if (inputEl) {
                            inputEl.current?.focus();
                        }
                    }}
                >
                    <i className="fa fa-pencil text-info" style={{cursor: "pointer"}}/>{" "}
                    {props.value}
                </div>
            )}
            {showInput && (
                <div className="form-group w-100">
                    <input
                        autoFocus
                        className="form-control w-100"
                        value={valor}
                        onChange={(e) => {
                            setValor(e.target.value);
                        }}
                        ref={inputEl}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                        onBlur={() => {
                            commit();
                        }}
                    />
                </div>
            )}
        </>
    );
}

function CellMaskSelect(props: {
    value: string;
    onChange: (text: string) => void;
    disabled?: boolean;
}) {
    const [showSelect, setShowSelect] = useState(false);
    const [valor, setValor] = useState({
        label: props.value.toString(),
        value: props.value.toString(),
    });

    const commit = (valorCommit: string) => {
        if (valorCommit) {
            props.onChange(valorCommit);
            setValor({
                label: valorCommit,
                value: valorCommit,
            });
        } else {
            setValor({
                label: props.value,
                value: props.value,
            });
        }
        setShowSelect(false);
    };
    return (
        <>
            {!showSelect && (
                <div
                    onClick={() => {
                        if (!props.disabled) setShowSelect(true);
                    }}
                >
                    {!props.disabled && (
                        <i
                            className="fa fa-pencil text-info"
                            style={{cursor: "pointer"}}
                        />
                    )}{" "}
                    {props.value || "Nenhuma"}
                </div>
            )}
            {showSelect && (
                <div className="form-group w-100">
                    <Select
                        autoFocus
                        className="w-100"
                        value={valor}
                        onBlur={() => {
                            setValor({
                                label: props.value,
                                value: props.value,
                            });
                            setShowSelect(false);
                        }}
                        onChange={(e: any) => {
                            commit(e.value);
                        }}
                        options={Object.keys(TipoDeMascara).map((x: any) => {
                            return {
                                label: (TipoDeMascara as any)[x],
                                value: (TipoDeMascara as any)[x],
                            };
                        })}
                    />
                </div>
            )}
        </>
    );
}

export function ProjetoConfigColunasPage(
    props: PropsProjetoPages & { idFonteDeTrabalho: string }
) {
    const dispatch = useDispatch();
    const fonte = useSelector<AppRootState, IFonteDeDados | undefined>(
        ({projeto}) =>
            projeto.fontes[props.projeto.id].find(
                (x) => x.id === props.idFonteDeTrabalho
            )
    );

    const [fonteDeTrabalho, setFonteDeTrabalho] = useState(fonte);

    const [loading, setLoading] = useState(false);

    const atualizarFonteDeTrabalho = async () => {
        if (fonteDeTrabalho) {
            setLoading(true);
            await ApiService.put(
                "/fonte-de-dados/" + props.projeto.id,
                fonteDeTrabalho.id,
                fonteDeTrabalho
            );
            dispatch(
                setFontes(
                    [
                        ...props.fontes.filter((x) => x.id !== fonteDeTrabalho.id),
                        fonteDeTrabalho,
                    ],
                    props.projeto.id
                )
            );
            setLoading(false);
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        let tempData = Array.from(fonteDeTrabalho?.ordenacaoCampos || []);
        let [source_data] = tempData.splice(result.source.index, 1);
        tempData.splice(result.destination.index, 0, source_data);
        setFonteDeTrabalho(
            (x) =>
                ({
                    ...x,
                    ordenacaoCampos: tempData,
                } as IFonteDeDados)
        );
    };

    const atualizarCampo = (campo: ICampoDaFonteDeDados, index: number) => {
        const campos = [...(fonteDeTrabalho?.campos || [])];
        campos[index] = campo;
        setFonteDeTrabalho(
            (x) =>
                ({
                    ...x,
                    campos: campos,
                } as IFonteDeDados)
        );
    };

    const atualizarCampoFonte = (
        indexCampo: number,
        campoFonte: any,
        idCampoFonte: number
    ) => {
        const campo = {
            ...fonteDeTrabalho?.campos[indexCampo],
        } as ICampoDaFonteDeDados;
        if (campo.fonte) {
            campo.fonte.campos[idCampoFonte] = campoFonte;
            atualizarCampo(campo, indexCampo);
        }
    };

    const ordenacao = fonteDeTrabalho?.ordenacaoCampos;

    if (fonteDeTrabalho && ordenacao) {
        return (
            <LayoutProjeto
                projeto={props.projeto}
                toggleMenu={props.toggleMenu}
                headlerToggleMenu={props.headlerToggleMenu}
                titulo={"Configuração de colunas da fonte " + fonteDeTrabalho.nome}
                administrador={props.administrador}
            >
                {loading && <Loading/>}
                <p>
                    <button
                        className="btn btn-success"
                        onClick={() => atualizarFonteDeTrabalho()}
                    >
                        <i className="fa fa-save"/> Salvar
                    </button>
                    {" "}
                    |{" "}
                    <button
                        className="btn btn-warning"
                        onClick={() => {
                            setFonteDeTrabalho(fonte);
                        }}
                    >
                        <i className="fa fa-rotate-left"/> Resetar
                    </button>
                </p>
                <DragDropContext onDragEnd={handleDragEnd} nonce="">
                    <div className="table-responsive vh-100">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Ordenação</th>
                                <th>Nome</th>
                                <th>Tipo</th>
                                <th>Mascara</th>
                                <th>Fonte de Relacionamento</th>
                            </tr>
                            </thead>
                            <Droppable droppableId="droppable-1">
                                {(provider) => (
                                    <tbody
                                        className="text-capitalize"
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}
                                    >
                                    {ordenacao.map((x, i) => {
                                        const campoIdx = fonteDeTrabalho.campos.findIndex(
                                            (c) =>
                                                c.id === x ||
                                                !!c.fonte?.campos.find((ce) => ce.key === x)
                                        );
                                        const campo = fonteDeTrabalho.campos[campoIdx];
                                        const fonte = props.fontes.find(
                                            (f) => f.id === campo?.fonte?.id
                                        );
                                        if (campo?.id === x) {
                                            return (
                                                <Draggable key={i} draggableId={String(i)} index={i}>
                                                    {(provider) => (
                                                        <tr
                                                            {...provider.draggableProps}
                                                            ref={provider.innerRef}
                                                        >
                                                            <td {...provider.dragHandleProps}> =</td>
                                                            <td>
                                                                <CellTextEditor
                                                                    onChange={(texto) => {
                                                                        if (campo.nome !== texto) {
                                                                            campo.nome = texto;
                                                                            atualizarCampo(
                                                                                {
                                                                                    ...campo,
                                                                                    nome: texto,
                                                                                } as ICampoDaFonteDeDados,
                                                                                campoIdx
                                                                            );
                                                                        }
                                                                    }}
                                                                    value={campo.nome}
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    TipoDeCampoFonteDeDados[
                                                                        String(
                                                                            campo.tipo
                                                                        ) as keyof typeof TipoDeCampoFonteDeDados
                                                                        ]
                                                                }
                                                            </td>
                                                            <td>
                                                                {campo && (
                                                                    <CellMaskSelect
                                                                        disabled={
                                                                            campo.tipo.toString() === "DATA" ||
                                                                            campo.tipo.toString() ===
                                                                            "DATA_COM_HORARIO"
                                                                        }
                                                                        value={campo.tipoMascara || ""}
                                                                        onChange={(v) => {
                                                                            if (campo.tipoMascara !== v) {
                                                                                atualizarCampo(
                                                                                    {
                                                                                        ...campo,
                                                                                        tipoMascara: v,
                                                                                    } as ICampoDaFonteDeDados,
                                                                                    campoIdx
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>{fonte?.nome || "-"}</td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            );
                                        } else {
                                            const ceId = campo?.fonte?.campos.findIndex(
                                                (ce) => ce.key === x
                                            );
                                            const ce =
                                                campo?.fonte?.campos[ceId === undefined ? -1 : ceId];
                                            return (
                                                <Draggable key={i} draggableId={String(i)} index={i}>
                                                    {(provider) => (
                                                        <tr
                                                            {...provider.draggableProps}
                                                            ref={provider.innerRef}
                                                        >
                                                            <td {...provider.dragHandleProps}> =</td>
                                                            <td>
                                                                {ce && (
                                                                    <CellTextEditor
                                                                        onChange={(texto) => {
                                                                            if (ce.nome !== texto) {
                                                                                atualizarCampoFonte(
                                                                                    campoIdx,
                                                                                    {...ce, nome: texto},
                                                                                    ceId!
                                                                                );
                                                                            }
                                                                        }}
                                                                        value={ce.nome}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    TipoDeCampoFonteDeDados[
                                                                        String(
                                                                            ce?.tipo
                                                                        ) as keyof typeof TipoDeCampoFonteDeDados
                                                                        ]
                                                                }
                                                            </td>
                                                            <td>
                                                                {ce && (
                                                                    <CellMaskSelect
                                                                        disabled={
                                                                            ce.tipo.toString() === "DATA" ||
                                                                            ce.tipo.toString() ===
                                                                            "DATA_COM_HORARIO"
                                                                        }
                                                                        value={ce.tipoMascara || ""}
                                                                        onChange={(v) => {
                                                                            if (ce.tipoMascara !== v) {
                                                                                atualizarCampoFonte(
                                                                                    campoIdx,
                                                                                    {...ce, tipoMascara: v},
                                                                                    ceId!
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>{fonte?.nome || ""}</td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                    })}
                                    </tbody>
                                )}
                            </Droppable>
                        </table>
                    </div>
                </DragDropContext>
            </LayoutProjeto>
        );
    } else {
        return <div/>;
    }
}
