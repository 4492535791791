import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ICampoDaFonteDeDados, IFonteDeDados} from "../models/FonteDeDados";
import DataGridFonte from "./DataGridFonte";

export function FiltroColunaAgrupamento(props: {
    campo: ICampoDaFonteDeDados,
    fontes: IFonteDeDados[],
    onSelect: (selected: { label: string, value: string } | ({ label: string, value: string }[])) => void,
    chaveAgrupamento: string,
    onHide: () => void,
    filtros?: any,
    multivalor?: boolean,
    valores?: any
}) {
    const {onSelect, campo, chaveAgrupamento, onHide} = props;
    const timeoutRef = useRef<any>(null);
    const fonteDeTrabalho = useMemo(() => props.fontes.find(
        (x) => x.id === props.campo.fonte?.id
    ), [props.fontes, props.campo.fonte]);
    const [erroValor, setShowErroValor] = useState('');
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (erroValor) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => setShowErroValor(''), 3000);
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, [erroValor])

    const handleSelect = useCallback((linha) => {
        const valor = linha[chaveAgrupamento];
        if (valor || valor === 0) {
            if (!props.multivalor) {
                onSelect({value: valor, label: valor});
                onHide();
            } else {
                const valores = (props.valores || []).filter((x: any) => x.value !== valor);
                onSelect([{value: valor, label: valor}, ...valores]);
                setSelected(x => [...x, linha?.id]);
            }
        } else {
            const agrupamento = campo.fonte?.campos.find(x => x.keyOrigem === chaveAgrupamento)!;
            setShowErroValor(`Esse registro não pode ser selecionado pois a coluna ${agrupamento?.nome} não possuiu um valor válido`);
        }
    }, [onSelect, chaveAgrupamento, campo, onHide, props.multivalor, props.valores]);

    return <div>
        {erroValor && <div className="alert alert-danger">{erroValor}</div>}
        <DataGridFonte
            fontes={props.fontes}
            fonteDeTrabalho={fonteDeTrabalho!}
            onSelectModel={handleSelect}
            multiValor={props.multivalor}
            filtros={props.filtros}
            valores={selected}
        />
    </div>

}
