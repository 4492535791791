import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {ICampoDaFonteDeDados, IFonteDeDados} from "../models/FonteDeDados";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";
import {CampoHelpers} from "../helpers/CampoHelpers";

export enum TipoDeCampoFonteDeDados {
    TEXTO = "Texto",
    NUMERICO = "Numérico",
    DATA = "Data",
    DATA_COM_HORARIO = "Data com horário",
    NSU = "Número sequencial único auto incrementado",
    LISTA = "Seleção de valor de outra fonte de dados",
    AGRUPAMENTO = "Agrupamento consolidado de colunas com resultado"
}

const jsonFromsCampos: IJsonFromField[] = [
    {
        name: "nome",
        label: "Nome",
        placeholder: "Nome do Campo",
        type: "text",
        validators: {
            required: {
                value: true,
                message: "Nome é obrigatório"
            },
            maxLength: {
                value: 80,
                message: "Máximo de 80 caracteres"
            }
        }
    },
    {
        name: "descricao",
        label: "Descrição",
        placeholder: "Descrição do campo",
        type: "text",
        validators: {
            maxLength: {
                value: 100,
                message: "Máximo de 100 caracteres"
            }
        }
    },
    {
        name: "tipo_campo",
        label: "Tipo do campo/coluna",
        placeholder: "Selecone o tipo do campo/coluna",
        type: "select",
        validators: {
            required: {
                value: true,
                message: "Tipo é obrigatório"
            }
        }
    }
];

export function FormularioCampoDaFonte(props: {
    fontes: IFonteDeDados[],
    onCancelEdit?: () => void,
    campoEditar?: ICampoDaFonteDeDados,
    onSubmit: (fieldsValues: ICampoDaFonteDeDados, errorCallback?: (erros: {
        name: string,
        type: string,
        message: string
    }[]) => void, reset?: () => void) => void
}) {

    const choicesFontes = props.fontes.map((x: any) => {
        return {
            value: x.id,
            label: x.nome
        }
    });

    const choicesFontesParaAgrupar = props.fontes.filter(x => String(x.tipo) !== "ESTATICA").map((x: any) => {
        return {
            value: x.id,
            label: x.nome
        }
    });

    const choicesTipoFonte = Object.keys(TipoDeCampoFonteDeDados).map(key => {
        return {
            value: String(key),
            label: TipoDeCampoFonteDeDados[key as keyof typeof TipoDeCampoFonteDeDados].toString()
        }
    });

    const valoresDefault = {} as any;

    if (props.campoEditar) {
        if (props.campoEditar.fonte?.id) {
            const choices = CampoHelpers.obterEscolhasDeCamposPorFonte({
                fontes: props.fontes,
                fonteId: props.campoEditar.fonte?.id
            });
            valoresDefault['campo_fonte'] = choices.filter(x => !!props.campoEditar?.fonte?.campos.find(y => {
                return y.keyOrigem === x.value && (!y.tipoAgrupamento || y.tipoAgrupamento === "AGRUPAMENTO")
            })).map(x => x.value);

            if (props.campoEditar.tipo.toString() === 'AGRUPAMENTO') {
                valoresDefault['campo_fonte_unicos'] = choices.filter(x => !!props.campoEditar?.fonte?.campos.find(y => {
                    return y.keyOrigem === x.value && y.tipoAgrupamento === "AGRUPAMENTO" && y.agrupamentoUnico
                })).map(x => x.value);
                valoresDefault['campo_fonte_multi'] = choices.filter(x => !!props.campoEditar?.fonte?.campos.find(y => {
                    return y.keyOrigem === x.value && y.tipoAgrupamento === "AGRUPAMENTO" && y.multivalor
                })).map(x => x.value);

                const choicesResultado = CampoHelpers.obterEscolhasDeResultadosDeCamposPorFonte({
                    fontes: props.fontes,
                    fonteId: props.campoEditar.fonte?.id
                })
                valoresDefault['campo_fonte_resultado'] = choicesResultado.filter(x => !!props.campoEditar?.fonte?.campos.find(y => {
                    return `${y.tipoResultado}|${y.keyOrigem}` === x.value
                })).map(x => x.value);
            }
        }
        valoresDefault['required'] = props.campoEditar.validacoes && props.campoEditar.validacoes.required ? "true" : "false";
        valoresDefault['unique'] = props.campoEditar.validacoes && props.campoEditar.validacoes.unique ? "true" : "false";
        valoresDefault['funcao'] = !!props.campoEditar.metaDataFuncao ? "sim" : "nao";
        valoresDefault['metaDataFuncao'] = props.campoEditar.metaDataFuncao;
        valoresDefault['min'] = props.campoEditar?.validacoes?.min;
        valoresDefault['max'] = props.campoEditar?.validacoes?.max;
        valoresDefault['minDate'] = props.campoEditar?.validacoes?.minDate;
        valoresDefault['maxDate'] = props.campoEditar?.validacoes?.maxDate;
        valoresDefault['minLength'] = props.campoEditar?.validacoes?.minLength;
        valoresDefault['maxLength'] = props.campoEditar?.validacoes?.maxLength;
        valoresDefault['fonte'] = choicesFontes.find(x => x.value === props.campoEditar?.fonte?.id)?.value;
        valoresDefault['valorNsuInicial'] = props.campoEditar.valorNsuInicial || 0;
        valoresDefault['nome'] = props.campoEditar.nome;
        valoresDefault['descricao'] = props.campoEditar.descricao;
        valoresDefault['tipo_campo'] = props.campoEditar?.tipo;
        valoresDefault['tipo_fonte'] = props.fontes.find(x => x.id === valoresDefault['fonte'])?.tipo;
    }

    const {register, handleSubmit, watch, errors, setError, setValue, reset, unregister, getValues} = useForm(
        {
            validateCriteriaMode: "all",
            defaultValues: valoresDefault
        }
    );

    const watchTipo = watch("tipo_campo", props.campoEditar?.tipo);
    const watchFuncao = watch("funcao", !!props.campoEditar?.metaDataFuncao ? "sim" : "nao");

    const fieldObritatorio: IJsonFromField[] = [{
        name: "required",
        label: "Obrigatório?",
        type: "select",
        placeholder: "Esse campo/coluna é obrigatório?",
        choices: [
            {
                value: "true",
                label: "Sim"
            },
            {
                value: "false",
                label: "Não"
            }
        ],
        validators: {
            required: {
                value: true,
                message: "Informe se é obrigatório"
            }
        },
        valueDefault: props.campoEditar ? {
            value: props.campoEditar.validacoes && props.campoEditar.validacoes.required ? "true" : "false",
            label: props.campoEditar.validacoes && props.campoEditar.validacoes.required ? "Sim" : "Não"
        } : undefined
    }, {
        name: "unique",
        label: "O valor desse campo/coluna poderá se repetir?",
        type: "select",
        placeholder: "O valor desse campo/coluna poderá se repetir?",
        choices: [
            {
                value: "false",
                label: "Sim"
            },
            {
                value: "true",
                label: "Não"
            }
        ],
        validators: {
            required: {
                value: true,
                message: "Informe se esse campo poderá se repetir"
            }
        },
        valueDefault: props.campoEditar ? {
            value: props.campoEditar.validacoes && props.campoEditar.validacoes.unique ? "true" : "false",
            label: props.campoEditar.validacoes && props.campoEditar.validacoes.unique ? "Não" : "Sim"
        } : undefined
    }];

    const fieldFuncao: IJsonFromField[] = [{
        name: "funcao",
        label: "Deseja inserir uma função para este campo?",
        type: "select",
        placeholder: "Função",
        choices: [
            {
                value: "sim",
                label: "Sim"
            },
            {
                value: "nao",
                label: "Não"
            }
        ],
        validators: {
            required: {
                value: true,
                message: "Informe se deseja uma função para este campo"
            }
        },
        valueDefault: props.campoEditar ? {
            value: !!props.campoEditar.metaDataFuncao ? "sim" : "nao",
            label: !!props.campoEditar.metaDataFuncao ? "Sim" : "Não",
        } : undefined
    }];
    if (watchFuncao === "sim") {
        fieldFuncao.push({
            name: "metaDataFuncao",
            label: "Insira aqui a função",
            type: "text-area",
            placeholder: "Função",
            validators: {
                required: {
                    value: true,
                    message: "Informe uma função para este campo"
                }
            },
            valueDefault: props.campoEditar?.metaDataFuncao
        });
    } else {
        unregister("metaDataFuncao");
    }

    const tipo = watchTipo ? TipoDeCampoFonteDeDados[watchTipo as keyof typeof TipoDeCampoFonteDeDados] : watchTipo;
    let complemento: IJsonFromField[] = [];
    const fonteSelecionada = props.fontes.find((x: any) => x.id === watch("fonte", props.campoEditar?.fonte?.id));
    const choicesCamposFonte = fonteSelecionada ? CampoHelpers.obterEscolhasDeCamposPorFonte({
        fontes: props.fontes,
        fonteId: fonteSelecionada.id
    }) : [];
    if (tipo === TipoDeCampoFonteDeDados.NUMERICO) {
        complemento = [
            ...fieldFuncao,
            {
                name: "min",
                label: "Valor mínimo aceito",
                helpText: "Defina 10 para aceitar valores igual ou maior que 10.",
                placeholder: "Defina um valor mínimo caso seja necessário",
                type: "number",
                valueDefault: props.campoEditar?.validacoes?.min
            },
            {
                name: "max",
                label: "Valor máximo aceito",
                helpText: "Defina 10 para aceitar valores igual ou menores que 10.",
                placeholder: "Defina um valor máximo caso seja necessário",
                type: "number",
                valueDefault: props.campoEditar?.validacoes?.max
            },
            ...fieldObritatorio
        ];
    } else if (tipo === TipoDeCampoFonteDeDados.DATA || tipo === TipoDeCampoFonteDeDados.DATA_COM_HORARIO) {
        complemento = [
            ...fieldFuncao,
            {
                name: "minDate",
                label: "Data mínima aceita",
                helpText: "Defina 01/01/2020 para aceitar datas iguais ou superioes.",
                placeholder: "Defina uma data mínima caso seja necessário",
                type: "date",
                valueDefault: props.campoEditar?.validacoes?.minDate
            }, {
                name: "maxDate",
                label: "Data máxima aceita",
                placeholder: "Defina uma data máxima caso seja necessário",
                helpText: "Defina 01/01/2020 para aceitar datas iguais ou inferiores.",
                type: "date",
                valueDefault: props.campoEditar?.validacoes?.maxDate
            },
            ...fieldObritatorio
        ];
    } else if (tipo === TipoDeCampoFonteDeDados.TEXTO) {
        complemento = [
            ...fieldFuncao,
            {
                name: "minLength",
                label: "Quantidade mínima de caracteres aceita",
                helpText: "Defina 10 para aceitar textos com no mínimo 10 caracteres.",
                placeholder: "Defina uma quantidade mínima de caracteres caso seja necessário",
                type: "number",
                valueDefault: props.campoEditar?.validacoes?.minLength
            }, {
                name: "maxLength",
                label: "Quantidade máxima de caracteres aceita",
                helpText: "Defina 10 para aceitar textos com no máximo 10 caracteres.",
                placeholder: "Defina uma quantidade máxima de caracteres caso seja necessário",
                type: "number",
                valueDefault: props.campoEditar?.validacoes?.maxLength
            },
            ...fieldObritatorio
        ];
    } else if (tipo === TipoDeCampoFonteDeDados.LISTA) {
        let complementoFonte: IJsonFromField[] = [
            {
                name: "multivalor",
                label: "Esse campo aceitará mais de um valor?",
                placeholder: "Selecione",
                type: "select",
                choices: [
                    {
                        value: "true",
                        label: "Sim"
                    },
                    {
                        value: "false",
                        label: "Não"
                    }
                ],
                validators: {
                    required: {
                        value: !props.campoEditar,
                        message: "Informe se deseja que esse campo aceitará mais de um valor"
                    }
                },
                disabled: !!props.campoEditar,
                valueDefault: props.campoEditar ? {
                    value: props.campoEditar.multivalor ? "true" : "false",
                    label: props.campoEditar.multivalor ? "Sim" : "Não",
                } : undefined
            }
        ];
        const tipo_fonte = watch("tipo_fonte");
        if (fonteSelecionada && tipo_fonte !== fonteSelecionada?.tipo) {
            setValue("tipo_fonte", fonteSelecionada.tipo)
        }
        if (fonteSelecionada && fonteSelecionada.tipo.toString() !== "ESTATICA") {
            complementoFonte.push({
                name: "campo_fonte",
                label: "Selecione quais colunas serão exibidas",
                placeholder: "Selecione uma ou mais colunas",
                helpText: "Selecione uma ou mais colunas serão exibidas na tabela",
                type: "multi-select",
                validators: {
                    required: {
                        value: true,
                        message: "Selecione uma fonte de dados"
                    }
                },
                choices: choicesCamposFonte,
                valueDefault: props.campoEditar ? choicesCamposFonte.filter(x => !!props.campoEditar?.fonte?.campos.find(y => {
                    return x.value === y.keyOrigem && (!y.tipoAgrupamento || y.tipoAgrupamento === "AGRUPAMENTO")
                })) : undefined
            });
        }

        complemento = [{
            type: "hidden",
            name: "tipo_fonte",
            valueDefault: fonteSelecionada?.tipo || "",
            label: "Tipo fonte",
            validators: {
                required: false
            }
        }, {
            name: "fonte",
            label: "Fonte para seleção",
            placeholder: "Selecione uma fonte de dados",
            helpText: "Escolha uma fonte de dados para lista da seleção do campo/coluna.",
            type: "select",
            validators: {
                required: {
                    value: true,
                    message: "Selecione uma fonte de dados"
                }
            },
            choices: choicesFontes,
            valueDefault: props.campoEditar ? choicesFontes.find(x => x.value === props.campoEditar?.fonte?.id) : undefined
        }, ...complementoFonte, ...fieldObritatorio];

    } else if (tipo === TipoDeCampoFonteDeDados.NSU) {
        complemento = [{
            name: "valorNsuInicial",
            label: "Informe qual valor deseja iniciar a sequência",
            placeholder: "Informe qual valor deseja iniciar a sequência",
            helpText: "Informe um valor inteiro para ínicio da sequência",
            type: "number",
            validators: {
                required: {
                    value: true,
                    message: "Informe um número de início para a sequência"
                }
            },
            valueDefault: props.campoEditar ? props.campoEditar.valorNsuInicial || 0 : 0
        }]
    } else if (tipo === TipoDeCampoFonteDeDados.AGRUPAMENTO) {
        complemento = [
            {
                name: "fonte",
                label: "Fonte para agrupamento",
                placeholder: "Selecione uma fonte de dados",
                helpText: "Escolha uma fonte de dados para agrupar campo/coluna.",
                type: "select",
                validators: {
                    required: {
                        value: true,
                        message: "Selecione uma fonte de dados"
                    }
                },
                choices: choicesFontesParaAgrupar,
                valueDefault: props.campoEditar ? choicesFontesParaAgrupar.find(x => x.value === props.campoEditar?.fonte?.id) : undefined
            }];
        if (fonteSelecionada) {
            const jsonCampos = {
                name: "campo_fonte",
                label: "Quais colunas e a ordem que dejesa agrupar?",
                placeholder: "Selecione quais colunas dejeja agrupar",
                type: "multi-select",
                validators: {
                    required: {
                        value: true,
                        message: "Selecione ao menos uma coluna"
                    }
                },
                choices: choicesCamposFonte,
                valueDefault: props.campoEditar ? choicesCamposFonte.filter(x => !!props.campoEditar?.fonte?.campos.find(y => {
                    return x.value === y.keyOrigem && (!y.tipoAgrupamento || y.tipoAgrupamento === "AGRUPAMENTO")
                })) : undefined
            };
            complemento.push(jsonCampos as any);
            const campos_selecionados = watch("campo_fonte", jsonCampos.valueDefault?.map(x => x.value));
            if (campos_selecionados) {
                const choicesMulti = choicesCamposFonte.filter(x => !!campos_selecionados.find((z: any) => z === x.value))
                const jsonCamposMultiplos = {
                    name: "campo_fonte_multi",
                    label: "Quais colunas aceitarão mais de um valor?",
                    placeholder: "Selecione quais colunas aceitarão mais de um valor",
                    type: "multi-select",
                    choices: choicesMulti,
                    disabled: !!props.campoEditar,
                    valueDefault: valoresDefault['campo_fonte_multi'] ? choicesMulti.filter(x => valoresDefault['campo_fonte_multi'].indexOf(x.value) > -1) : undefined
                };
                const choicesUnico = choicesCamposFonte.filter(x => !!campos_selecionados.find((z: any) => z === x.value))
                const jsonCamposUnicos = {
                    name: "campo_fonte_unicos",
                    label: "Quais colunas não poderão se repetir?",
                    placeholder: "Selecione quais colunas não poderão se repetir",
                    type: "multi-select",
                    choices: choicesUnico,
                    valueDefault: valoresDefault['campo_fonte_unicos'] ? choicesUnico.filter(x => valoresDefault['campo_fonte_unicos'].indexOf(x.value) > -1) : undefined
                };
                const choicesResultado = CampoHelpers.obterEscolhasDeResultadosDeCamposPorFonte({
                    fontes: props.fontes,
                    fonteId: fonteSelecionada.id
                })
                complemento.push(jsonCamposUnicos as any, jsonCamposMultiplos as any, {
                    name: "campo_fonte_resultado",
                    label: "Selecione o tipo de resultado",
                    placeholder: "Selecione quais resultados deseja",
                    type: "multi-select",
                    choices: choicesResultado,
                    valueDefault: valoresDefault['campo_fonte_resultado'] ? choicesResultado.filter(x => valoresDefault['campo_fonte_resultado'].indexOf(x.value) > -1) : undefined
                });
            }
        }
    }

    useEffect(() => {
        const un = [] as string[];
        Object.keys(getValues()).forEach(name => {
            if (name !== "nome" && name !== "descricao" && name !== "tipo_campo" && complemento.findIndex(x => x.name === name) === -1) {
                un.push(name);
            }
        });
        if (un.length > 0)
            unregister(un);
    });
    return (
        <form onSubmit={handleSubmit((data) => {
            if (props.onSubmit) {
                const dataOutput = {...data};
                if (dataOutput.tipo_campo === "LISTA") {
                    if (dataOutput.tipo_fonte !== "ESTATICA" && (!dataOutput.campo_fonte || dataOutput.campo_fonte.length === 0)) {
                        setError("campo_fonte", "required", "Selecione ao menos uma coluna");
                        return;
                    }
                    dataOutput.fonte = {
                        id: dataOutput.fonte,
                        campos: dataOutput.tipo_fonte === "ESTATICA" ? [] : Array.isArray(dataOutput.campo_fonte) ? dataOutput.campo_fonte : [dataOutput.campo_fonte]
                    };
                    if (props.campoEditar) {
                        dataOutput.fonte.campos = dataOutput.fonte.campos.map((x: any) => {
                            return props.campoEditar?.fonte?.campos.find(y => y.id === x || y.keyOrigem === x) || x
                        })
                    }
                    delete dataOutput['campo_fonte'];
                } else if (dataOutput.tipo_campo === "AGRUPAMENTO") {
                    if (!dataOutput.campo_fonte || dataOutput.campo_fonte.length === 0) {
                        setError("campo_fonte", "required", "Selecione ao menos uma coluna");
                        return;
                    }
                    if (!dataOutput.campo_fonte_resultado || dataOutput.campo_fonte_resultado.length === 0) {
                        setError("campo_fonte_resultado", "required", "Selecione ao menos um tipo de resultado");
                        return;
                    }
                    dataOutput.fonte = {
                        id: dataOutput.fonte,
                        campos: [...(Array.isArray(dataOutput.campo_fonte) ? dataOutput.campo_fonte : [dataOutput.campo_fonte]).map(x => {
                            const c = props.campoEditar?.fonte?.campos.find(y => y.keyOrigem === x && y.tipoAgrupamento === "AGRUPAMENTO")
                            if (c) {
                                return {
                                    ...c,
                                    unique: dataOutput.campo_fonte_unicos && dataOutput.campo_fonte_unicos.indexOf(x) > -1,
                                    multivalor: dataOutput.campo_fonte_multi && dataOutput.campo_fonte_multi.indexOf(x) > -1
                                }
                            } else {
                                return {
                                    tipoAgrupamento: "AGRUPAMENTO",
                                    keyOrigem: x,
                                    unique: dataOutput.campo_fonte_unicos && dataOutput.campo_fonte_unicos.indexOf(x) > -1,
                                    multivalor: dataOutput.campo_fonte_multi && dataOutput.campo_fonte_multi.indexOf(x) > -1,
                                }
                            }
                        }), ...dataOutput.campo_fonte_resultado.map((x: string) => {
                            const res = x.split("|");
                            const c = props.campoEditar?.fonte?.campos.find(y => y.keyOrigem === res[1] && y.tipoAgrupamento === "RESULTADO" && y.tipoResultado === res[0])
                            if (c) {
                                return {
                                    ...c
                                }
                            } else {
                                return {
                                    keyOrigem: res[1],
                                    tipoAgrupamento: "RESULTADO",
                                    tipoResultado: res[0]
                                }
                            }
                        })]
                    };
                    delete dataOutput['campo_fonte']
                    delete dataOutput['campo_fonte_multi']
                    delete dataOutput['campo_fonte_resultado']
                    delete dataOutput['campo_fonte_unicos']
                } else {
                    delete dataOutput['fonte'];
                }

                if (props.campoEditar?.fonte?.campos?.length) {
                    const camposOriginais = props.campoEditar.fonte?.campos;
                    const camposRemovidos = camposOriginais?.filter(x => !dataOutput.fonte?.campos.some((y: any) => y.id === x.id) && !x.podeRemover);
                    if (camposRemovidos.length > 0) {
                        if (camposRemovidos.length > 1) {
                            setError("campo_fonte", "required", `Os campos ${camposRemovidos.map(x => x.nome).join(", ")} não podem ser removidos pois possuem relacionamentos com outras tablelas.`);
                        } else {
                            setError("campo_fonte", "required", `O campo ${camposRemovidos.map(x => x.nome).join(", ")} não pode ser removido pois possue relacionamentos com outras tablelas.`);
                        }
                        return;
                    }
                }

                dataOutput.validacoes = {
                    required: dataOutput['required'] === "true",
                    unique: dataOutput['unique'] === "true",
                    minLength: dataOutput['minLength'],
                    maxLength: dataOutput['maxLength'],
                    max: dataOutput['max'],
                    min: dataOutput['min'],
                    minDate: dataOutput['minDate'],
                    maxDate: dataOutput['maxDate'],
                }
                
                if (dataOutput.funcao === "nao") {
                    dataOutput.metaDataFuncao = null;
                }
                delete dataOutput['tipo_fonte'];
                delete dataOutput['required'];
                delete dataOutput['unique'];
                delete dataOutput['minLength'];
                delete dataOutput['maxLength'];
                delete dataOutput['max'];
                delete dataOutput['min'];
                delete dataOutput['minDate'];
                delete dataOutput['maxDate'];
                delete dataOutput['funcao'];
                dataOutput['tipo'] = dataOutput['tipo_campo'];
                delete dataOutput['tipo_campo'];
                dataOutput['multivalor'] = props.campoEditar ? props.campoEditar.multivalor : dataOutput['multivalor'] === "true";
                if (props.campoEditar) {
                    dataOutput["id"] = props.campoEditar?.id;
                }
                props.onSubmit(dataOutput as ICampoDaFonteDeDados, (erros: any) => {
                    if (erros) {
                        setError(erros)
                    }
                });
                reset();
            }
        })}>
            {jsonFromsCampos.map(field => {
                const f = JSON.parse(JSON.stringify(field));
                if (f.name === "tipo_campo") {
                    f.choices = [...choicesTipoFonte];
                }
                if (props.campoEditar) {
                    if (f.name === "nome") {
                        f.valueDefault = props.campoEditar.nome
                    } else if (f.name === "descricao") {
                        f.valueDefault = props.campoEditar.descricao
                    } else if (f.name === "tipo_campo") {
                        f.valueDefault = choicesTipoFonte.find(x => x.value === props.campoEditar?.tipo);
                        f.disabled = true;
                    }
                } else {
                    f.valueDefault = undefined;
                }
                return (<FieldJsonForm watch={watch} field={f} errors={errors} register={register} key={f.name}
                                       setValue={setValue}/>);
            })}
            {complemento.map(field => {
                return (<FieldJsonForm watch={watch} field={field} errors={errors} register={register} key={field.name}
                                       setValue={setValue}/>);
            })}
            <div className="row">
                <div className="col-6">
                    {props.campoEditar &&
                        <button className="btn btn-warning" type="button"
                                onClick={() => {
                                    reset();
                                    return props.onCancelEdit ? props.onCancelEdit() : undefined
                                }}>
                            Cancelar Edição
                        </button>
                    }
                </div>
                <div className="col-6">
                    <p className="text-right">
                        <button className="btn btn-primary" type="submit">
                            {!props.campoEditar ? "Adicionar campo/coluna" : "Alterar"}
                        </button>
                    </p>
                </div>
            </div>
        </form>
    );
}
