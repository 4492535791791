import * as React from 'react';

type Props = {
    mensagem?: string
};
export const Loading = ({mensagem}: Props) => (
    <div className="loading-shading d-flex justify-content-center align-items-center">
        {mensagem ? <div style={{
                width: "300px",
                background: "rgba(100, 100, 100, 0.8)",
                textAlign: "center",
                color: "#fff",
                padding: "20px",
                fontWeight: 700,
                borderRadius: "10px",
            }}>
                <p className="text-center">{mensagem}</p>
                <span className="fas fa-spinner fa-pulse" style={{fontSize: "20px"}}/>
            </div> :
            <div className="loading-icon">
                <span className="fas fa-spinner fa-pulse"/>
            </div>
        }
    </div>
);
