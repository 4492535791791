import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {IProjeto} from "../models/Projeto";
import {IComponente, IDashboard} from "../models/Dashboard";
import {CampoHelpers, MetaDataColuna} from "../helpers/CampoHelpers";
import {FormularioFiltroComponente} from "./FormularioFiltroComponente";


export function ButtonFiltroComponente(props: { onEdit: (dashboard: IDashboard) => void, projeto: IProjeto, fontes: IFonteDeDados[], componente: IComponente, dashboard: IDashboard }) {

    const [show, setShow] = useState(false);
    const metaDatas = props.fontes.find(f => f.id === props.componente.fonteId)?.campos.reduce((previousValue, currentValue) => {
        return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
    }, {} as { [key: string]: MetaDataColuna }) || {};
    return (<div className="d-inline-block">
        <button className="btn btn-light" onClick={() => setShow(true)}><i
            className={"fa fa-filter"}/>
        </button>
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Fitros Componente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show &&
                <FormularioFiltroComponente componente={props.componente} fontes={props.fontes} onCancel={() => {
                    setShow(false);
                }} onSubmit={(data) => {
                    const cc = {...props.componente}
                    if (cc.filtros) {
                        cc.filtros?.push({
                            campo: data.campo,
                            relacao: data.relacao
                        })
                    } else {
                        cc.filtros = [{
                            campo: data.campo,
                            relacao: data.relacao
                        }]
                    }
                    const idx = props.dashboard.componentes.findIndex(c => c.id === props.componente.id)
                    props.dashboard.componentes[idx] = cc as IComponente
                    props.onEdit(props.dashboard);
                }}/>}
                <hr/>
                <p>Filtros Cadastrados:</p>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                        <tr>
                            <th>Campo</th>
                            <th>Relação</th>
                            <th>Ação</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.componente.filtros && props.componente.filtros.map((x, i) => {
                            return <tr>
                                <td>{metaDatas[x.campo]?.nome}</td>
                                <td>{x.relacao === "CONTEM_VALOR" ? "Contém Valor" : "Não Contém Valor"}</td>
                                <td>
                                    <button className='btn btn-danger' onClick={() => {
                                        if (window.confirm("Deseja realmente remover esse filtro?")) {
                                            const cc = {...props.componente}
                                            cc.filtros?.splice(i, 1);
                                            const idx = props.dashboard.componentes.findIndex(c => c.id === props.componente.id)
                                            props.dashboard.componentes[idx] = cc as IComponente
                                            props.onEdit(props.dashboard);
                                        }
                                    }}>
                                        <i className='fa fa-trash'/>
                                    </button>
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    </div>);
}
