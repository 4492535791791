import React, {useState} from "react";
import {
    DragDropProvider,
    Grid,
    Table,
    TableColumnResizing,
    TableHeaderRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import {PopupTexto} from "../../componets/PopupTexto";
import {ButtonAddUsuarioProjeto} from "../../componets/ButtonAddUsuarioProjeto";
import {ButtonAddFonteAoUsuario} from "../../componets/ButtonAddFonteAoUsuario";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {useDispatch} from "react-redux";
import {ApiService} from "../../services/ApiService";
import {setFonte} from "../../actions/ProjetoActions";

const ListarFontes = ({
                          fontes: f,
                          projetoId,
                      }: {
    fontes: any;
    projetoId: string;
}) => {
    const dispatch = useDispatch();
    const [fontes, setFontes] = useState<
        {
            fonteId: string;
            nome: string;
            letura: boolean;
            edicao: boolean;
            exclusao: boolean;
            insersao: boolean;
            alterado: boolean;
        }[]
    >(f);
    const editar = (fonte: any) => {
        const data: any = [...fontes];
        const f = data.findIndex((x: any) => x.fonteId === fonte.fonteId);
        if (f > -1) {
            data[f].alterado = false;
        }
        setFontes(data);
        const permissoes = [];
        fonte.edicao && permissoes.push("edicao");
        fonte.insersao && permissoes.push("insersao");
        fonte.exclusao && permissoes.push("exclusao");
        ApiService.post("/fonte-de-dados/add-usuario-fonte/" + projetoId, {
            fonte: fonte.fonteId,
            usuarioId: fonte.usuarioId,
            permissoes,
        }).then(
            (fonteSalva) => {
                dispatch(setFonte(fonteSalva, projetoId));
            },
            () => {
                window.alert("Falha ao tentar salvar alteração");
                const data: any = [...fontes];
                const f = data.findIndex((x: any) => x.fonteId === fonte.fonteId);
                if (f > -1) {
                    data[f].alterado = true;
                }
                setFontes(data);
            }
        );
    };

    const remover = (fonte: any) => {
        if (
            window.confirm(
                "Deseja realmente remover o acesso desse usuário a essa fonte?"
            )
        ) {
            ApiService.delete("/fonte-de-dados/add-usuario-fonte", projetoId, {
                fonte: fonte.fonteId,
                usuarioId: fonte.usuarioId,
            }).then(
                (fonteSalva) => {
                    dispatch(setFonte(fonteSalva, projetoId));
                },
                () => {
                    window.alert("Falha ao tentar remover o usuário");
                }
            );
        }
    };

    const toggleCheckbox = (fonte: any, type: any) => {
        const data: any = [...fontes];
        const f = data.findIndex((x: any) => x.fonteId === fonte.fonteId);
        if (f > -1) {
            data[f][type] = !data[f][type];
            data[f].alterado = true;
        }
        setFontes(data);
    };
    return (
        <div className="table-responsive">
            <table className="table w-100">
                <thead>
                <tr>
                    <th>Fonte</th>
                    <th>Leitura</th>
                    <th>Edição</th>
                    <th>Exclusão</th>
                    <th>Insersão</th>
                    <th>Opções</th>
                </tr>
                </thead>
                <tbody>
                {fontes.map((x: any, i: number) => {
                    return (
                        <tr key={i}>
                            <td>{x.nome}</td>
                            <td>{x.leitura ? "Sim" : "Não"}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={x.edicao}
                                    onChange={() => toggleCheckbox(x, "edicao")}
                                />{" "}
                                {x.edicao ? "Sim" : "Não"}
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={x.exclusao}
                                    onChange={() => toggleCheckbox(x, "exclusao")}
                                />{" "}
                                {x.exclusao ? "Sim" : "Não"}
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={x.insersao}
                                    onChange={() => toggleCheckbox(x, "insersao")}
                                />{" "}
                                {x.insersao ? "Sim" : "Não"}
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    disabled={!x.alterado}
                                    onClick={() => {
                                        editar(x);
                                    }}
                                >
                                    <i className="fa fa-save"/>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => remover(x)}
                                >
                                    <i className="fa fa-trash"/>
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export function ProjetoCompartilhamentoPage(props: PropsProjetoPages) {
    const [colunas] = useState([
        {name: "usuario", title: "Usuário"},
        {name: "fontes", title: "Fontes Compartilhadas"},
        {name: "administratodor", title: "Administrador"},
        {name: "editar", title: "Editar"},
    ]);

    const Cell: (propsCell: Table.DataCellProps) => JSX.Element = (propsCell) => {
        return <Table.Cell {...(propsCell as any)} />;
    };

    const fontes = props.fontes.filter((x) => x.tipo.toString() !== "ESTATICA");

    return (
        <LayoutProjeto
            projeto={props.projeto}
            toggleMenu={props.toggleMenu}
            headlerToggleMenu={props.headlerToggleMenu}
            titulo="Compartilhamento"
            administrador={props.administrador}
        >
            <div>
                <div className="mb-2">
                    <ButtonAddUsuarioProjeto
                        onAdd={() => {
                            window.location.reload();
                        }}
                        projetoId={props.projeto.id}
                    />{" "}
                    |{" "}
                    <ButtonAddFonteAoUsuario
                        onAdd={() => {
                        }}
                        projeto={props.projeto}
                        fontes={fontes}
                    />
                </div>
                <div className="card" style={{position: "relative"}}>
                    <Grid
                        rows={props.projeto.usuarios.map((x) => {
                            const fontesUsuario = fontes
                                .filter((f) => !!f.usuarios.find((u) => u.id === x.id))
                                .map((f) => {
                                    const userFonte = f.usuarios.find((u) => u.id === x.id);
                                    return {
                                        fonteId: f.id,
                                        nome: f.nome,
                                        leitura: true,
                                        edicao: !!userFonte?.edicao,
                                        exclusao: !!userFonte?.exclusao,
                                        insersao: !!userFonte?.insersao,
                                        usuarioId: userFonte?.id,
                                    };
                                });
                            return {
                                id: x.id,
                                usuario: x.nome,
                                administratodor: x.administrador ? "Sim" : "Não",
                                fontes: (
                                    <PopupTexto
                                        textoBtn={`${fontesUsuario.length} fontes compartilhadas`}
                                    >
                                        <ListarFontes
                                            fontes={fontesUsuario}
                                            projetoId={props.projeto.id}
                                        />
                                    </PopupTexto>
                                ),
                                editar: (
                                    <>
                                        <ButtonAddUsuarioProjeto
                                            onAdd={() => {
                                                window.location.reload();
                                            }}
                                            projetoId={props.projeto.id}
                                            usuario={{
                                                nome: x.nome,
                                                administrador: x.administrador,
                                                usuarioId: String(x.id),
                                                compId: x.compId
                                            }}
                                        />
                                        |
                                        <button
                                            className={"btn btn-danger"}
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        "Deseja realmente remover esse usuário desse proejto?"
                                                    )
                                                ) {
                                                    ApiService.delete(
                                                        "/projeto/add-usuario/" + props.projeto.id,
                                                        x.compId
                                                    ).then(
                                                        () => {
                                                            window.location.reload();
                                                        },
                                                        () => {
                                                        }
                                                    );
                                                }
                                            }}
                                        >
                                            <i className={"fa fa-trash"}/>
                                        </button>
                                    </>
                                ),
                            };
                        })}
                        columns={colunas}
                    >
                        <DragDropProvider/>
                        <Table cellComponent={Cell}/>
                        <TableColumnResizing minColumnWidth={200}/>
                        <TableHeaderRow/>
                    </Grid>
                </div>
            </div>
        </LayoutProjeto>
    );
}
