import React from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {FormularioCampoDaFonte} from "./FormularioCampoFonteDados";

export class ButtonNovoCampoFormulario extends React.Component<{fontes: IFonteDeDados[], onCancel: () => void, onSave: (data: any) => void }, { show: boolean, dataForm?: any }> {

    constructor(props: any) {
        super(props);
        this.state = {
            show: false
        };
        this.handleCancel = this.handleCancel.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleCancel() {
        this.setState({show: false});
        this.props.onCancel();
    }

    handleShow() {
        this.setState({show: true});
    }

    handleSubmit(data: any) {
        this.props.onSave(data);
        this.setState({show: false});
    }

    render(): React.ReactNode {
        return (<>
            <div className="d-inline-block">
                <button className="btn btn-sm btn-link text-info" onClick={this.handleShow}><i
                    className="oi oi-plus"/> Inserir novo campo
                </button>
                <Modal show={this.state.show} size={"xl"} onHide={this.handleCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Inserindo Novo Campo da Fonte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormularioCampoDaFonte fontes={this.props.fontes} onSubmit={this.handleSubmit} onCancelEdit={this.handleCancel}/>
                    </Modal.Body>
                </Modal>
            </div>
        </>);
    }
}
