import React, {useCallback, useContext, useState} from "react";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {PropsProjetoPages} from "./ProjetoPage";
import {EventosContext, EventosStateType} from "../../contexts/eventos";
import {IEvento} from "../../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {FormularioEventoComponente} from "../../componets/FormularioEventoComponente";
import {ApiService} from "../../services/ApiService";
import useEventos from "../../hooks/useEventos";

export function ProjetoEventosPage(props: PropsProjetoPages & { idFonteDeTrabalho: string }) {
    const [evento, setEvento] = useState<IEvento>();
    const [show, setShow] = useState(false);
    const [eventos, setEventos] = useContext(EventosContext);
    const fonteTrabalho = props.fontes.find(x => x.id === props.idFonteDeTrabalho)!;
    const eventosFonte = useEventos(fonteTrabalho);


    const editarEvento = useCallback((data: any, errorCallback?: any) => {
        ApiService.put(`/eventos/${props.idFonteDeTrabalho}`, evento?.id || "", data).then(evento => {
            const data = [...(eventosFonte || [])] as IEvento[];
            const index = data.findIndex(x => x.id === evento.id);
            if (index > -1) {
                data[index] = evento
            }
            const eventosTotais: EventosStateType = {...eventos, [props.idFonteDeTrabalho]: data};
            setEventos(eventosTotais);
            setEvento(undefined);
            setShow(false);
        }, error => {
            if (error && error.status === 400 && error.data && errorCallback && error.data.errors) {
                const errors = error.data.errors;
                errorCallback(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: String(errors[key].type),
                        message: String(errors[key].message)
                    }
                }));
            }
        })
    }, [eventos, setEventos, evento, eventosFonte, props.idFonteDeTrabalho]);

    const addEvento = useCallback((data: any, errorCallback?: any) => {
        ApiService.post(`/eventos/${props.idFonteDeTrabalho}`, data).then(evento => {
            const eventosTotais: EventosStateType = {
                ...eventos,
                [props.idFonteDeTrabalho]: [...(eventosFonte || []), evento]
            };
            setEventos(eventosTotais);
            setShow(false);
        }, error => {
            if (error && error.status === 400 && error.data && errorCallback && error.data.errors) {
                const errors = error.data.errors;
                errorCallback(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: String(errors[key].type),
                        message: String(errors[key].message)
                    }
                }));
            }
        })
    }, [eventos, setEventos, eventosFonte, props.idFonteDeTrabalho]);

    const removerEvento = useCallback((evento: IEvento) => {
        ApiService.delete(`/eventos/${props.idFonteDeTrabalho}`, evento?.id || "").then(() => {
            const data = [...(eventosFonte || [])] as IEvento[];
            const index = data.findIndex(x => x.id === evento.id);
            if (index > -1) {
                data.splice(index, 1)
            }
            const eventosTotais: EventosStateType = {...eventos, [props.idFonteDeTrabalho]: data};
            setEventos(eventosTotais);
        });
    }, [eventos, setEventos, eventosFonte, props.idFonteDeTrabalho]);

    const handleSubmit = useCallback((data: any, errorCallback?: any) => {
        const dataRequest = {
            ...data,
            onDelete: data.quando.some((x: any) => x === "onDelete" || (x?.hasOwnProperty('value') && x?.value === "onDelete")),
            onCreate: data.quando.some((x: any) => x === "onCreate" || (x?.hasOwnProperty('value') && x?.value === "onCreate")),
            onUpdate: data.quando.some((x: any) => x === "onUpdate" || (x?.hasOwnProperty('value') && x?.value === "onUpdate")),
            quando: undefined,
        };

        if (evento) {
            editarEvento(dataRequest, errorCallback);
        } else {
            addEvento(dataRequest, errorCallback);
        }
    }, [addEvento, editarEvento, evento])

    return (
        <>
            <LayoutProjeto projeto={props.projeto}
                           toggleMenu={props.toggleMenu}
                           headlerToggleMenu={props.headlerToggleMenu}
                           administrador={props.administrador}
                           titulo={`Eventos da fonte ${fonteTrabalho.nome}`}>
                <div className="ProjetoFontesPages">
                    <div className="row mb-2">
                        <div className="col-8">
                            <p><b>Eventos Cadastrados</b></p>
                        </div>
                        <div className="col-4 text-right">
                            <button className="btn btn-primary" onClick={() => setShow(true)}>
                                <i className="fas fa-plus"/> Novo Evento
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Ações</th>
                                <th>Nome</th>
                                <th>Quando</th>
                            </tr>
                            </thead>
                            <tbody>
                            {eventosFonte?.length ? eventosFonte.map(evento => {
                                return (
                                    <tr key={evento.id}>
                                        <td>
                                            <div className="btn-group">
                                                <button className="btn btn-link text-info" onClick={() => {
                                                    setEvento(evento);
                                                    setShow(true)
                                                }}>
                                                    <i className="fa fa-pencil"/>
                                                </button>
                                                <button className="btn btn-link text-danger" onClick={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    if (confirm("Deseja realmente remover esse evento?")) {
                                                        removerEvento(evento);
                                                    }
                                                }}>
                                                    <i className="fa fa-trash"/>
                                                </button>
                                            </div>
                                        </td>
                                        <td>{evento.nome}</td>
                                        <td>
                                            Quando adicionar: <b>{evento.onCreate ? 'Sim' : 'Não'}</b>,
                                            Quando editar: <b>{evento.onUpdate ? 'Sim' : 'Não'}</b>,
                                            Quando deletar: <b>{evento.onDelete ? 'Sim' : 'Não'}</b>,
                                        </td>
                                    </tr>
                                );
                            }) : <tr>
                                <td colSpan={6}>
                                    Nenhum evento cadastrado!
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </LayoutProjeto>
            <Modal show={show} size={"xl"} onHide={() => {
                setShow(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormularioEventoComponente evento={evento} fontes={props.fontes} onCancel={() => {
                        setShow(false);
                        setEvento(undefined);
                    }} onSubmit={(data) => handleSubmit(data)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}
