import React, {PropsWithoutRef, useState} from "react";
import {Modal} from "react-bootstrap";
import CSVReader from "react-csv-reader";
import {JsonForm} from "./JsonForm";
import {IJsonFromField} from "./FieldJsonForm";
import {ICampoDaFonteDeDados, IFonteDeDados,} from "../models/FonteDeDados";
import {ApiService} from "../services/ApiService";
import {CampoHelpers} from "../helpers/CampoHelpers";
import moment from "moment";

function obterNomeDeColunas(campos: ICampoDaFonteDeDados[], dataForm: any) {
  return campos
    .filter((x) => !!x && !x.multivalor)
    .reduce((pre, x) => {
      if (dataForm["coluna_" + x.id?.toString()]) {
        pre.push(
          ...dataForm["coluna_" + x.id?.toString()].map((col: string) => {
            return `${x.nome} (${
              x.fonte?.campos.find((y) => String(y.keyOrigem) === col)?.nome ||
              ""
            })`;
          })
        );
      } else if (String(x.tipo) === "AGRUPAMENTO") {
        pre.push(...(x.fonte?.campos.map((x) => x.nome) || []));
      } else {
        pre.push(x.nome);
      }
      return pre;
    }, [] as string[]);
}

let parse: any = null;
let cacheColunasExternas = {} as any;

export function ButtonImportCsv(
  props: PropsWithoutRef<{
    onImportCsv: (data: any) => void;
    fonteDeTrabalho: IFonteDeDados;
    fontes: IFonteDeDados[];
  }>
) {
  const [show, setShow] = useState(false);
  const [progresso, setProgresso] = useState(0);
  const [linhasAnalisadas, setLinhasAnalisadas] = useState(0);
  const [dataForm, setDataForm] = useState(null as any);
  const [mensagens, setMensagem] = useState(
    [] as Array<{ mensagem: string; tipo: "danger" | "warning" }>
  );

  const jsonFromsCampos: IJsonFromField[] = [
    {
      name: "possuiCabecario",
      label: "Arquivo Possui Cabeçario?",
      placeholder: "Possui Cabeçario?",
      type: "select",
      choices: [
        {
          label: "Sim",
          value: "Sim",
        },
        {
          label: "Não",
          value: "Não",
        },
      ],
      validators: {
        required: {
          value: true,
          message: "obrigatório",
        },
      },
    },
  ];
  const obrigatorios = new Array<ICampoDaFonteDeDados>();
  const opcionais = new Array<ICampoDaFonteDeDados>();
  const naoInformaveis = new Array<ICampoDaFonteDeDados>();
  if (props.fonteDeTrabalho.espelho) {
    jsonFromsCampos.push({
      name: "chavesEspelho",
      label: "Qual coluna deseja usar como chave?",
      placeholder: "Qual chave?",
      type: "multi-select",
      choices: CampoHelpers.obterEscolhasDeCamposPorFonte({
        fontes: props.fontes,
        fonteId: props.fonteDeTrabalho.id,
        filtros: props.fonteDeTrabalho.campos
          .filter((x) => x.espelho && x.tipo.toString() !== "LISTA")
          .map((x) => x.id),
      }),
      validators: {
        required: {
          value: true,
          message: "obrigatório",
        },
      },
    });
  }
  props.fonteDeTrabalho.campos.forEach((campo) => {
    if (
      campo.espelho &&
      dataForm &&
      dataForm["chavesEspelho"].indexOf(campo?.id?.toString()) > -1
    ) {
      obrigatorios.push(campo);
    } else if (!campo.espelho) {
      if (String(campo.tipo) === "NSU") {
        naoInformaveis.push(campo);
      } else {
        if (campo.validacoes.required) {
          obrigatorios.push(campo);
        } else {
          opcionais.push(campo);
        }
        if (dataForm && campo.fonte) {
          if (String(campo.tipo) === "LISTA" && !campo.multivalor) {
            const fonte = props.fontes.find(
              (x) => campo.fonte && x.id === campo.fonte.id
            );
            if (fonte && String(fonte.tipo) !== "ESTATICA") {
              const listCampos = dataForm["coluna_" + String(campo.id)];
              listCampos.forEach((x: any, index: number) => {
                if (index > 0) {
                  if (campo.validacoes.required) {
                    obrigatorios.push(null as any);
                  } else {
                    opcionais.push(null as any);
                  }
                }
              });
            }
          } else if (String(campo.tipo) === "AGRUPAMENTO") {
            campo.fonte.campos.forEach((x, i) => {
              if (i > 0) {
                if (campo.validacoes.required) {
                  obrigatorios.push(null as any);
                } else {
                  opcionais.push(null as any);
                }
              }
            });
          }
        }

        if (campo.fonte && String(campo.tipo) === "LISTA" && !campo.multivalor) {
          const fonte = props.fontes.find(
            (x) => campo.fonte && x.id === campo.fonte.id
          );
          if (fonte && String(fonte.tipo) !== "ESTATICA") {
            jsonFromsCampos.push({
              name: "coluna_" + campo.id,
              label: `Qual coluna deseja usar para o relacionameto ${campo.nome}?`,
              placeholder: "Selecione uma coluna",
              type: "multi-select",
              choices: campo.fonte.campos.map((x) => {
                return { label: String(x.nome), value: x.keyOrigem };
              }),
              validators: {
                required: {
                  value: true,
                  message: "obrigatório",
                },
              },
            });
          }
        }
      }
    }
  });
  let camposEmOrdem = [...obrigatorios, ...opcionais];
  const quantidadeDeColunas = camposEmOrdem.length;
  let linhas = 0;
  const configImport = {
    header: false,
    skipEmptyLines: "greedy" as "greedy",
    step: async (results: any, parser: any) => {
      parse = parser;
      linhas += 1;
      if (dataForm && dataForm.possuiCabecario === "Sim" && linhas === 1)
        return;
      setLinhasAnalisadas((l) => l + 1);
      setProgresso(1);
      parser.pause();
      let inputData = {} as any;
      if (props.fonteDeTrabalho.espelho) {
        inputData["chaves"] = dataForm["chavesEspelho"];
      }
      if (quantidadeDeColunas - results.data.length !== 0) {
        setMensagem((e) => {
          return [
            ...e,
            {
              tipo: "danger",
              mensagem: `Erro linha ${linhas} -> A linha deve conter ${quantidadeDeColunas} colunas.`,
            },
          ];
        });
        parser.resume();
        return;
      }
      for (let key = 0; key < results.data.length; key++) {
        const campo = camposEmOrdem[key];
        const valor = results.data[key];
        if (campo) {
          if (campo.fonte && String(campo.tipo) === "LISTA") {
            const fonte = props.fontes.find((x) => x.id === campo.fonte?.id);
            if (fonte && String(fonte.tipo) !== "ESTATICA") {
              try {
                const listCampos = dataForm["coluna_" + String(campo.id)];
                const valores = {} as any;
                let chaveCache = campo.fonte.id;
                for (
                  let keyListaCampos = 0;
                  keyListaCampos < listCampos.length;
                  keyListaCampos++
                ) {
                  valores[listCampos[keyListaCampos]] =
                    results.data[key + keyListaCampos];
                  chaveCache += `${listCampos[keyListaCampos]}${
                    results.data[key + keyListaCampos]
                  }`;
                }
                key += listCampos.length - 1;
                if (cacheColunasExternas[chaveCache]) {
                  inputData[String(campo.id)] =
                    cacheColunasExternas[chaveCache];
                } else {
                  const registro = await ApiService.get(
                    "/fonte-de-dados/valores",
                    campo.fonte.id,
                    {
                      filters: dataForm["coluna_" + String(campo.id)],
                      ...valores,
                    }
                  ).then((data) => {
                    return data.docs.length > 0 ? data.docs[0].id : null;
                  });
                  inputData[String(campo.id)] = registro;
                  cacheColunasExternas[chaveCache] = registro;
                }
              } catch (e) {
                // eslint-disable-next-line
                setMensagem((e) => {
                  return [
                    ...e,
                    {
                      tipo: "danger",
                      mensagem: `Erro linha ${linhas} -> Erro ao buscar relacionamento da coluna ${campo.nome}`,
                    },
                  ];
                });
                parser.resume();
                return;
              }
            } else {
              inputData[String(campo.id)] = fonte?.valoresEstaticos.find(
                (x) =>
                  x.valor.trim().toUpperCase() ===
                  String(valor).trim().toUpperCase()
              )?.id;
            }
          } else if (
            String(campo.tipo) === "DATA" ||
            String(campo.tipo) === "DATA_COM_HORARIO"
          ) {
            inputData[String(campo.id)] = moment(valor).utc().toISOString();
          } else if (String(campo.tipo) === "AGRUPAMENTO") {
            const listCampos =
              campo.fonte?.campos.map((x) => {
                return { key: x.key, multivalor: !!x.multivalor, nome: x.nome };
              }) || [];
            for (
              let keyListaCampos = 0;
              keyListaCampos < listCampos.length;
              keyListaCampos++
            ) {
              const valorCelula = results.data[key + keyListaCampos];
              inputData[listCampos[keyListaCampos].key] = listCampos[
                keyListaCampos
              ].multivalor
                ? String(valorCelula)
                    .split(",")
                    .map((x) => x.trim())
                : valorCelula;
            }
            key += listCampos.length - 1;
          } else if (
            String(campo.tipo) === "NUMERICO" ||
            String(campo.tipo) === "NSU"
          ) {
            inputData[String(campo.id)] = Number(valor);
          } else {
            inputData[String(campo.id)] = valor;
          }
        }
      }
      if (!props.fonteDeTrabalho.espelho) {
        ApiService.post(
          "/fonte-de-dados/valores/" + props.fonteDeTrabalho.id,
          inputData
        ).then(
          () => {
            parser.resume();
          },
          (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
              setMensagem((e) => {
                return [
                  ...e,
                  {
                    tipo: "danger",
                    mensagem: `Erro linha ${linhas} -> ${Object.keys(errors)
                      .map((key) => {
                        return `Coluna ${
                          camposEmOrdem.find((x) => x && String(x.id) === key)
                            ?.nome
                        } - ${String(errors[key])}`;
                      })
                      .join(" | ")}`,
                  },
                ];
              });
            } else {
              setMensagem((e) => {
                return [
                  ...e,
                  {
                    tipo: "danger",
                    mensagem: `Erro linha ${linhas} -> Erro ao inserir a linha tente novamente`,
                  },
                ];
              });
            }
            parser.resume();
          }
        );
      } else {
        ApiService.put(
          "/fonte-de-dados/valores/import-espelhada",
          props.fonteDeTrabalho.id,
          inputData
        ).then(
          (valor) => {
            if (valor.alteradas !== 1) {
              setMensagem((e) => {
                return [
                  ...e,
                  {
                    tipo: "warning",
                    mensagem: `Atenção: A linha ${linhasAnalisadas} alterou ${valor.alteradas} registros`,
                  },
                ];
              });
            }
            parser.resume();
          },
          (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
              setMensagem((e) => {
                return [
                  ...e,
                  {
                    tipo: "danger",
                    mensagem: `Erro linha ${linhas} -> ${Object.keys(errors)
                      .map((key) => {
                        return `Coluna ${
                          camposEmOrdem.find((x) => x && String(x.id) === key)
                            ?.nome
                        } - ${String(errors[key])}`;
                      })
                      .join(" | ")}`,
                  },
                ];
              });
            } else {
              setMensagem((e) => {
                return [
                  ...e,
                  {
                    tipo: "danger",
                    mensagem: `Erro linha ${linhas} -> Erro ao inserir a linha tente novamente`,
                  },
                ];
              });
            }
            parser.resume();
          }
        );
      }
    },
    complete: () => {
      setProgresso(2);
      parse = null;
      cacheColunasExternas = {};
    },
    error: () => {
      setProgresso(3);
      parse = null;
      cacheColunasExternas = {};
    },
  };

  const handleClose = () => {
    if (parse) parse.abort();
    setShow(false);
    setProgresso(0);
    setLinhasAnalisadas(0);
    setDataForm(null as any);
    setMensagem([]);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="btn-group">
        <button className="btn btn-warning" onClick={handleShow}>
          <i className="fa fa-upload" /> Importar CSV
        </button>
      </div>
      <Modal show={show} size={"xl"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Importação de dados via CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!dataForm ? (
            <>
              Configure abaixo sua imporação:
              <br />
              <br />
              <JsonForm
                name={"ConfigImport"}
                fields={jsonFromsCampos}
                onSubmit={(data) => {
                  setLinhasAnalisadas(0);
                  setDataForm(data);
                }}
                textSubmitButton={"Continuar"}
              />
            </>
          ) : (
            <>
              Possui cabeçario? {dataForm.possuiCabecario}
              <br />
              O arquivo deverá conter a seguinte ordem de colunas:
              <br />
              {obterNomeDeColunas(obrigatorios, dataForm).map((x) => (
                <>
                  <br />
                  {x} (Obrigatório)
                </>
              ))}
              {obterNomeDeColunas(opcionais, dataForm).map((x) => (
                <>
                  <br />
                  {x}
                </>
              ))}
              <br />
              <br />
              {progresso === 0 ? (
                <>
                  <CSVReader
                    onFileLoaded={(data, fileInfo) =>
                      console.dir(data, fileInfo)
                    }
                    parserOptions={configImport}
                  />
                  <br />
                </>
              ) : progresso === 1 || progresso === 2 ? (
                <>
                  {progresso === 2 ? (
                    <>
                      Arquivo processado com{" "}
                      {mensagens.filter((x) => x.tipo === "danger").length === 0
                        ? "sucesso!"
                        : "falha!"}
                    </>
                  ) : (
                    <>
                      Processando arquivo, por favor aguarde...{" "}
                      <i className="fas fa-spinner fa-pulse" />
                      <br />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          if (parse) parse.abort();
                        }}
                      >
                        PARAR IMPORTAÇÃO
                      </button>
                    </>
                  )}
                  <br />
                  <b>
                    {linhasAnalisadas} linhas processadas,{" "}
                    {mensagens.filter((x) => x.tipo === "danger").length} erros
                    encontrados e{" "}
                    {linhasAnalisadas -
                      mensagens.filter((x) => x.tipo === "danger").length}{" "}
                    linhas inseridas.
                  </b>
                  <br />
                  <div
                    className="bg-light p-2 overflow-auto"
                    style={{ height: "200px" }}
                  >
                    {mensagens.length > 0 ? (
                      mensagens
                        .filter((x) => x.tipo === "danger")
                        .map((x) => (
                          <>
                            <span className={"text-" + x.tipo}>
                              {x.mensagem}
                            </span>
                            <br />
                          </>
                        ))
                    ) : (
                      <>
                        Nenhum erro encontrado
                        <br />
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  Ocorreu um erro ao abrir seu arquivo, verifique e tente
                  novamente
                  <br />
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => {
                      setDataForm(null as any);
                      setProgresso(0);
                    }}
                  >
                    Tentar Novamente
                  </button>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
