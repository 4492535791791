import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Dropdown, Modal} from "react-bootstrap";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {IComponente, IDashboard} from "../models/Dashboard";
import {FormularioCartaoDashboard} from "./FormularioCartaoDashboard";


export function ButtonCartaoDashboard(props: { onAdd: (dashboard: IDashboard) => void, projeto: IProjeto, dashboard: IDashboard, fontes: IFonteDeDados[], componenteEdicao?: IComponente }) {

    const [show, setShow] = useState(false);

    const handlerSubmit = (data: any, callbackError: (error: any) => void) => {
        if (props.dashboard) {
            const dataSend = {
                ...props.dashboard
            }
            if (props.componenteEdicao) {
                const inx = dataSend.componentes.findIndex(x => x.id === props.componenteEdicao?.id)
                dataSend.componentes[inx] = {
                    ...dataSend.componentes[inx],
                    titulo: data.titulo,
                    mascara: data.mascara,
                    valor: {
                        campo: data.valor,
                        agrupameto: data.agrupamento
                    }
                } as IComponente
            } else {
                dataSend.componentes?.push({
                    titulo: data.titulo,
                    mascara: data.mascara,
                    fonteId: data.fonteId,
                    tipo: "CARD",
                    valor: {
                        campo: data.valor,
                        agrupameto: data.agrupamento
                    }
                } as IComponente)
            }
            ApiService.put("/dashboards/" + props.projeto.id, props.dashboard.id, dataSend).then(dash => {
                props.onAdd(dash);
                setShow(false);
            }, (error) => {
                const errors = error.data?.errors;
                if (error.status === 400 && errors) {
                    callbackError(Object.keys(errors).map(key => {
                        return {
                            name: key,
                            type: "ValidationError" + key,
                            message: String(errors[key])
                        }
                    }));
                }
            })
        }
    }

    return (<div className="d-inline-block">
        {!props.componenteEdicao && <Dropdown.Item onClick={() => setShow(true)}>Cartão</Dropdown.Item>}
        {props.componenteEdicao && <button className='btn btn-primary' onClick={() => setShow(true)}>
            <i className='fa fa-pencil'/>
        </button>}
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Cartão</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && <FormularioCartaoDashboard dashboard={props.dashboard}
                                                    fontes={props.fontes} componenteEdicao={props.componenteEdicao}
                                                    onCancel={() => {
                                                        setShow(false);
                                                    }} onSubmit={(data, onErr) => {
                    handlerSubmit(data, onErr);
                }}/>}
            </Modal.Body>
        </Modal>
    </div>);
}
