import React, {PropsWithChildren, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {IProjeto} from "../../models/Projeto";
import {IFonteDeDados} from "../../models/FonteDeDados";
import {IUsuario} from "../../models/Usuario";
import {ProjetoSlidebar} from "../../componets/ProjetoSlidebar";
import {IDashboard} from "../../models/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import {AppRootState} from "../../reducers";
import {ProjetoState} from "../../reducers/ProjetoStore";
import {ApiService} from "../../services/ApiService";
import {Loading} from "../../componets/Loading";
import {setDashboards, setFontes} from "../../actions/ProjetoActions";

export enum RotasInternas {
    HOME = "",
    FORMULARIO = "formulario",
    FONTES = "config-fonte-de-dados",
    DASH = "config-dashboard",
    COMPART = "config-compartilhamentos"
}

export interface PropsProjetoPages {
    projeto: IProjeto,
    usuario: IUsuario,
    administrador: boolean,
    fontes: IFonteDeDados[],
    toggleMenu: boolean,
    dashboards: IDashboard[]
    headlerToggleMenu: (t: boolean) => void
}

export function ProjetoPage(props: PropsWithChildren<{ projetoId: string, render: (props: PropsProjetoPages) => React.ReactNode }>) {
    const dispatch = useDispatch();
    const projetoState = useSelector<AppRootState, ProjetoState>((state) => state?.projeto)
    const usuario = useSelector<AppRootState, IUsuario | undefined>(({sessao}) => sessao?.usuario)
    const projeto = projetoState.projetos?.find(x => x.id === props.projetoId) || undefined;
    const fontes = projetoState?.fontes[props.projetoId];
    const dashboards = projetoState?.dashboards[props.projetoId];
    const [toggle, setToggle] = useState(true);

    const administrador = projeto?.proprietario.id === usuario?.id
        || !!projeto?.usuarios.find(x => x.id === usuario?.id && x.administrador)
    useEffect(() => {
        if (!fontes && !dashboards && dispatch && props.projetoId) {
            ApiService.get("/fonte-de-dados", props.projetoId).then((fts: IFonteDeDados[]) => {
                ApiService.get("/dashboards", props.projetoId).then((dashs: IDashboard[]) => {
                    dispatch(setFontes(fts, props.projetoId));
                    dispatch(setDashboards(dashs, props.projetoId));
                });
            });
        }
    }, [fontes, dashboards, dispatch, props.projetoId])

    if (!projeto) {
        return (
            <div>
                <h1 className="text-center mt-5">
                    Pagina não encontrada
                </h1>
                <p className="text-center">
                    <Link to="/">
                        Voltar para Home
                    </Link>
                </p>
            </div>
        )
    } else if (!fontes || !dashboards) {
        return <Loading/>;
    } else {
        const Page: any = props.render;
        const pageProps = {
            projeto: projeto,
            fontes: fontes,
            usuario: usuario,
            administrador: administrador,
            toggleMenu: toggle,
            dashboards: dashboards,
            headlerToggleMenu: (t: any) => setToggle(t)
        };
        return (
            <div className="container-fluid">
                <div className='row'>
                    <div
                        className={(toggle ? 'col-md-4 col-lg-3 col-xl-2 slidebar-content' : 'd-none slidebar-content')}>
                        {usuario && <ProjetoSlidebar projeto={projeto}
                                                     usuarioId={usuario?.id}
                                                     onToggle={setToggle}
                                                     collapsed={false}
                                                     administrador={administrador}
                                                     toggled={toggle}/>}
                    </div>
                    <div className={(toggle ? 'col-12 col-md-8 col-lg-9 col-xl-10' : 'col-md-12')}>
                        {projeto && usuario && <Page {...pageProps} />}
                    </div>
                </div>
            </div>
        );
    }
}
