import axios, {AxiosRequestConfig} from 'axios';
import {ApiEvent} from "../events/AppEvent";
import {ApiEventsType, ApiService} from "../services/ApiService";
// @ts-ignore
import qs from 'qs';

const constructURL = (path: string) => {
    const fullPath = ApiService.BASE_URL + path;
    const [pathUrl, ...query] = fullPath.split('?');
    if (!pathUrl.endsWith('/')) {
        return pathUrl + '/' + (query.length ? '?' : '') + query.join('?');
    }
    return fullPath;
};

const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

const _buildHeaders = (headers = {}) => {
    return {...DEFAULT_HEADERS, ...headers};
};

export class NetworkUnauthorizedError extends Error {
    constructor(...params: any[]) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        this.name = 'NetworkUnauthorizedError';
    }
}

export class NetworkParamError extends Error {
    errors: any;

    constructor(errors: any, ...params: any[]) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        this.name = 'NetworkParamError';
        this.errors = errors;
    }
}

export const fnApiCall = (
    path: string,
    {method = 'get', headers = {}, data = null, ...options}: any
) => {
    const promise = axios(constructURL(path), {
        ...options,
        method,
        data,
        headers: _buildHeaders(headers),
        withCredentials: true,
        paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'brackets' })
    } as AxiosRequestConfig).catch((error) => {
        const {response} = error;
        ApiEvent.emit(ApiEventsType.HTTP_ERROR, {
            status: response.status,
            error: response.statusText
        });
        if (response.data && response.status === 400) {
            throw new NetworkParamError(response.data);
        }
        return error;
    });

    return method === 'get'
        ? promise.then((response: any) => response?.data)
        : promise;
};
