import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/ApiService";
import {Link} from "react-router-dom";
import {IUsuario} from "../../models/Usuario";
import {useDispatch, useSelector} from "react-redux";
import {AppRootState} from "../../reducers";
import {setProjetos as setGlobalPrjetos} from "../../actions/ProjetoActions"

export function HomePage() {
    const dispatch = useDispatch();
    const usuario = useSelector<AppRootState, IUsuario | undefined>(({sessao}) => sessao.usuario);
    const [projetos, setProjetos] = useState(undefined as any);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (dispatch) {
            setLoader(true);
            ApiService.getAll("/projeto").then(projetos => {
                setProjetos(projetos);
                dispatch(setGlobalPrjetos(projetos))
                setLoader(false);
            }, () => {
                setProjetos(null as any);
                setLoader(false);
            });
        }
    }, [dispatch]);

    return (
        <div className="Home mt-5">
            <div className="container-fluid min-vh-100 ">
                <div className="row">
                    <div className="col-md-9 mx-auto col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Projetos</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        {usuario?.criarProjeto && <Link to="/projeto/add"
                                                                        className="btn btn-primary d-md-inline-block d-block btn-sm">
                                            <i className="fas fa-plus"/> Novo projeto
                                        </Link>}
                                    </div>
                                </div>
                                <hr/>
                                <div className="row mt-2">
                                    {loader && <div className="col-12"><h1 className="text-center mt-5">
                                        <i className="fas fa-spinner fa-pulse"/>
                                    </h1></div>}
                                    {!loader && projetos === null &&
                                        <div className="col-12"><p className="text-center mt-5">Falha ao carregar
                                            projetos,
                                            tente novamente mais
                                            tarde!</p></div>}
                                    {!loader && projetos && (
                                        projetos.length > 0 ? (projetos.map((projeto: any) => {
                                            return (
                                                <div className="col-md-4 mx-auto" key={projeto.id}>
                                                    <Link to={"/projeto/" + (projeto.id)} className="btn d-block">
                                                        <div className="d-block mx-auto">
                                                            <div className="card border-secondary mb-3">
                                                                <div
                                                                    className="card-header bg-primary text-white">{projeto.nome}</div>
                                                                <div className="card-body text-left ">
                                                                    <p className="card-text">
                                                                        {projeto.descricao}
                                                                    </p>
                                                                    <hr/>
                                                                    <small className="small" style={
                                                                        {
                                                                            "fontSize": "50%"
                                                                        }
                                                                    }>Criado
                                                                        por {projeto.proprietario.nome} em {new Date(projeto.createdAt).toLocaleString()}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })) : <p className="p-3">Nenhum projeto cadatrado</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
