import {IFonteDeDados} from "../models/FonteDeDados";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {CampoHelpers} from "../helpers/CampoHelpers";
import {ApiService} from "../services/ApiService";
import {IRegistro} from "../models/Registro";
import {IJsonFromField} from "./FieldJsonForm";
import {JsonForm} from "./JsonForm";

async function obterLinhaParaCalculo(fonteDeTrabalho: IFonteDeDados, fontes: IFonteDeDados[], valores: any, updatedData = true) {
    const linha = {
        'id': valores.id,
    } as any;
    for (let i = 0; i < fonteDeTrabalho.campos.length; i++) {
        const c = fonteDeTrabalho.campos[i];
        const valor = await CampoHelpers.obterCampoParaFuncao(fontes, c, valores, updatedData);
        if (valor !== undefined) {
            linha[c.nome] = valor;
        }
    }
    return linha;
}

async function recalucarFuncao(idRegistro: string, fonteDeTrabalho: IFonteDeDados, fontes: IFonteDeDados[], camposFuncoes: string[]) {
    const registro: IRegistro = await ApiService.get("/fonte-de-dados/valores/" + fonteDeTrabalho.id, idRegistro);
    const valores = {...registro} as any;
    for (const campo of fonteDeTrabalho.campos) {
        if (String(campo.tipo) === "LISTA") {
            if (valores[campo.id + "Id"]) {
                valores[campo.id] = valores[campo.id + "Id"];
            }
        }
    }

    for (const campo of fonteDeTrabalho.campos.filter(x => !!x.metaDataFuncao && camposFuncoes.some(y => y === x.id))) {
        try {
            valores[campo.id] = await CampoHelpers.processarFuncao(fontes, campo?.metaDataFuncao!, await obterLinhaParaCalculo(fonteDeTrabalho, fontes, valores, false));
        } catch (e) {
            return Promise.reject();
        }
    }
    return ApiService.put("/fonte-de-dados/valores/" + fonteDeTrabalho.id, idRegistro, valores);
}

export function ButtonCalculoColunasFuncao(props: { fonteDeTrabalho: IFonteDeDados, fontes: IFonteDeDados[], disabled?: boolean, onCancel: () => void, idsCalculo: string[] }) {

    const [show, setShow] = useState(false);
    const [recalculando, setRecalculando] = useState(false);
    const [idsRecalculados, setIdsRecalculados] = useState(0);
    const [cancelado, setCancelado] = useState(false);
    const [colunas, setColunas] = useState([] as string[]);
    useEffect(() => {
        if (idsRecalculados < props.idsCalculo.length && recalculando && !cancelado && colunas.length > 0) {
            recalucarFuncao(props.idsCalculo[idsRecalculados], props.fonteDeTrabalho, props.fontes, colunas).then(() => {
                setIdsRecalculados(id => id + 1);
            }, () => {
                setIdsRecalculados(id => id + 1);
            });
        }
    }, [idsRecalculados, props.idsCalculo, recalculando, cancelado, colunas, props.fontes, props.fonteDeTrabalho])


    const jsonField: IJsonFromField = {
        name: 'campos',
        label: "Selecione quais colunas deseja recalcular",
        placeholder: "Quais colunas deseja recalcular?",
        type: "multi-select",
        choices: props.fonteDeTrabalho.campos.filter(x => !!x.metaDataFuncao && !x.espelho).map(x => {
            return {
                label: x.nome,
                value: x.id
            }
        }),
        validators: {
            required: true
        }
    };

    return (<>
        <div className="d-inline-block">
            <button className="btn btn-warning" disabled={props.disabled} onClick={() => {
                setShow(true);
                setIdsRecalculados(0);
                setCancelado(false);
                setRecalculando(false);
                setColunas([]);
            }}><i
                className={"fa fa-calculator"}/> Recalcular Funções
            </button>
            <Modal show={show} size={"xl"} onHide={() => {
                props.onCancel();
                setShow(false);
                setCancelado(true);
                setColunas([]);
            }} backdrop={"static"}>
                <Modal.Header closeButton>
                    <Modal.Title>Recalcular Funções</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {colunas.length === 0 && <JsonForm name={"edicaoEmMassa"} fields={[jsonField]} onSubmit={(data) => {
                        setColunas(data['campos']);
                    }} textSubmitButton="Continuar"/>}
                    {!recalculando && colunas.length > 0 && <div>
                        <p>Você deseja realmente recalcular as
                            colunas {props.fonteDeTrabalho.campos.filter(x => !!x.metaDataFuncao && colunas.find(i => i === x.id)).map(x => x.nome).toString()} para
                            os {props.idsCalculo.length} registros?</p>
                        <button className={"btn btn-info"} onClick={() => {
                            setRecalculando(true);
                        }}>
                            Recalcular
                        </button>
                    </div>}
                    {recalculando && idsRecalculados < props.idsCalculo.length && <div>
                        <p>Recalculando
                            registros... <br/>{idsRecalculados} de {props.idsCalculo.length} calculados.<br/>
                            <button className={"btn btn-danger"} onClick={() => {
                                setRecalculando(false);
                                props.onCancel();
                                setShow(false);
                                setCancelado(true);
                            }}>
                                Cancelar
                            </button>
                        </p>
                    </div>}
                    {idsRecalculados >= props.idsCalculo.length && <div>
                        <p>
                            Processo concluído, <br/>{idsRecalculados} de {props.idsCalculo.length} calculados.
                        </p>
                    </div>}
                </Modal.Body>
            </Modal>
        </div>
    </>);
}
