import {DataTypeProvider} from "@devexpress/dx-react-grid";
import {NumberFilter} from "./NumberFilter";
import React from "react";

const NumberRFilter = (props: DataTypeProvider.ValueEditorProps) => {
    const [tipo, ...query] = props.value
        ? props.value.replace("numero:", "").split("#")
        : [];
    return (
        <NumberFilter
            defaultValue={props.value ? {tipo, query: query.join("#")} : undefined}
            onChange={(data) =>
                data
                    ? props.onValueChange(`numero:${data.tipo}#${data.query}`)
                    : props.onValueChange(null)
            }
        />
    );
};

export default NumberRFilter;