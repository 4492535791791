import {Toolbar} from "@devexpress/dx-react-grid-bootstrap4";
import {Dropdown, Nav} from "react-bootstrap";
import {ButtonNovoItemFormulario} from "./ButtonNovoItemFormulario";
import {ButtonImportCsv} from "./ButtonImportCsv";
import {ButtonEdicaoEmMassaFormulario} from "./ButtonEdicaoEmMassaFormulario";
import {ButtonCalculoColunasFuncao} from "./ButtonCalculoColunasFuncao";
import {ButtonExport} from "./ButtonExportXlsx";
import {FormularioPageHelpers} from "../helpers/FormularioPageHelpers";
import React from "react";
import {IEvento, IFonteDeDados} from "../models/FonteDeDados";

type Props = {
    fonteDeTrabalho?: IFonteDeDados,
    usuarioPodeInserir?: boolean,
    usuarioPodeEditar?: boolean,
    usuarioPodeExcluir?: boolean,
    fontes: IFonteDeDados[],
    eventos?: IEvento[],
    onLoadData: () => void,
    selection: string[],
    onDelete: (selected: string[]) => void,
    onSelectItem: (items: string[]) => void,
    columns: any[],
    onShowConfVisualizacao: (show: boolean) => void,
    onCommitProfile: (data: any) => void,
    linkExportCsv: string,
    linkExportXlsx: string,
} & Toolbar.RootProps

const ToolbarHeader = (props: Props) => {
    const {
        fonteDeTrabalho, usuarioPodeInserir, usuarioPodeEditar, usuarioPodeExcluir, onLoadData, selection, onDelete,
        onSelectItem, columns, onShowConfVisualizacao, onCommitProfile, linkExportCsv, linkExportXlsx
    } = props
    return (
        <div className="p-2">
            <div className="row">
                <div className="col-md-10">
                    <Nav as="div">
                        {fonteDeTrabalho &&
                            !fonteDeTrabalho.espelho &&
                            usuarioPodeInserir && (
                                <Nav.Item as="div">
                                    <ButtonNovoItemFormulario
                                        fontes={props.fontes}
                                        fonteDeTrabalho={fonteDeTrabalho}
                                        onSave={onLoadData}
                                        onCancel={() => {
                                        }}
                                    />
                                </Nav.Item>
                            )}

                        {fonteDeTrabalho &&
                            (String(fonteDeTrabalho.tipo) === "ESPELHO"
                                ? usuarioPodeEditar
                                : usuarioPodeInserir) && (
                                <Nav.Item as="div">
                                    <ButtonImportCsv
                                        onImportCsv={onLoadData}
                                        fontes={props.fontes}
                                        fonteDeTrabalho={fonteDeTrabalho}
                                    />
                                </Nav.Item>
                            )}
                        {fonteDeTrabalho && usuarioPodeEditar && (
                            <Nav.Item as="div">
                                <ButtonEdicaoEmMassaFormulario
                                    fonteDeTrabalho={fonteDeTrabalho}
                                    onCancel={() => {
                                    }}
                                    onSave={onLoadData}
                                    fontes={props.fontes}
                                    disabled={selection.length <= 1}
                                    idsEdicaoMassa={selection}
                                />
                            </Nav.Item>
                        )}
                        {fonteDeTrabalho &&
                            usuarioPodeExcluir &&
                            !fonteDeTrabalho.espelho && (
                                <Nav.Item as="div">
                                    <button
                                        className={"btn btn-danger btn-block"}
                                        disabled={selection.length <= 1}
                                        type="button"
                                        onClick={() => {
                                            onDelete(selection);
                                        }}
                                    >
                                        <i className={"fa fa-trash"}/> Exclusão em Massa
                                    </button>
                                </Nav.Item>
                            )}
                        <Nav.Item as="div">
                            <button
                                className={"btn btn-light btn-block"}
                                disabled={!selection || selection.length === 1}
                                type="button"
                                onClick={() => {
                                    onSelectItem([]);
                                }}
                            >
                                <i className={"fa fa-eraser"}/> Limpar Seleção
                            </button>
                        </Nav.Item>
                        {fonteDeTrabalho &&
                            fonteDeTrabalho.campos.filter(
                                (x) => !!x.metaDataFuncao && !x.espelho
                            ).length > 0 && (
                                <Nav.Item as="div">
                                    <ButtonCalculoColunasFuncao
                                        onCancel={onLoadData}
                                        fontes={props.fontes}
                                        fonteDeTrabalho={fonteDeTrabalho}
                                        idsCalculo={selection.map((x) => String(x))}
                                        disabled={selection.length === 0}
                                    />
                                </Nav.Item>
                            )}
                        {props.children &&
                            Array.isArray(props.children) &&
                            props.children.map((x, i) => {
                                return (
                                    <Nav.Item as="div" key={i}>
                                        {x}
                                    </Nav.Item>
                                );
                            })}
                        {fonteDeTrabalho && (
                            <Nav.Item as="div">
                                <ButtonExport
                                    linkExportXlsx={linkExportXlsx}
                                    fonte={fonteDeTrabalho}
                                />
                            </Nav.Item>
                        )}
                    </Nav>
                </div>
                <div className="col-md-2 text-right">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="fas fa-cog"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    onShowConfVisualizacao(true);
                                }}
                            >
                                Colunas Visiveis
                            </Dropdown.Item>
                            {!!props.children &&
                                !Array.isArray(props.children) &&
                                props.children}
                            {!!props.children &&
                                Array.isArray(props.children) &&
                                props.children.map((x, i) => {
                                    return <span key={i}>{x}</span>;
                                })}
                            <Dropdown.Item
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            "Dejesa relamente resetar a cofiguração desta tabela?"
                                        )
                                    ) {
                                        onCommitProfile({
                                            columnOrder: FormularioPageHelpers.getColumnOrders(
                                                columns.map((x) => x.name),
                                                fonteDeTrabalho?.ordenacaoCampos
                                            ),
                                            pageSize: 100,
                                            hiddenColumnNames: [],
                                            columnsFix: [],
                                            columnWidths: columns.map((x) => {
                                                return {
                                                    columnName: x.name,
                                                    width: 150,
                                                };
                                            }),
                                            sorting: [],
                                        });
                                        window.location.reload();
                                    }
                                }}
                            >
                                Resetar Configuração
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default ToolbarHeader;