import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Dropdown, Modal} from "react-bootstrap";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {IComponente, IDashboard} from "../models/Dashboard";
import {FormularioGraficoPizzaDashboard} from "./FormularioGraficoPizzaDashboard";


export function ButtonGraficoPizzaDashboard(props: { onAdd: (dashboard: IDashboard) => void, projeto: IProjeto, dashboard: IDashboard, fontes: IFonteDeDados[], componenteEdicao?: IComponente }) {

    const [show, setShow] = useState(false);

    const handlerSubmit = (data: any, callbackError: (error: any) => void) => {
        const dataSend = {
            ...props.dashboard
        }
        if (props.componenteEdicao) {
            const inx = dataSend.componentes.findIndex(x => x.id === props.componenteEdicao?.id)
            dataSend.componentes[inx] = {
                ...dataSend.componentes[inx],
                titulo: data.titulo,
                legenda: data.legenda,
                valor: {
                    campo: data.valor,
                    agrupameto: data.agrupamento
                }
            } as IComponente
        } else {
            dataSend.componentes?.push({
                titulo: data.titulo,
                fonteId: data.fonteId,
                tipo: "PIZZA",
                legenda: data.legenda,
                mascara: "PERCENTUAL",
                valor: {
                    campo: data.valor,
                    agrupameto: data.agrupamento
                }
            } as IComponente)
        }
        ApiService.put("/dashboards/" + props.projeto.id, props.dashboard.id, dataSend).then(dash => {
            props.onAdd(dash);
            setShow(false);
        }, (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
                callbackError(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: "ValidationError" + key,
                        message: String(errors[key])
                    }
                }));
            }
        })
    }

    return (<div className="d-inline-block">
        {!props.componenteEdicao && <Dropdown.Item onClick={() => setShow(true)}>Gráfico de Pizza</Dropdown.Item>}
        {props.componenteEdicao && <button className='btn btn-primary' onClick={() => setShow(true)}>
            <i className='fa fa-pencil'/>
        </button>}
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Gráfico de Pizza</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && <FormularioGraficoPizzaDashboard dashboard={props.dashboard} fontes={props.fontes}
                                                          componenteEdicao={props.componenteEdicao} onCancel={() => {
                    setShow(false);
                }} onSubmit={(data, onErr) => {
                    handlerSubmit(data, onErr);
                }}/>}
            </Modal.Body>
        </Modal>
    </div>);
}
