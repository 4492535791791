import {ICampoDaFonteDeDados, IFonteDeDados} from "../models/FonteDeDados";
import {IJsonFromFieldChoice} from "../componets/FieldJsonForm";
import {ApiService} from "../services/ApiService";
import {TipoDeCampoFonteDeDados} from "../componets/FormularioCampoFonteDados";
import {TipoDeFonteDeDados} from "../componets/FormularioFonteDados";
import moment from "moment-timezone";
import {isObject} from "lodash";

export interface MetaDataColuna {
    nome: string,
    tipo: string,
    keyOrigem: string,
    multivalor?: boolean,
    id: string
}

export const TIPOS_AGRUPAMENTOS: { [key: string]: string } = {
    "SOMA": "Soma",
    "MEDIA": "Média",
    "PRODUTO": "Produto",
    "MAXIMA": "Máxima",
    "MINIMA": "Mínima",
    "CONTAGEM": "Contagem",
    "CONTAGEMUNI": "Contagem única"
}

export class CampoHelpers {


    static obterValorResultadoPorTipo(nome: string, tipo: string, chave: string): { label: string, value: string }[] {
        const choices = CampoHelpers.obterChoicesTiposDeAgrupamento(tipo);
        return choices.map(x => {
            return {
                label: `${x.label} de ${nome}`,
                value: `${x.value}|${chave}`
            }
        });
    }

    static obterChoicesTiposDeAgrupamento(tipo: string) {
        const choices: { label: string, value: string }[] = [];
        Object.keys(TIPOS_AGRUPAMENTOS).forEach((x: any) => {
            if (tipo === "NUMERICO" || x === "CONTAGEM" || x === "CONTAGEMUNI") {
                choices.push({
                    label: TIPOS_AGRUPAMENTOS[x],
                    value: x
                });
            }
        })
        return choices;
    }


    static obterEscolhasDeResultadosDeCamposPorFonte(opcoes: {
        fontes: IFonteDeDados[],
        fonteId: string,
        campoId?: string,
        filtros?: string[]
    }): { label: string, value: string }[] {
        const choices: { label: string, value: string }[] = [];
        const fonteSelecionada = opcoes.fontes.find((x: any) => x.id === opcoes.fonteId);
        if (fonteSelecionada) {
            if (opcoes.campoId) {
                const campo = fonteSelecionada.campos.find((x: ICampoDaFonteDeDados) => x.id?.toString() === opcoes.campoId);
                if (campo && !campo.multivalor) {
                    if (campo.fonte && campo.fonte.campos && campo.fonte.campos.length > 0 && campo.fonte.id) {
                        campo.fonte.campos.forEach(x => {
                            choices.push(...CampoHelpers.obterValorResultadoPorTipo(x.nome, String(x.tipo), x.key))
                        })
                    } else {
                        choices.push(...CampoHelpers.obterValorResultadoPorTipo(campo.nome, String(campo.tipo), campo.id));
                    }
                }
            } else {
                fonteSelecionada.campos.filter((x: ICampoDaFonteDeDados) => !opcoes.filtros || opcoes?.filtros.find(y => x.id?.toString() === y)).forEach((campo: any) => {
                    choices.push(...CampoHelpers.obterEscolhasDeResultadosDeCamposPorFonte({
                        fontes: opcoes.fontes,
                        fonteId: opcoes.fonteId,
                        campoId: campo.id
                    }));
                });
            }
        }
        return choices;
    }

    static obterEscolhasDeCamposPorFonte(opcoes: {
        fontes: IFonteDeDados[],
        fonteId: string,
        campoId?: string,
        filtros?: string[]
    }): { label: string, value: string, podeRemover?: boolean }[] {
        const choices: { label: string, value: string, podeRemover?: boolean }[] = [];
        const fonteSelecionada = opcoes.fontes.find((x: any) => x.id === opcoes.fonteId);
        if (fonteSelecionada) {
            if (opcoes.campoId) {
                const campo = fonteSelecionada.campos.find((x: ICampoDaFonteDeDados) => x.id?.toString() === opcoes.campoId);
                if (campo) {
                    if (campo.fonte && campo.fonte.campos && campo.fonte.campos.length > 0 && campo.fonte.id && !campo.multivalor) {
                        campo.fonte.campos.forEach(x => {
                            choices.push({
                                label: x.nome,
                                value: x.key,
                                podeRemover: x.podeRemover,
                            })
                        })
                    } else {
                        choices.push({
                            label: campo.nome,
                            value: campo.id?.toString() || "",
                            podeRemover: campo.podeRemover,
                            // value: campoAnterior? campo.id
                        });
                    }
                }
            } else {
                fonteSelecionada.campos.filter((x: ICampoDaFonteDeDados) => (!opcoes.filtros || opcoes?.filtros.find(y => x.id?.toString() === y))).forEach((campo: any) => {
                    choices.push(...CampoHelpers.obterEscolhasDeCamposPorFonte({
                        fontes: opcoes.fontes,
                        fonteId: opcoes.fonteId,
                        campoId: campo.id
                    }));
                });
            }
        }
        return choices;
    }

    static async objectIdToUuid(objectIdStr: string): Promise<string> {
        return ApiService.getAll("/converter-object-id", {objectId: objectIdStr});
    }

    static async agruparValoresPorFonte(fontes: IFonteDeDados[], idDaFonte: string, tipoResultado: string, colunaResultado: string, filtros: {
        [key: string]: string
    }) {
        const uuidFonte = await CampoHelpers.objectIdToUuid(idDaFonte);
        const fonteDeTrabalho = fontes.find(x => x.id === uuidFonte);
        let keys = {} as any;
        if (fonteDeTrabalho) {
            keys = fonteDeTrabalho.campos.reduce((previousValue, currentValue) => {
                if (currentValue.fonte?.campos && currentValue.fonte?.campos.length > 0) {
                    currentValue.fonte.campos.forEach(x => {
                        previousValue[x.nome] = x.key;
                    })
                } else {
                    previousValue[currentValue.nome] = currentValue.id;
                }
                return previousValue;
            }, {} as any);
        }
        if (!keys[colunaResultado]) {
            throw Error(`Coluna ${colunaResultado} resultado não encontrada na fonte ${fonteDeTrabalho?.nome}`);
        }
        const params = {
            tipoResultado: tipoResultado,
            resultado: keys[colunaResultado],
            filters: Object.keys(filtros).map(x => {
                return keys[x]
            }).filter(x => !!x),
        } as any;
        Object.keys(filtros).forEach(x => {
            params[keys[x]] = filtros[x]
        })
        return ApiService.get("/fonte-de-dados/valores", uuidFonte, params)
    }

    static async agruparColunaPorFonte(fontes: IFonteDeDados[], idDaFonte: string, coluna: string, filtros: {
        [key: string]: string
    }) {
        const uuidFonte = await CampoHelpers.objectIdToUuid(idDaFonte);
        const fonteDeTrabalho = fontes.find(x => x.id === uuidFonte);
        let keys = {} as any;
        if (fonteDeTrabalho) {
            keys = fonteDeTrabalho.campos.reduce((previousValue, currentValue) => {
                if (currentValue.fonte?.campos && currentValue.fonte?.campos.length > 0) {
                    currentValue.fonte.campos.forEach(x => {
                        previousValue[x.nome] = x.key;
                    })
                } else {
                    previousValue[currentValue.nome] = currentValue.id;
                }
                return previousValue;
            }, {} as any);
        }
        if (!keys[coluna]) {
            throw Error(`Coluna ${coluna} agrupamento não encontrada na fonte ${fonteDeTrabalho?.nome}`);
        }
        const params = {
            'agrupar': keys[coluna],
            filters: Object.keys(filtros).map(x => {
                return keys[x]
            }).filter(x => !!x),
        } as any;
        Object.keys(filtros).forEach(x => {
            params[keys[x]] = filtros[x]
        })
        return ApiService.get("/fonte-de-dados/valores", uuidFonte, params)
    }

    static async processarFuncao(fontes: IFonteDeDados[], metaDataFuncao: string, linha: any) {
        if (metaDataFuncao) {
            const agruparValorFonte = (idDaFonte: string, tipoResultado: string, colunaResultado: string, filtros: {
                [key: string]: string
            }) => {
                return CampoHelpers.agruparValoresPorFonte(fontes, idDaFonte, tipoResultado, colunaResultado, filtros)
            }
            const agruparColunaPorFonte = (idDaFonte: string, colunaResultado: string, filtros: {
                [key: string]: string
            }) => {
                return CampoHelpers.agruparColunaPorFonte(fontes, idDaFonte, colunaResultado, filtros).then(d => d.map((x: any) => x.id))
            }
            const obterDadosUsuarioLogado = () => {
                return ApiService.get("/usuario-logado");
            }
            const getCsrfToken = () => {
                return ApiService.getCookie('csrftoken');
            }

            try {
                // eslint-disable-next-line
                return await eval(`(async function metaDataFunction(linha, agruparValorFonte, agruparColunaPorFonte, obterDadosUsuarioLogado, moment, getCsrfToken) {
                    return Promise.resolve(${metaDataFuncao});
                })`)(linha, agruparValorFonte, agruparColunaPorFonte, obterDadosUsuarioLogado, moment, getCsrfToken);
            } catch (e) {
                console.error(e);
                return Promise.reject("**ERRO**");
            }
        }
        return Promise.resolve();
    }

    static obterMetaDataDeCamposPorFonte(campo: ICampoDaFonteDeDados): { [key: string]: MetaDataColuna } {
        const coluna = {} as any;
        if (campo.fonte?.campos.length) {
            campo.fonte.campos.forEach(x => {
                coluna[x.key] = {
                    nome: x.nome,
                    tipo: x.multivalor ? "LISTA" : x.tipo,
                    keyOrigem: x.keyOrigem,
                    id: campo.id?.toString() || "",
                };
            })
        } else {
            coluna[campo.id?.toString() || ""] = {
                nome: campo.nome,
                tipo: campo.tipo,
                keyOrigem: campo.id,
                multivalor: campo.multivalor
            }
        }
        return coluna;
    }

    static obterHeaderDeColunasPorCampo(campo: ICampoDaFonteDeDados): Array<{
        name: string,
        title: string;
        tipo: string
    }> {
        const metaDataFonte = {} as any;
        if (campo.fonte?.campos.length && !campo.multivalor) {
            campo.fonte.campos.forEach(x => {
                metaDataFonte[x.key] = {
                    nome: x.nome,
                    tipo: x.multivalor ? "LISTA" : x.tipo,
                    keyOrigem: x.keyOrigem,
                    id: campo.id?.toString() || "",
                };
            })
        } else {
            metaDataFonte[campo.id?.toString() || ""] = {
                nome: campo.nome,
                tipo: campo.tipo,
                keyOrigem: campo.id,
                multivalor: campo.multivalor
            }
        }
        return Object.keys(metaDataFonte).map(key => {
            return {
                title: metaDataFonte[key].nome,
                name: key,
                tipo: metaDataFonte[key].tipo,
            }
        })
    };


    static obterValoresFonteDinamicaParaCampo = (campo: ICampoDaFonteDeDados, filter: string | {
        [key: string]: any
    }, somenteColunaComKey?: string): Promise<IJsonFromFieldChoice[]> => {
        if (campo.fonte) {
            const metaDataCompos = CampoHelpers.obterMetaDataDeCamposPorFonte(campo);
            const params = {} as any;

            if (filter && typeof filter === 'string') {
                params['likeFilters'] = somenteColunaComKey ? somenteColunaComKey : Object.keys(metaDataCompos).map(key => metaDataCompos[key].keyOrigem);
                Object.keys(metaDataCompos).forEach(key => {
                    params[metaDataCompos[key].keyOrigem] = filter;
                });
            } else if (filter) {
                params['filters'] = Object.keys(filter).filter(x => x !== somenteColunaComKey);
                Object.keys(filter).forEach(key => {
                    params[key] = (filter as any)[key];
                });
                if (somenteColunaComKey && params[somenteColunaComKey])
                    params['likeFilters'] = somenteColunaComKey
            }
            if (somenteColunaComKey) {
                params['agrupar'] = somenteColunaComKey
            }
            return ApiService.get("/fonte-de-dados/valores", campo.fonte.id, params).then(valores => {
                if (somenteColunaComKey) {
                    return valores.map((reg: any) => {
                        return {
                            value: reg.id,
                            label: reg.id
                        }
                    })
                } else {
                    return valores.docs.map((reg: any) => {
                        return {
                            value: reg.id,
                            label: Object.keys(metaDataCompos).map(key => {
                                let nome = metaDataCompos[key].nome;
                                let valorOrigem;
                                if (metaDataCompos[key].tipo === "LISTA") {
                                    valorOrigem = reg[metaDataCompos[key].keyOrigem]
                                } else {
                                    valorOrigem = reg[metaDataCompos[key].keyOrigem]
                                }
                                let valor = String(valorOrigem) === "undefined" ? '-' : valorOrigem as any;
                                if (isObject(valor)) {
                                    // @ts-ignore
                                    valor = valor?.valor ? valor?.valor : `**Multi Valores**`
                                }
                                return `${nome}: ${valor ?? '-'}`
                            }).join(" | ")
                        }
                    })
                }
            });
        } else {
            return Promise.reject("Fonte campo não definida");
        }
    };

    static obterChoicesCampo = (campo: ICampoDaFonteDeDados, fontes: IFonteDeDados[]) => {
        const fonte = fontes.find((x: any) => x.id === campo.fonte?.id?.toString());
        if (fonte) {
            if (TipoDeFonteDeDados[String(fonte.tipo) as keyof typeof TipoDeFonteDeDados] === TipoDeFonteDeDados.ESTATICA) {
                return fonte.valoresEstaticos.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.valor
                    }
                });
            } else {
                return (filter: string) => {
                    return CampoHelpers.obterValoresFonteDinamicaParaCampo(campo, filter)
                };
            }
        }
        return undefined;
    }

    static obterChoicesCampoAgrupado = (campo: ICampoDaFonteDeDados, fontes: IFonteDeDados[], colunaAgrupadora: string) => {
        const fonte = fontes.find((x: any) => x.id === campo.fonte?.id?.toString());
        if (fonte) {
            if (TipoDeFonteDeDados[String(fonte.tipo) as keyof typeof TipoDeFonteDeDados] === TipoDeFonteDeDados.ESTATICA) {
                return fonte.valoresEstaticos.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.valor
                    }
                });
            } else {
                return (filter: string) => {
                    if (filter) {
                        const query = {} as any
                        query[colunaAgrupadora] = filter
                        return CampoHelpers.obterValoresFonteDinamicaParaCampo(campo, query, colunaAgrupadora);
                    } else {
                        return CampoHelpers.obterValoresFonteDinamicaParaCampo(campo, filter, colunaAgrupadora);
                    }
                };
            }
        }
        return undefined;
    }


    static obterTipoCampo(campo: ICampoDaFonteDeDados) {
        switch (TipoDeCampoFonteDeDados[String(campo.tipo) as keyof typeof TipoDeCampoFonteDeDados]) {
            case TipoDeCampoFonteDeDados.DATA_COM_HORARIO:
                return "datetime-local";
            case TipoDeCampoFonteDeDados.DATA:
                return "date";
            case TipoDeCampoFonteDeDados.LISTA:
                return campo?.multivalor ? "multi-select" : "select";
            case TipoDeCampoFonteDeDados.NUMERICO:
                return "number";
            default:
                return "text";
        }
    }

    static obterCampoParaFuncao(fontes: IFonteDeDados[], campo: ICampoDaFonteDeDados, formularioData: any, updatedData?: boolean): Promise<any> {
        const tipo = TipoDeCampoFonteDeDados[String(campo.tipo) as keyof typeof TipoDeCampoFonteDeDados];
        if (tipo === TipoDeCampoFonteDeDados.LISTA) {
            const fonte = fontes.find((x: any) => x.id === campo.fonte?.id);
            if (fonte) {
                if (TipoDeFonteDeDados[String(fonte.tipo) as keyof typeof TipoDeFonteDeDados] === TipoDeFonteDeDados.ESTATICA) {
                    const estatico = fonte.valoresEstaticos.find(x => campo.id && x.id === formularioData[campo.id]);
                    if (estatico) {
                        return Promise.resolve(estatico.valor);
                    } else {
                        return Promise.resolve(null);
                    }
                } else {
                    const tratarValorRelacionamento = (regValores: any) => {
                        const coluna = {} as any;
                        const fonte = fontes.find(x => x.id === campo.fonte?.id);
                        Object.keys(regValores).forEach(key => {
                            const campoFind = fonte?.campos.find(x => x.id?.toString() === key || (x.fonte && x.fonte.campos && x.fonte.campos.find(y => y.key === key)));
                            if (campoFind && campoFind.id?.toString() === key) {
                                coluna[campoFind.nome] = regValores[key]
                            } else if (campoFind && campoFind.fonte && campoFind.fonte.campos) {
                                const c = campoFind.fonte.campos.find(x => x.key === key)
                                if (c) {
                                    coluna[c.nome] = regValores[key]
                                }
                            }
                        });
                        return coluna;
                    }
                    if (formularioData[(campo as any).id]) {
                        if (updatedData) {
                            return formularioData[(campo as any).id] ? Promise.resolve(tratarValorRelacionamento(formularioData[(campo as any).id])) : Promise.resolve(null);
                        }
                        const campoValor = typeof formularioData[(campo as any).id] === "string" ? formularioData[(campo as any).id] : formularioData[(campo as any).id].value;
                        if (campoValor) {
                            return ApiService.get("/fonte-de-dados/valores/" + campo.fonte?.id, campoValor).then((valores) => tratarValorRelacionamento(valores));
                        }
                    }
                    return Promise.resolve(null);
                }
            }
        } else if (tipo === TipoDeCampoFonteDeDados.AGRUPAMENTO) {
            const campoFind = campo.fonte?.campos
            if (campoFind) {
                return Promise.resolve(campoFind.reduce((pre, pos) => {
                    pre[pos.nome] = formularioData[pos.key]
                    return pre
                }, {} as any))
            }
        }
        return Promise.resolve(formularioData[campo?.id?.toString() || ""])
    }
}
