import React, {useCallback, useRef, useState} from "react";
import {ErrorMessage, ValidationOptions} from "react-hook-form";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import pt from 'date-fns/locale/pt-BR';

registerLocale('pt', pt);

export interface IJsonFromFieldChoice {
    label: string;
    value: string;
    disabled?: boolean;
}


export interface IJsonFromField {
    name: string;
    label: string;
    type: "text" | "select" | "number" | "multi-select" | "tel" | "password" | "email" | "date" | "text-area" | "datetime-local" | "hidden";
    valueDefault?: any;
    placeholder?: string;
    helpText?: string;
    mask?: any;
    disabled?: boolean;
    readonly?: boolean;
    validators?: ValidationOptions;
    choices?: ((filter: string) => Promise<IJsonFromFieldChoice[]>) | IJsonFromFieldChoice[] | undefined,
    onChange?: (event: any) => void,
    filter?: (value: any) => any,
    preFilter?: (value: any) => any,
    visivel?: boolean,
    key?: any,
    extraFilter?: (onSelected: (selected: any) => void, onHide: () => void, valores: IJsonFromFieldChoice | IJsonFromFieldChoice[]) => React.ReactNode
}


export function FieldJsonForm(props: { field: IJsonFromField, errors: any, register: any, setValue: any; watch: any }) {
    const {field, errors, register, setValue} = props;
    const isInvalid = !!errors[field.name];
    const [datePicker, setDatePicker] = useState(props.field.valueDefault);
    const [showExtraFilter, setShowExtraFilter] = useState(false);
    const [selectValue, setSelectValue] = useState(props.field.valueDefault);
    const refSetTimeout = useRef<any>(null);

    const choices = useCallback((value) => {
        return new Promise<any>((resolve, reject) => {
            if (refSetTimeout.current) {
                clearTimeout(refSetTimeout.current as any);
            }
            refSetTimeout.current = setTimeout(async () => {
                try {
                    const result = await (field.choices as any)(value);
                    resolve(result);
                    clearTimeout(refSetTimeout.current as any);
                } catch {
                    reject();
                }
            }, 500);
        })
    }, [field.choices]);

    const onSelect = useCallback(selected => {
        if (selected) {
            if (field.type === "multi-select") {
                const select = selected.filter((x: any) => x.value).map((x: any) => {
                    return x.value
                });
                setValue(field.name, select);
            } else {
                setValue(field.name, selected.value);
            }
            setSelectValue(selected);
        } else {
            const select = field.type === "multi-select" ? [] : null;
            setValue(field.name, select);
            setSelectValue(select);
        }
    }, [field, setValue])

    const extraComponente = () => {
        const {field, errors} = props;
        return (<>
            {field.helpText ?
                <small className="form-text text-muted">{field.helpText}</small> : ''}
            <ErrorMessage errors={errors} name={field.name}>
                {
                    ({messages, message}) => {
                        if (messages) {
                            return messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <span key={type}
                                          className="form-text text-danger">{message}<br/></span>
                                ))
                        } else if (message) {
                            return (<span className="form-text text-danger">{message}<br/></span>)
                        }
                    }
                }
            </ErrorMessage>
        </>);
    };

    if (field.visivel === false) {
        return <></>;
    }
    switch (field.type) {
        case "hidden":
            return (<input type="hidden" ref={(ref) => register(ref, field.validators ? field.validators : {})}
                           name={field.name}/>);
        case "datetime-local":
        case "date":
            let selected = datePicker ? moment(datePicker).toDate() : datePicker;
            const dataW = props.watch(field.name, props.field.valueDefault);
            if (!selected || selected.toString() !== dataW?.toString()) {
                selected = dataW ? moment(dataW).toDate() : dataW;
            }
            return (
                <div className="form-group" key={field.name}>
                    <label>{field.label} {field.validators?.required && <i className="text-danger">*</i>}</label>
                    <DatePicker
                        ref={() => {
                            register({name: field.name}, field.validators ? field.validators : {})
                        }}
                        onChange={date => {
                            setDatePicker(date);
                            setValue(field.name, date)
                        }}
                        className={(isInvalid ? 'is-invalid ' : '') + 'form-control w-100'}
                        wrapperClassName={'w-100 bg-transparent'}
                        placeholderText={field.placeholder}
                        showTimeSelect={field.type === "datetime-local"}
                        selected={selected}
                        readOnly={!!field.readonly}
                        locale="pt"
                        clearButtonTitle={"Limpar valor"}
                        isClearable={!field.validators?.required && !field.disabled && !field.readonly}
                        dateFormat={field.type === "datetime-local" ? "dd/MM/yyyy HH:mm:ss" : "dd/MM/yyyy"}
                        disabled={!!field.disabled}/>
                    {extraComponente()}
                </div>
            );
        case "email":
        case "number":
            return (
                <div className="form-group" key={field.name}>
                    <label>{field.label} {field.validators?.required && <i className="text-danger">*</i>}</label>
                    <input
                        readOnly={!!field.readonly}
                        step="any"
                        formNoValidate={true}
                        name={field.name}
                        type={field.type}
                        disabled={!!field.disabled}
                        className={(isInvalid ? 'is-invalid ' : '') + 'form-control'}
                        placeholder={field.placeholder}
                        // defaultValue={field.valueDefault}
                        ref={(ref) => register(ref, field.validators ? field.validators : {})}
                    />
                    {extraComponente()}
                </div>
            );
        case "tel":
        case "text":
        case "password":
            return (
                <div className="form-group" key={field.name}>
                    <label>{field.label} {field.validators?.required && <i className="text-danger">*</i>}</label>
                    <input
                        readOnly={!!field.readonly}
                        name={field.name}
                        type={field.type}
                        disabled={!!field.disabled}
                        className={(isInvalid ? 'is-invalid ' : '') + 'form-control'}
                        placeholder={field.placeholder}
                        // defaultValue={field.valueDefault}
                        ref={(ref) => register(ref, field.validators ? field.validators : {})}
                    />
                    {extraComponente()}
                </div>
            );
        case "text-area":
            return (
                <div className="form-group" key={field.name}>
                    <label>{field.label} {field.validators?.required && <i className="text-danger">*</i>}</label>
                    <textarea
                        readOnly={!!field.readonly}
                        disabled={!!field.disabled}
                        name={field.name}
                        className={(isInvalid ? 'is-invalid ' : '') + 'form-control'}
                        placeholder={field.placeholder}
                        // defaultValue={field.valueDefault}
                        ref={(ref) => register(ref, field.validators ? field.validators : {})}
                    />
                    {extraComponente()}
                </div>
            );
        case "select":
        case "multi-select":
            return (
                <div className="form-group" key={field.name}>
                    <label>
                        {field.label} {field.validators?.required && <i className="text-danger">*</i>}
                        {field.extraFilter && !field.disabled &&
                            <button type="button" className="btn btn-link text-info btn-sm" onClick={() => {
                                setShowExtraFilter((s) => !s);
                            }}>
                                <i className="fa fa-filter"/>
                            </button>}
                    </label>
                    {field.extraFilter && showExtraFilter && (field.extraFilter(onSelect, () => setShowExtraFilter(false), selectValue))}
                    {(!field.extraFilter || !showExtraFilter) && (Array.isArray(field.choices) ?
                        <Select
                            isMulti={field.type === "multi-select"}
                            isDisabled={!!field.disabled || !!field.readonly}
                            isClearable={true}
                            name={field.name}
                            placeholder={field.placeholder}
                            className={(isInvalid ? 'is-invalid ' : '')}
                            defaultValue={selectValue}

                            options={field.choices.filter(x => !x.disabled).map((data) => {
                                return {
                                    value: data.value,
                                    label: data.label
                                }
                            })}
                            isOptionDisabled={(option) => {
                                if (Array.isArray(field.choices)) {
                                    const choice = field.choices.find((x: any) => x.value === option.value);
                                    return !!choice?.disabled
                                }
                                return false;
                            }}
                            onChange={onSelect}
                            ref={() => register({name: field.name}, field.validators ? field.validators : {})}
                            // ref={register({name: field.name}, field.validators ? field.validators : {})}
                        />
                        : <AsyncSelect
                            isMulti={field.type === "multi-select"}
                            defaultOptions={true}
                            isClearable={true}
                            isDisabled={!!field.disabled}
                            name={field.name}
                            placeholder={field.placeholder}
                            className={(isInvalid ? 'is-invalid ' : '')}
                            defaultValue={selectValue}
                            isOptionDisabled={(option) => {
                                if (Array.isArray(field.choices)) {
                                    const choice = field.choices.find((x: any) => x.value === option.value);
                                    return !!choice?.disabled
                                }
                                return false;
                            }}
                            onChange={onSelect}
                            ref={() => register({name: field.name}, field.validators ? field.validators : {})}
                            loadOptions={choices}/>)}
                    {extraComponente()}
                </div>
            );
        default:
            return <div/>;
    }
}
