import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {IDashboard} from "../models/Dashboard";
import {FormularioDashboard} from "./FormularioDashboard";
import {useSelector} from "react-redux";
import {AppRootState} from "../reducers";
import {IUsuario} from "../models/Usuario";


export function ButtonAddDashboard(props: { onAdd: (dashboard: IDashboard) => void, projeto: IProjeto, fontes: IFonteDeDados[] }) {

    const [show, setShow] = useState(false);
    const usuario = useSelector<AppRootState, IUsuario | undefined>(({sessao}) => sessao.usuario);


    const handlerSubmit = (data: any, callbackError: (error: any) => void) => {
        ApiService.post("/dashboards/" + props.projeto.id, {...data, proprietario: usuario?.id, usuarios: []}).then(dash => {
            props.onAdd(dash);
            setShow(false);
        }, (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
                callbackError(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: "ValidationError" + key,
                        message: String(errors[key])
                    }
                }));
            }
        })
    }

    return (<div className="d-inline-block">
        <button className="btn btn-primary" onClick={() => setShow(true)}><i
            className={"fa fa-plus"}/> Nova Dashboard
        </button>
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && <FormularioDashboard fontes={props.fontes} onCancel={() => {
                    setShow(false);
                }} onSubmit={(data, onErr) => {
                    handlerSubmit(data, onErr);
                }}/>}
            </Modal.Body>
        </Modal>
    </div>);
}
