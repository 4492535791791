import React, {ChangeEvent, useEffect, useState} from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {useDebouncedState} from "../hooks/useDebouncedState";


const IC_IGUAL = <i className="fas fa-equals"/>;
const IC_MAIOR = <i className="fas fa-greater-than"/>;
const IC_MAIOR_IGUAL = <i className="fas fa-greater-than-equal"/>;
const IC_MENOR = <i className="fas fa-less-than"/>;
const IC_MENOR_IGUAL = <i className="fas fa-less-than-equal"/>;
const IC_DIFERENTE = <i className="fas fa-not-equal"/>;
const IC_ENTRE = <i className="fas fa-arrows-alt-h"/>;
const IC_VAZIO = <i className="fas fa-minus"/>;

export const TiposDeFiltroNumero = {
    IGUAL: "=",
    MAIOR: ">",
    MAIOR_IGUAL: ">=",
    MENOR: "<",
    MENOR_IGUAL: "<=",
    DIFERENTE: "<>",
    ENTRE: "<!",
    VAZIO: "vaz",
}

const getIconePorTipo = (tipo: string) => {
    switch (tipo) {
        case TiposDeFiltroNumero.MAIOR:
            return IC_MAIOR;
        case TiposDeFiltroNumero.MAIOR_IGUAL:
            return IC_MAIOR_IGUAL;
        case TiposDeFiltroNumero.MENOR:
            return IC_MENOR;
        case TiposDeFiltroNumero.MENOR_IGUAL:
            return IC_MENOR_IGUAL;
        case TiposDeFiltroNumero.DIFERENTE:
            return IC_DIFERENTE;
        case TiposDeFiltroNumero.ENTRE:
            return IC_ENTRE;
        case TiposDeFiltroNumero.VAZIO:
            return IC_VAZIO;
        default:
            return IC_IGUAL;
    }
}

export function NumberFilter(props: { defaultValue?: { query: string, tipo: string }, onChange: (filtro?: { query: string, tipo: string }) => void }) {
    const [value, setValue, nextState] = useDebouncedState(props.defaultValue?.query ?? null, 500);
    const [icone, setIcone] = useState(props.defaultValue?.tipo ? getIconePorTipo(props.defaultValue.tipo) : IC_IGUAL);
    const [tipoFiltro, setTipoFiltroState] = useState(props.defaultValue?.tipo || TiposDeFiltroNumero.IGUAL);

    useEffect(() => {
        if (value != null || tipoFiltro === TiposDeFiltroNumero.VAZIO) {
            props.onChange({
                query: value,
                tipo: tipoFiltro
            });
        }
        // eslint-disable-next-line
    }, [value, tipoFiltro])

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const setTipoFiltro = (tipo: string) => {
        setTipoFiltroState(tipo);
        setIcone(getIconePorTipo(tipo));
    }

    return <>
        <div className="input-group">
            <div className="input-group-prepend">
                <DropdownButton variant="secondary"
                                title={icone}
                                id="btn-conf-projeto">
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.IGUAL}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.IGUAL)
                                   }}>
                        {IC_IGUAL}
                        <span className="ml-2">Igual</span>
                    </Dropdown.Item>
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.MAIOR}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.MAIOR)
                                   }}>
                        {IC_MAIOR}
                        <span className="ml-2">Maior</span>
                    </Dropdown.Item>
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.MAIOR_IGUAL}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.MAIOR_IGUAL)
                                   }}>
                        {IC_MAIOR_IGUAL}
                        <span className="ml-2">Maior igual</span>
                    </Dropdown.Item>
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.MENOR}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.MENOR)
                                   }}>
                        {IC_MENOR}
                        <span className="ml-2">Menor</span>
                    </Dropdown.Item>
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.MENOR_IGUAL}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.MENOR_IGUAL)
                                   }}>
                        {IC_MENOR_IGUAL}
                        <span className="ml-2">Menor igual</span>
                    </Dropdown.Item>
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.DIFERENTE}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.DIFERENTE)
                                   }}>
                        {IC_DIFERENTE}
                        <span className="ml-2">Diferente</span>
                    </Dropdown.Item>
                    {/*<Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.ENTRE}*/}
                    {/*               onClick={() => {*/}
                    {/*                   setTipoFiltro(TiposDeFiltroNumero.ENTRE)*/}
                    {/*               }}>*/}
                    {/*    {IC_ENTRE}*/}
                    {/*    <span className="ml-2">Entre</span>*/}
                    {/*</Dropdown.Item>*/}
                    <Dropdown.Item active={tipoFiltro === TiposDeFiltroNumero.VAZIO}
                                   onClick={() => {
                                       setTipoFiltro(TiposDeFiltroNumero.VAZIO)
                                   }}>
                        {IC_VAZIO}
                        <span className="ml-2">Somente Vazios</span>
                    </Dropdown.Item>
                </DropdownButton>
            </div>
            <input type="number" placeholder="Filtros" className="form-control" value={nextState ?? ''}
                   onChange={onChange} disabled={tipoFiltro === TiposDeFiltroNumero.VAZIO}/>
            <div className="input-group-append">
                <button className="input-group-text" onClick={() => {
                    setValue("")
                    setTipoFiltro(TiposDeFiltroNumero.IGUAL)
                    props.onChange()
                }}>
                    <i className="oi oi-circle-x"/>
                </button>
            </div>
        </div>
    </>
}
