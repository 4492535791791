import {FormularioPageHelpers} from "../helpers/FormularioPageHelpers";
import {
    DragDropProvider,
    Grid,
    PagingPanel,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableFixedColumns,
    TableHeaderRow,
    TableSelection,
    Toolbar,
    VirtualTable,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
    Column,
    CustomPaging,
    DataTypeProvider,
    Filter,
    FilteringState,
    IntegratedSelection,
    PagingState,
    SelectionState,
    Sorting,
    SortingState,
    TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import React, {ReactText} from "react";
import DataFilter from "./DataFilter";
import TextFilter from "./TextFilter";
import NumberRFilter from "./NumberRFilter";
import MultivalorFilter from "./MultivalorFilter";

type Props = {
    rows: ReadonlyArray<any>
    columns: ReadonlyArray<Column>
    dateColumns: string[]
    textColumns: string[]
    multivalorColumns: string[]
    numberColumns: string[]
    columnOrder?: string[]
    selection?: string[]
    columnsFix?: string[]
    hiddenColumnNames?: string[]
    currentPage?: number
    pageSize?: number
    pageSizes?: number[]
    totalCount?: number
    sorting?: Sorting[]
    columnWidths?: Array<TableColumnWidthInfo>
    cell?: any,
    headerCell?: any,
    toolbarHeader?: any,
    columnExtensions?: any[],

    onCurrentPageChange?: (currentPage: number) => void
    onPageSizeChange?: (page: number) => void
    onFiltersChange?: (filters: Filter[]) => void
    onSortingChange?: (sorting: Sorting[]) => void
    onSelectionChange?: (sorting: ReactText[]) => void
    onColumnWidthsChange?: (columnWidthInfos: Array<TableColumnWidthInfo>) => void
    onOrderChange?: (order: string[]) => void
    onHiddenColumnNamesChange?: (columns: string[]) => void
    tableFilterRow?: any
}

const DataGrid = (props: Props) => {
    const {
        rows,
        columns,
        dateColumns,
        textColumns,
        numberColumns,
        currentPage,
        pageSize,
        pageSizes,
        columnsFix,
        multivalorColumns,
        tableFilterRow = FormularioPageHelpers.CellFilterRow
    } = props;
    const {totalCount, sorting, selection, columnWidths, columnOrder, hiddenColumnNames, columnExtensions} = props;
    return <Grid
        rows={rows}
        columns={columns}
        getRowId={FormularioPageHelpers.getRowId}
    >
        <DragDropProvider/>
        <DataTypeProvider for={dateColumns} editorComponent={DataFilter}/>
        <DataTypeProvider for={textColumns} editorComponent={TextFilter}/>
        <DataTypeProvider
            for={numberColumns}
            editorComponent={NumberRFilter}
        />
        <DataTypeProvider
            for={multivalorColumns}
            editorComponent={MultivalorFilter}
        />
        <PagingState
            currentPage={currentPage}
            pageSize={pageSize}
            onCurrentPageChange={props.onCurrentPageChange}
            onPageSizeChange={props.onPageSizeChange}
        />
        <CustomPaging totalCount={totalCount}/>
        <FilteringState onFiltersChange={props.onFiltersChange}/>
        <SortingState
            sorting={sorting}
            onSortingChange={props.onSortingChange}
        />
        {selection && <SelectionState
            selection={selection}
            onSelectionChange={props.onSelectionChange}
        />}
        <VirtualTable cellComponent={props.cell} height={"100vh"}/>
        {selection && <IntegratedSelection/>}
        <TableColumnResizing
            columnWidths={columnWidths}
            minColumnWidth={100}
            onColumnWidthsChange={props.onColumnWidthsChange}
        />
        <TableColumnReordering
            order={columnOrder}
            onOrderChange={props.onOrderChange}
        />
        <TableHeaderRow
            showSortingControls
            cellComponent={props.headerCell}
        />
        <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={props.onHiddenColumnNamesChange}
            columnExtensions={columnExtensions}
        />
        {selection && <TableSelection showSelectionColumn highlightRow showSelectAll/>}
        <TableFilterRow
            cellComponent={tableFilterRow}
        />
        <TableFixedColumns leftColumns={columnsFix}/>
        <PagingPanel pageSizes={pageSizes}/>
        {props.toolbarHeader && <Toolbar rootComponent={props.toolbarHeader}/>}
    </Grid>
};

export default DataGrid;