import {Action, Reducer} from 'redux'
import {IProjeto} from "../models/Projeto";
import {IFonteDeDados} from "../models/FonteDeDados";
import {IDashboard} from "../models/Dashboard";
import {ACTION_TYPES_PROJETO} from "../actions/ActionTypes";

export interface ActionProjeto extends Action<ACTION_TYPES_PROJETO> {
    projeto?: IProjeto,
    projetos?: IProjeto[],
    fontes?: { lista: IFonteDeDados[], projetoId: string }
    fonte?: { fonte: IFonteDeDados, projetoId: string }
    dashboards?: { lista: IDashboard[], projetoId: string }
}

export interface ProjetoState {
    projetos: IProjeto[],
    fontes: { [projetoId: string]: IFonteDeDados[] }
    dashboards: { [projetoId: string]: IDashboard[] }
}

export const projetoReducer: Reducer<ProjetoState, ActionProjeto> = function (state = {
    projetos: [],
    fontes: {},
    dashboards: {}
}, action): ProjetoState {
    switch (action.type) {
        case "SET_PROJETOS":
            return {
                projetos: [...(action.projetos || [])],
                fontes: { ...state.fontes },
                dashboards: { ...state.dashboards },
            }
        case "ADD_PROJETO":
            return {
                projetos: action.projeto ? [...state.projetos.filter(x => x.id !== action.projeto?.id), action.projeto] : state.projetos,
                fontes: { ...state.fontes },
                dashboards: { ...state.dashboards },
            }
        case "ADD_FONTES":
            const f = {} as { [projetoId: string]: IFonteDeDados[] };
            if (action.fontes)
                f[action.fontes?.projetoId || ""] = action.fontes?.lista
            return {
                projetos: [...state.projetos],
                fontes: { ...state.fontes, ...f },
                dashboards: { ...state.dashboards },
            }
        case "ADD_DASHBOARDS":
            const d = {} as { [projetoId: string]: IDashboard[] };
            if (action.dashboards)
                d[action.dashboards?.projetoId || ""] = action.dashboards?.lista
            return {
                projetos: [...state?.projetos],
                fontes: { ...state?.fontes },
                dashboards: state?.dashboards ? { ...state.dashboards, ...d } : d
            }
        case "EDITAR_FONTE": {
            const { projetoId, fonte } = action.fonte!;
            const fontes = [...(state.fontes[projetoId] || [])];
            const idx = fontes.findIndex(x => x.id === fonte.id);
            if (idx > -1) {
                fontes[idx] = fonte;
            }
            return {
                projetos: [...state.projetos],
                fontes: { ...state.fontes, [projetoId]: fontes },
                dashboards: { ...state.dashboards },
            }
        }
        default:
            return state as any;
    }
};
