import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {FormInputFonteDedados} from "./FormInputFonteDedados";
import {IJsonFromField} from "./FieldJsonForm";
import {JsonForm} from "./JsonForm";

export function ButtonEdicaoEmMassaFormulario(props: { fonteDeTrabalho: IFonteDeDados, fontes: IFonteDeDados[], onCancel: () => void, onSave: (data: any) => void, disabled?: boolean, idsEdicaoMassa: string[] }) {

    const [show, setShow] = useState(false);
    const [campos, setCampos] = useState([] as string[]);

    const jsonField: IJsonFromField = {
        name: 'campos',
        label: "Selecione quais campos deseja editar em massa",
        placeholder: "Quais campos deseja editar em massa?",
        type: "multi-select",
        choices: props.fonteDeTrabalho.campos.filter(x => String(x.tipo) !== "AGRUPAMENTO" && (!x.validacoes || !x.validacoes.unique) && !x.espelho && !x.metaDataFuncao).map(x => {
            return {
                label: x.nome,
                value: x.id
            }
        }),
        validators: {
            required: true
        }
    };

    return (<>
        <div className="d-inline-block">
            <button className="btn btn-info" disabled={props.disabled} onClick={() => setShow(true)}><i
                className={"fa fa-pencil"}/> Edição em
                Massa
            </button>
            <Modal show={show} size={"xl"} onHide={() => {
                props.onCancel();
                setCampos([]);
                setShow(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edição em Massa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(!campos || campos.length === 0) &&
                    <JsonForm name={"edicaoEmMassa"} fields={[jsonField]} onSubmit={(data) => {
                        setCampos(data['campos']);
                    }} textSubmitButton="Continuar"/>}
                    {campos && campos.length > 0 && <FormInputFonteDedados onSubmit={(data) => {
                        props.onSave(data);
                        setShow(false);
                        setCampos([]);
                    }} fonteDeTrabalho={props.fonteDeTrabalho} fontes={props.fontes} parcial={campos}
                                                                           idsEdicaoMassa={props.idsEdicaoMassa}>
                        <button type="button" className="btn btn-warning" onClick={() => {
                            props.onCancel();
                            setShow(false);
                            setCampos([]);
                        }}>Cancelar
                        </button>
                    </FormInputFonteDedados>}
                </Modal.Body>
            </Modal>
        </div>
    </>);
}
