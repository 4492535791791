import React from "react";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {useDispatch} from "react-redux";
import {setDashboards} from "../../actions/ProjetoActions";
import {CampoHelpers, MetaDataColuna, TIPOS_AGRUPAMENTOS} from "../../helpers/CampoHelpers";
import {IDashboard} from "../../models/Dashboard";
import {ButtonAddEditFiltroDashboard} from "../../componets/ButtonAddFiltroDashboard";
import {ApiService} from "../../services/ApiService";
import {DropdownButton} from "react-bootstrap";
import {ButtonCartaoDashboard} from "../../componets/ButtonCartaoDashboard";
import {ButtonGraficoPizzaDashboard} from "../../componets/ButtonGraficoPizzaDashboard";
import {ButtonFiltroComponente} from "../../componets/ButtonFiltroComponente";
import {ButtonGraficoBarraDashboard} from "../../componets/ButtonGraficoBarraDashboard";


export function ProjetoConfigComponentsDashboardPage(props: PropsProjetoPages & { dashboardId: string }) {
    const dispatch = useDispatch();
    const dashboard = props.dashboards.find(x => x.id === props.dashboardId);

    const dispatchDashboard = (dash: IDashboard) => {
        const dashs = [...props.dashboards];
        const indx = dashs.findIndex(x => x.id === dash.id);
        if (indx > -1) {
            dashs[indx] = dash
            dispatch(setDashboards(dashs, props.projeto.id));
        }
    }

    const atualizarDashboard = (dash: IDashboard) => {
        ApiService.put("/dashboards/" + props.projeto.id, dash.id, dash).then(d => {
            dispatchDashboard(d);
        })
    }

    return (
        <LayoutProjeto projeto={props.projeto}
                       toggleMenu={props.toggleMenu}
                       headlerToggleMenu={props.headlerToggleMenu}
                       titulo={`Componentes | ${dashboard?.nome}`} administrador={props.administrador}>
            {dashboard && <div className="ProjetoConfigDashboardPages">
                <h4 className='d-flex'>
                    Filtros
                    <p className="ml-auto">
                        <ButtonAddEditFiltroDashboard onAdd={(dash) => {
                            dispatchDashboard(dash);
                        }} projeto={props.projeto} fontes={props.fontes} dashboard={dashboard}/>
                    </p>
                </h4>
                <div className="table-responsive">
                    <table className='table'>
                        <thead>
                        <tr>
                            <th>
                                Fonte
                            </th>
                            <th>
                                Título
                            </th>
                            <th>
                                Coluna
                            </th>
                            <th>
                                Tipo
                            </th>
                            <th>
                                Ação
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {dashboard.filtros?.map(x => {
                            const metaDatas = props.fontes.find(f => f.id === x.fonteId)?.campos.reduce((previousValue, currentValue) => {
                                return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
                            }, {} as { [key: string]: MetaDataColuna }) || {};
                            return <tr key={x.campo}>
                                <td>{props.fontes.find(f => f.id === x.fonteId)?.nome}</td>
                                <td>{x.titulo}</td>
                                <td>{metaDatas[x.campo]?.nome}</td>
                                <td>{metaDatas[x.campo]?.tipo}</td>
                                <td>
                                    <button className='btn btn-danger' onClick={() => {
                                        if (window.confirm("Deseja realmente remover esse filtro?")) {
                                            const d = {...dashboard} as IDashboard;
                                            d.filtros = d.filtros?.filter(y => y.campo !== x.campo);
                                            atualizarDashboard(d);
                                        }
                                    }}>
                                        <i className='fa fa-trash'/>
                                    </button>
                                    <ButtonAddEditFiltroDashboard filtroEditar={x} onAdd={(dash) => {
                                        dispatchDashboard(dash);
                                    }} projeto={props.projeto} fontes={props.fontes} dashboard={dashboard}/>
                                </td>
                            </tr>
                        })}
                        {dashboard.filtros?.length === 0 && <tr>
                            <td colSpan={5}>
                                Nenhum filtro cadastrado para essa dashboard
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <hr/>
                <h4 className='d-flex'>
                    Componentes
                    <p className="ml-auto">
                        <DropdownButton variant="primary"
                                        title={<span><i className={'fa fa-plus'}/> Novo Componente</span>}
                                        id="btn-conf-projeto">
                            <ButtonCartaoDashboard dashboard={dashboard}
                                                   fontes={props.fontes} projeto={props.projeto}
                                                   onAdd={(dashboard1 => {
                                                       dispatchDashboard(dashboard1);
                                                   })}/>
                            <ButtonGraficoPizzaDashboard dashboard={dashboard}
                                                         fontes={props.fontes} projeto={props.projeto}
                                                         onAdd={(dashboard1 => {
                                                             dispatchDashboard(dashboard1);
                                                         })}/>
                            <ButtonGraficoBarraDashboard dashboard={dashboard}
                                                         fontes={props.fontes} projeto={props.projeto}
                                                         onAdd={(dashboard1 => {
                                                             dispatchDashboard(dashboard1);
                                                         })}/>

                        </DropdownButton>
                    </p>
                </h4>
                <div className="table-responsive">
                    <table className='table'>
                        <thead>
                        <tr>
                            <th>
                                Fonte
                            </th>
                            <th>
                                Tipo
                            </th>
                            <th>
                                Titulo
                            </th>
                            <th>
                                Métrica
                            </th>
                            <th>
                                Coluna
                            </th>
                            <th>
                                Ação
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {dashboard.componentes?.map(x => {
                            const metaDatas = props.fontes.find(f => f.id === x.fonteId)?.campos.reduce((previousValue, currentValue) => {
                                return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
                            }, {} as { [key: string]: MetaDataColuna }) || {};
                            return <tr key={x.id}>
                                <td>{props.fontes.find(f => f.id === x.fonteId)?.nome}</td>
                                <td>{x.tipo}</td>
                                <td>{x.titulo || '-'}</td>
                                <td>{TIPOS_AGRUPAMENTOS[x.valor.agrupameto] || '-'}</td>
                                <td>{metaDatas[x.valor.campo]?.nome}</td>
                                <td>
                                    <button className='btn btn-danger' onClick={() => {
                                        if (window.confirm("Deseja realmente remover esse componente?")) {
                                            const d = {...dashboard} as IDashboard;
                                            d.componentes = d.componentes?.filter(y => y.id !== x.id);
                                            atualizarDashboard(d);
                                        }
                                    }}>
                                        <i className='fa fa-trash'/>
                                    </button>
                                    {x.tipo === "CARD" && <ButtonCartaoDashboard dashboard={dashboard}
                                                                                 componenteEdicao={x}
                                                                                 fontes={props.fontes}
                                                                                 projeto={props.projeto}
                                                                                 onAdd={(dashboard1 => {
                                                                                     dispatchDashboard(dashboard1);
                                                                                 })}/>}
                                    {x.tipo === "PIZZA" && <ButtonGraficoPizzaDashboard dashboard={dashboard}
                                                                                        componenteEdicao={x}
                                                                                        fontes={props.fontes}
                                                                                        projeto={props.projeto}
                                                                                        onAdd={(dashboard1 => {
                                                                                            dispatchDashboard(dashboard1);
                                                                                        })}/>}
                                    {x.tipo === "BARRA" && <ButtonGraficoBarraDashboard dashboard={dashboard}
                                                                                        componenteEdicao={x}
                                                                                        fontes={props.fontes}
                                                                                        projeto={props.projeto}
                                                                                        onAdd={(dashboard1 => {
                                                                                            dispatchDashboard(dashboard1);
                                                                                        })}/>}
                                    <ButtonFiltroComponente componente={x} fontes={props.fontes} dashboard={dashboard}
                                                            projeto={props.projeto} onEdit={d => {
                                        atualizarDashboard(d);
                                    }}/>
                                </td>
                            </tr>
                        })}
                        {dashboard.componentes?.length === 0 && <tr>
                            <td colSpan={6}>
                                Nenhum componente cadastrado para essa dashboard
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <hr/>
            </div>}
        </LayoutProjeto>
    );
}
