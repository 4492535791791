import {ApiService} from "../services/ApiService";
import {ICampoDaFonteDeDados, IFonteDeDados} from "../models/FonteDeDados";
import {CampoHelpers} from "./CampoHelpers";
import {TableFilterRow} from "@devexpress/dx-react-grid-bootstrap4";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import * as queryString from "query-string";
import {DataTableEvent} from "../events/AppEvent";
import {PopupTexto} from "../componets/PopupTexto";
import {isObject} from "lodash";


export class FormularioPageHelpers {

    static getRowId = (row: any) => row.id;

    static obterRegistros = (opcoes: {
        fonteId: string,
        pagina?: number,
        tamanhoPagina?: number,
        ordenar?: string,
        likeFilters?: { key: string, value: any }[]
        filters?: { key: string, value: any }[],
    }) => {
        opcoes.pagina = opcoes.pagina || 1;
        opcoes.tamanhoPagina = opcoes.tamanhoPagina || 15;
        const parms = {
            page: String(opcoes.pagina),
            tamanhoPagina: opcoes.tamanhoPagina,
            tipoCondicao: "and"
        } as any;
        if (opcoes.ordenar) {
            parms['ordenar'] = opcoes.ordenar
        }
        if (opcoes.likeFilters && opcoes.likeFilters.length > 0) {
            parms['likeFilters'] = [];
            opcoes.likeFilters.forEach(x => {
                parms['likeFilters'].push(x.key);
                parms[x.key] = x.value
            });
        }
        if (opcoes.filters && opcoes.filters.length > 0) {
            parms['filters'] = [];
            opcoes.filters.forEach(x => {
                parms['filters'].push(x.key);
                parms[x.key] = x.value
            });
        }
        return ApiService.get("/fonte-de-dados/valores", opcoes.fonteId, parms).then((valores: any) => {
            return {
                registros: valores.docs,
                paginacao: {
                    totalRegistros: valores.totalDocs,
                    paginaAtual: valores.page
                }
            };
        });
    };

    static obterLinkParaExportacaoCsv(opcoes: {
        fonteId: string,
        pagina?: number,
        tamanhoPagina?: number,
        ordenar?: string,
        likeFilters?: { key: string, value: any }[]
    }) {
        const parms = {
            page: String(opcoes.pagina),
            tamanhoPagina: opcoes.tamanhoPagina,
            tipoCondicao: "and",
            exportacao: "csv",
            paginacao: "false",
        } as any;
        if (opcoes.ordenar) {
            parms['ordenar'] = opcoes.ordenar
        }
        if (opcoes.likeFilters && opcoes.likeFilters.length > 0) {
            parms['likeFilters'] = [];
            opcoes.likeFilters.forEach(x => {
                parms['likeFilters'].push(x.key);
                parms[x.key] = x.value
            });
        }
        let url = "/fonte-de-dados/valores/";
        if (opcoes.fonteId)
            url += opcoes.fonteId + "/";
        if (parms) {
            url += "?" + queryString.stringify(parms);
        }
        return url.toString()
    }

    static obterLinkParaExportacaoXlsx(opcoes: {
        fonteId: string,
        pagina?: number,
        tamanhoPagina?: number,
        ordenar?: string,
        likeFilters?: { key: string, value: any }[]
    }) {
        const parms = {
            page: String(opcoes.pagina),
            tamanhoPagina: opcoes.tamanhoPagina,
            tipoCondicao: "and",
            exportacao: "xlsx",
            paginacao: "false",
        } as any;
        if (opcoes.ordenar) {
            parms['ordenar'] = opcoes.ordenar
        }
        if (opcoes.likeFilters && opcoes.likeFilters.length > 0) {
            parms['likeFilters'] = [];
            opcoes.likeFilters.forEach(x => {
                parms['likeFilters'].push(x.key);
                parms[x.key] = x.value
            });
        }
        let url = "/fonte-de-dados/valores/";
        if (opcoes.fonteId)
            url += opcoes.fonteId + "/";
        if (parms) {
            url += "?" + queryString.stringify(parms);
        }
        return url.toString()
    }

    static obterColunasParaGrid = (fontes: IFonteDeDados[], fonteDeTrabalho?: IFonteDeDados): {
        name: string,
        title: string,
        tipo: string
    }[] => {
        const colunas: any[] = [];
        fonteDeTrabalho?.campos.forEach(campo => {
            const c = CampoHelpers.obterHeaderDeColunasPorCampo(campo);
            colunas.push(...c)
        });
        return colunas;
    };

    static obterColunasData = (fontes: IFonteDeDados[], fonteDeTrabalho?: IFonteDeDados): string[] => {
        const colunas: any[] = [];
        fonteDeTrabalho?.campos.forEach(campo => {
            const metaDataFonte = CampoHelpers.obterMetaDataDeCamposPorFonte(campo);
            Object.keys(metaDataFonte).forEach(key => {
                if (metaDataFonte[key].tipo === "DATA" || metaDataFonte[key].tipo === "DATA_COM_HORARIO") {
                    colunas.push(key)
                }
            })
        });
        return colunas;
    };

    static obterColunasTexto = (fontes: IFonteDeDados[], fonteDeTrabalho?: IFonteDeDados): string[] => {
        const colunas: any[] = [];
        fonteDeTrabalho?.campos.forEach(campo => {
            const metaDataFonte = CampoHelpers.obterMetaDataDeCamposPorFonte(campo);
            Object.keys(metaDataFonte).forEach(key => {
                if (!metaDataFonte[key].multivalor && metaDataFonte[key].tipo !== "DATA" && metaDataFonte[key].tipo !== "DATA_COM_HORARIO" && metaDataFonte[key].tipo !== "NUMERICO") {
                    colunas.push(key)
                }
            })
        });
        return colunas;
    };

    static obterColunasMultivalor = (fontes: IFonteDeDados[], fonteDeTrabalho?: IFonteDeDados): string[] => {
        const colunas: any[] = [];
        fonteDeTrabalho?.campos.forEach(campo => {
            if (campo.multivalor) {
                colunas.push(campo.id);
            }
        });
        return colunas;
    };


    static obterColunasNumero = (fontes: IFonteDeDados[], fonteDeTrabalho?: IFonteDeDados): string[] => {
        const colunas: any[] = [];
        fonteDeTrabalho?.campos.forEach(campo => {
            const metaDataFonte = CampoHelpers.obterMetaDataDeCamposPorFonte(campo);
            Object.keys(metaDataFonte).forEach(key => {
                if (metaDataFonte[key].tipo === "NUMERICO" || metaDataFonte[key].tipo === "NSU") {
                    colunas.push(key)
                }
            })
        });
        return colunas;
    };

    static CellOptions: (props: TableFilterRow.CellProps) => JSX.Element = (props) => {
        const [selecionados, setSelecionados] = useState(0);
        const dataTableSelectHandler = (sels: number) => {
            setSelecionados(sels);
        }
        useEffect(() => {
            DataTableEvent.addListener("changeSelectItem", dataTableSelectHandler);
            return () => {
                DataTableEvent.removeListener("changeSelectItem", dataTableSelectHandler);
            }
        });
        return (<TableFilterRow.Cell {...props}>
            {selecionados > -1 &&
                <p className='text-center w-100 mb-0 overflow-hidden' style={{
                    textOverflow: "ellipsis",
                    maxHeight: "50px",
                }}>
                    {selecionados === 1 && `${selecionados} linha selecionada`}
                    {(selecionados > 1 || !selecionados) && `${selecionados} linhas selecionadas`}
                </p>}
        </TableFilterRow.Cell>)
    }
    static CellFilterRow: (props: TableFilterRow.CellProps) => JSX.Element = (props) => {

        if (props.column.name === "opcoes") {
            return (<FormularioPageHelpers.CellOptions {...props}/>)
        }
        return (<
            TableFilterRow.Cell
            {...
                props
            }
        />)
    };

    static CellOptionsSelect: (props: TableFilterRow.CellProps & {
        onSelectAll: () => void
    }) => JSX.Element = (props) => {
        return (<TableFilterRow.Cell {...props}>
            <button type="button" className="btn btn-link text-primary" onClick={props.onSelectAll}>
                Selecionar todos da página
            </button>
        </TableFilterRow.Cell>)
    }

    static CellFilterRowSelect: (props: {
        onSelectAll: () => void
    }) => (propsRow: TableFilterRow.CellProps) => JSX.Element = (props) => (propsRow) => {
        if (propsRow.column.name === "opcoes") {
            return (<FormularioPageHelpers.CellOptionsSelect {...propsRow} onSelectAll={props.onSelectAll}/>)
        }
        return (<
            TableFilterRow.Cell
            {...
                propsRow
            }
        />)
    };

    static overlayComponent = (oc: any) => {
        return <Modal show={oc.visible} size={"xl"} onHide={oc.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Configuração de Colunas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {oc.children}
            </Modal.Body>
        </Modal>
    };

    static getColumnOrders(defaultOrder: string[], profileOrder ?: string[]
    ) {
        if (!profileOrder || profileOrder.length === 0) {
            return ["opcoes", ...defaultOrder.filter(x => x !== "opcoes")]
        } else {
            const order = [...profileOrder]
            defaultOrder.forEach(x => {
                if (!order.some(y => y === x)) {
                    order.push(x);
                }
            })
            return ["opcoes", ...order.filter(x => x !== "opcoes")];
        }
    }

    static getColumnsFix(profileFix ?: string[]) {
        if (!profileFix || profileFix.length === 0) {
            return ['opcoes']
        } else {
            if (!profileFix.find(x => x === "opcoes")) {
                profileFix.push("opcoes")
            }
            return profileFix
        }
    }

    static tratarCampoRelacionamentoParaLinha(registro: any, idCampo?: string, fonteId?: string, fontes?: IFonteDeDados[]): any {
        const fonte = fontes?.find(x => fonteId === x.id);
        const campo = fonte?.campos.find(x => x.id === idCampo);
        if (registro[campo?.id!]) {
            return isObject(registro[campo?.id!]) ? registro[campo?.id!]?.valor : registro[campo?.id!];
        } else if (String(campo?.tipo) === "LISTA") {
            return campo?.fonte?.campos.map(x => {
                if (registro[x.id]) {
                    return isObject(registro[x.id]) ? registro[x.id]?.valor : registro[x.id];
                } else {
                    return FormularioPageHelpers.tratarCampoRelacionamentoParaLinha(registro, x?.keyOrigem, campo?.fonte?.id, fontes);
                }
            }).join(", ");
        } else {
            return "-";
        }
    }

    static tratarCampoArray(registro: any[], campo?: ICampoDaFonteDeDados, fontes?: IFonteDeDados[]) {
        if (!registro.length) {
            return null
        } else if (registro.length === 1 && !isObject(registro[0])) {
            return registro[0];
        } else {
            let dataList = <ul>{registro.map(
                (x: string, i: number) => (
                    <li key={i}>{x}</li>
                )
            )}</ul>;
            if (campo?.multivalor || String(campo?.tipo) === "LISTA") {
                if (campo?.fonte?.campos.length) {
                    dataList = <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                            <tr>
                                {campo.fonte?.campos.map(x => <th key={x.id}>{x.nome}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {registro.map(reg => <tr key={reg.id}>
                                {campo.fonte?.campos.map(x => {
                                    const val = reg[x.id] || FormularioPageHelpers.tratarCampoRelacionamentoParaLinha(reg, x.keyOrigem, campo?.fonte?.id, fontes);
                                    return val ? <td key={x.id}>{val}</td> : <td>-</td>;
                                })}
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                } else {
                    dataList = <ul>{registro.map(x => <li key={x.id}>{x.valor}</li>)}</ul>;
                }
            }
            return <PopupTexto
                textoBtn={`${registro.length} valores selecionados`}
            >
                {dataList}
            </PopupTexto>
        }
    }

    static tratarDadosRequisicao = (registros: [], fonte: IFonteDeDados, fontes?: IFonteDeDados[]) => {
        return registros.map((registro: any) => {
            return {
                id: registro.id,
                ...Object.keys(registro).reduce(
                    (previousValue, currentValue) => {
                        if (Array.isArray(registro[currentValue])) {
                            const campo = fonte.campos.find(x => x.id === currentValue || x.fonte?.campos.some(y => y.id === currentValue))
                            previousValue[currentValue] = FormularioPageHelpers.tratarCampoArray(registro[currentValue], campo, fontes)
                        } else {
                            previousValue[currentValue] = registro[currentValue]
                        }
                        return previousValue;
                    },
                    {} as any
                ),
            };
        })
    }
}
