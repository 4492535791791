import {IComponente, IDashboard} from "../models/Dashboard";
import React, {useEffect, useState} from "react";
import {ApiService} from "../services/ApiService";
import {MascarasHelpers} from "../helpers/MascarasHelpers";
import {TipoDeMascara} from "../@types/models";

export function CardDashboard(props: { dashboard: IDashboard, componente: IComponente, filtros?: { [campo: string]: any } }) {
    const [valor, setValor] = useState<number>();
    const [tentarNovamente, setTentarNovamente] = useState(false);
    useEffect(() => {
        if (props.componente.id && props.dashboard.id) {
            const likeFilters = Object.keys(props.filtros || {});
            const p = {
                likeFilters: likeFilters,
                ...Object.keys(props.filtros || {}).reduce((pre, pos) => {
                    pre[pos] = (props.filtros || {})[pos];
                    return pre;
                }, {} as any),
            };
            setValor(undefined);
            ApiService.get("/dashboards/" + props.dashboard.id, props.componente.id, p).then(valores => {
                setValor(valores);
                setTentarNovamente(false);
            }, () => {
                setTentarNovamente(true);
            });
        }
    }, [props.filtros, props.componente.id, props.dashboard.id])

    const obterValorComMascara = (valor: number) => {
        return props.componente.mascara ? MascarasHelpers.aplicarMascaraEmValor(TipoDeMascara[props.componente.mascara], valor) : valor.toLocaleString('pt-br', {minimumFractionDigits: 2});
    }

    return <div className='card'>
        <div className='card-body text-center bg-light'>
            {!tentarNovamente && <h1 className='mt-2'>
                {valor === undefined ? <span className="fas fa-spinner fa-pulse"/> : obterValorComMascara(valor)}
            </h1>}
            {tentarNovamente && <h1 className='mt-2'>
                <button className='btn btn-link text-black-50 btn-sm' onClick={() => {
                    setTentarNovamente(false);
                }}>
                    Tentar novamente
                </button>
            </h1>}
            {props.componente.titulo && <p className='text-center'>
                {props.componente.titulo}
            </p>}
        </div>
    </div>
}
