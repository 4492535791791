import React, {PropsWithChildren, useEffect, useState} from "react";
import {ApiService} from "../services/ApiService";
import history from "../services/RouteService";
import {Container, Dropdown, DropdownButton, Modal, Nav, Navbar} from "react-bootstrap";
import {IUsuario} from "../models/Usuario";
import {useDispatch, useSelector} from "react-redux";
import {AppRootState} from "../reducers";
import {login, logout} from "../actions/SessaoActions";
import {setProjetos} from "../actions/ProjetoActions";
import {JsonForm} from "./JsonForm";

export default function Layout(props: PropsWithChildren<{}>) {
    const dispatch = useDispatch();
    const usuario = useSelector<AppRootState, IUsuario | undefined>(({sessao}) => sessao.usuario)
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    useEffect(() => {
            if (usuario === undefined && dispatch) {
                ApiService.get("/usuario-logado").then(usuaroLogado => {
                    ApiService.getAll("/projeto").then(projetos => {
                        dispatch(login(usuaroLogado));
                        dispatch(setProjetos(projetos));
                    });
                }, (erro) => {
                    if (erro.status !== 403) {
                        history.push("/login");
                    }
                })
            }
        }
        , [usuario, dispatch]);
    const handlerSubmit = (data: any, callbackError?: (erros: {
        name: string,
        type: string,
        message: string
    }[]) => void, formEl?: HTMLFormElement | null) => {
        setLoading(true);
        setSucesso(false);
        ApiService.post("/usuario/senha-usuario/", data).then(() => {
            setLoading(false);
            setSucesso(true);
            formEl?.reset();
        }, (error) => {
            setLoading(false);
            const errors = error.data;
            if (error.status === 400 && errors && callbackError) {
                callbackError(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: "ValidationError" + key,
                        message: String(errors[key])
                    }
                }));
            }
        })
    }

    if (!usuario) {
        return <h1 className="text-center" style={{marginTop: "50vh"}}><i className="fa fa-spinner fa-pulse"/></h1>
    } else {
        return <div className="Layout">
            <header>
                <Navbar bg="primary" expand="lg">
                    <Container>
                        <Navbar.Brand href="/projetos">
                            <h3 className=" text-light">dyData</h3>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="/projetos" bsPrefix="text-light">Projetos</Nav.Link>
                            </Nav>
                            <div className="form-inline my-2 my-lg-0">
                                <div className="col-4 col-md-6 mx-auto text-center text-lg-right">
                                            <span
                                                className="text-white mr-3 text-capitalize">Olá {usuario.nome.split(" ")[0].toLowerCase()}</span>
                                </div>
                                <div className="col-4 col-md-3 mx-auto text-center text-lg-right">
                                    <DropdownButton variant="secondary"
                                                    title={<i className="fas fa-user-alt"/>}
                                                    id="btn-conf-projeto">
                                        <Dropdown.Item onClick={() => setShowModal(true)}>
                                            <i className="fas fa-key"/> Alterar Senha
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <div className="col-4 col-md-3 mx-auto text-center text-lg-right">
                                    <button className="btn btn-link text-white ml-2" type="button"
                                            onClick={() => {
                                                ApiService.delete("/login", '1').then(() => {
                                                    dispatch(logout());
                                                    history.push("/login");
                                                })
                                            }}>
                                        <i className="fas fa-sign-out-alt"/>
                                    </button>
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Modal show={showModal} size={"xl"} onHide={() => {
                    setShowModal(false);
                    setSucesso(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Alteração de senha</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {sucesso && <div className='alert alert-success'>
                            Senha alterada com sucesso!
                        </div>}
                        <JsonForm name={"edicaoEmMassa"} fields={[
                            {
                                name: "senhaAtual",
                                type: "password",
                                label: "Senha atual",
                                validators: {
                                    required: {
                                        value: true,
                                        message: "Senha atual é obrigatória"
                                    }
                                }
                            },
                            {
                                name: "novaSenha",
                                type: "password",
                                label: "Nova Senha",
                                validators: {
                                    required: {
                                        value: true,
                                        message: "Nova senha é obrigatória"
                                    }
                                }
                            },
                            {
                                name: "confirmacaoSenha",
                                type: "password",
                                label: "Confiramção da nova senha",
                                validators: {
                                    required: {
                                        value: true,
                                        message: "Confirmação é obrigatória"
                                    }
                                }
                            }
                        ]} loading={loading} onSubmit={handlerSubmit} textSubmitButton="Alterar Senha"/>
                    </Modal.Body>
                </Modal>
            </header>
            {props.children}
        </div>
    }
}
