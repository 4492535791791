import {ApiEvent} from "../events/AppEvent";
import * as queryString from "query-string";

export enum ApiEventsType {
    HTTP_ERROR = "httpError",
    FETCH_ERROR = "fetchError",
}

export class ApiService {

    public static BASE_URL: string = "/api";

    private static checkFetchResponse = (response: Promise<Response>) => {
        return new Promise<any>((resolve, reject) => {
            response.then((response) => {
                if (response.status >= 200 && response.status <= 399) {
                    if (response.status === 204) {
                        resolve({});
                    } else {
                        response.json().then(resolve, (e) => {
                            reject({
                                error: e,
                                status: 0
                            })
                        });
                    }
                } else {
                    ApiEvent.emit(ApiEventsType.HTTP_ERROR, {
                        status: response.status,
                        error: response.statusText
                    });
                    response.json().then((data) => {
                        reject({
                            status: response.status,
                            data: data,
                            error: response.statusText
                        });
                    }, (e) => {
                        reject({
                            status: 0,
                            error: e
                        })
                    })
                }
            }, (e) => {
                ApiEvent.emit(ApiEventsType.FETCH_ERROR, e);
                reject({
                    error: e,
                    status: 0
                })
            })
        })
    };


    public static get(partialUrl: string, id?: string, params?: { [name: string]: any } | queryString.ParsedQuery) {
        let url = ApiService.getUrlWithId(partialUrl, id || '');
        if (params) {
            url += "?" + queryString.stringify(params);
        }
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    public static getAll(partialUrl: string, params?: { [name: string]: any } | queryString.ParsedQuery) {
        let url = ApiService.BASE_URL + partialUrl;
        url = url.endsWith("/") ? url : url + "/";
        if (params) {
            url += "?" + queryString.stringify(params);
        }
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    public static post(partialUrl: string, data: any) {
        let url = ApiService.BASE_URL + partialUrl;
        url = url.endsWith("/") ? url : url + "/";
        let csrf = ApiService.getCookie('csrftoken');
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(csrf ? {'X-CSRFToken': csrf} : {})
            },
            body: JSON.stringify(data)
        }));
    }

    public static put(partialUrl: string, id: string, data: any) {
        const url = ApiService.getUrlWithId(partialUrl, id);
        let csrf = ApiService.getCookie('csrftoken');
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...(csrf ? {'X-CSRFToken': csrf} : {})
            },
            body: JSON.stringify(data)
        }));
    }

    public static delete(partialUrl: string, id: string, data?: any) {
        const url = ApiService.getUrlWithId(partialUrl, id);
        let csrf = ApiService.getCookie('csrftoken');
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...(csrf ? {'X-CSRFToken': csrf} : {})
            },
            body: data ? JSON.stringify(data) : undefined
        }));
    }

    static getCookie(name: string) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    static getUrlWithId(partialUrl:string, id: string) {
        let url = ApiService.BASE_URL + partialUrl;
        if (id) {
            if (url.endsWith("/")) {
                url += id;
            } else {
                url += "/" + id;
            }
        }
        return url.endsWith("/") ? url : url + "/";
    }
}
