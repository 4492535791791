import {IFonteDeDados} from "../models/FonteDeDados";
import {useApiGet} from "./useApi";
import {useEffect, useMemo} from "react";
import {FormularioPageHelpers} from "../helpers/FormularioPageHelpers";
import {AlertEvent, AlertEventType} from "../events/AppEvent";


const useFetchRegistros = (fonte: IFonteDeDados, fontes: IFonteDeDados[], filters: any) => {
    const {data, isLoading, isRefetching, error, refetch} = useApiGet("/fonte-de-dados/valores", {
        params: filters,
        id: fonte.id
    }) as any;

    const rows = useMemo(() => {
        if (data) {
            return FormularioPageHelpers.tratarDadosRequisicao(data.docs, fonte, fontes);
        }
        return [];
    }, [data, fonte, fontes]);

    useEffect(() => {
        if (error && (error as any).request?.status !== 500)
            AlertEvent.emit(
                AlertEventType.DANGER,
                "Falha ao obter dados, por favor tente novamente!"
            );
    }, [error]);

    return {rows, isLoading: isLoading || isRefetching, totalRegistros: data?.totalDocs, refetch};
};

export default useFetchRegistros;