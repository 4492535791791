import React, {useEffect, useState} from "react";
import {AlertEvent, AlertEventType, ApiEvent} from "../events/AppEvent";
import history from "../services/RouteService";
import {ApiEventsType} from "../services/ApiService";


declare interface AppStateType {
    hasApiError: boolean,
    apiError?: string,
    alertMessage?: string,
    tipoAlerta?: AlertEventType,
    timeoutErrorMessage?: any,
    timeoutAlertMessage?: any
}

export function GlobalErrorAlert() {

    const [state, setState] = useState({
        hasApiError: false
    } as AppStateType);

    const hideErrorMessage = () => {
        const timeoutErrorMessage = setTimeout(() => {
            setState(state => {
                return {...state, hasApiError: false, timeoutErrorMessage: undefined}
            });
        }, 4000);
        setState((state) => {
            if (state.timeoutErrorMessage) {
                clearTimeout(state.timeoutErrorMessage);
            }
            return {...state, timeoutErrorMessage: timeoutErrorMessage}
        })
    }

    const hideAlertMessage = () => {
        const timeoutAlertMessage = setTimeout(() => {
            setState(state => {
                return {
                    ...state, alertMessage: undefined,
                    tipoAlerta: undefined,
                    timeoutAlertMessage: undefined
                }
            });
        }, 4000);
        setState((state) => {
            if (state.timeoutAlertMessage) {
                clearTimeout(state.timeoutAlertMessage);
            }
            return {...state, timeoutAlertMessage: timeoutAlertMessage}
        })
    }

    const headlerHttpError = (data: any) => {
        if (data.status >= 500) {
            setState({
                hasApiError: true,
                apiError: "Ocorreu uma falha inesperada na sua solicitação, tente novamente!"
            });
            hideErrorMessage();
        } else if (data.status === 403) {
            hideErrorMessage();
        }
    }

    const headlerFetchError = () => {
        setState({
            hasApiError: true,
            apiError: "Solicitação falhou, verifique sua conexão com a internet!"
        });
        hideErrorMessage();
    }


    const headlerAlertEventWarning = (data: any) => {
        setState(state => {
            return {...state, alertMessage: data, tipoAlerta: AlertEventType.WARNING}
        });
        hideAlertMessage();
    }

    const headlerAlertEventDanger = (data: any) => {
        setState(state => {
            return {...state, alertMessage: data, tipoAlerta: AlertEventType.DANGER}
        });
        hideAlertMessage();
    }

    const headlerAlertEventInfo = (data: any) => {
        setState(state => {
            return {...state, alertMessage: data, tipoAlerta: AlertEventType.INFO}
        });
        hideAlertMessage();
    }

    const verificarSessao = (data: any) => {
        if (data.status === 403) {
            history.push("/login");
        }
    }

    useEffect(() => {
        ApiEvent.addListener(ApiEventsType.HTTP_ERROR, verificarSessao);
        ApiEvent.addListener(ApiEventsType.HTTP_ERROR, headlerHttpError);
        ApiEvent.addListener(ApiEventsType.FETCH_ERROR, headlerFetchError);
        AlertEvent.addListener(AlertEventType.WARNING, headlerAlertEventWarning);
        AlertEvent.addListener(AlertEventType.DANGER, headlerAlertEventDanger);
        AlertEvent.addListener(AlertEventType.INFO, headlerAlertEventInfo);
        return () => {
            ApiEvent.removeListener(ApiEventsType.HTTP_ERROR, verificarSessao);
            ApiEvent.removeListener(ApiEventsType.HTTP_ERROR, headlerHttpError);
            ApiEvent.removeListener(ApiEventsType.FETCH_ERROR, headlerFetchError);
            AlertEvent.removeListener(AlertEventType.WARNING, headlerAlertEventWarning);
            AlertEvent.removeListener(AlertEventType.DANGER, headlerAlertEventDanger);
            AlertEvent.removeListener(AlertEventType.INFO, headlerAlertEventInfo);
        }
    })
    return (
        <>
            {state.hasApiError && <div className="api-error">
                <div className="alert alert-danger">
                    {state.apiError}
                </div>
            </div>}
            {state.alertMessage && <div className="api-error">
                <div className={"alert alert-" + state.tipoAlerta?.toString()}>
                    {state.alertMessage?.toString()}
                </div>
            </div>}
        </>
    );
}
