import {FormularioFonteDados} from "../../componets/FormularioFonteDados";
import React from "react";
import {IFonteDeDados} from "../../models/FonteDeDados";
import {ApiService} from "../../services/ApiService";
import history from "../../services/RouteService";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {PropsProjetoPages} from "./ProjetoPage";
import {setFontes} from "../../actions/ProjetoActions";
import {useDispatch} from "react-redux";


export function ProjetoCadastroFontePage(props: PropsProjetoPages & { idFonteParaEditar?: string }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const editarFonteDeDados = (data: any, errorCallback?: (fieldsValues: any, errorCallback?: (erros: {
        name: string,
        type: string,
        message: string
    }[]) => void, formEl?: HTMLFormElement | null) => void) => {
        setLoading(true);
        ApiService.put(`/fonte-de-dados/${props.projeto?.id}`, props.idFonteParaEditar || "", data).then(fonte => {
            const fontes = [...props.fontes] as IFonteDeDados[];
            const index = fontes.findIndex(x => x.id === fonte.id);
            if (index > -1) {
                fontes[index] = fonte
            }
            dispatch(setFontes(fontes, props.projeto.id));
            history.push("/projeto/" + props.projeto.id + "/config-fonte-de-dados");
            setLoading(false);
        }, error => {
            if (error && error.status === 400 && error.data && errorCallback && error.data.errors) {
                const errors = error.data.errors;
                errorCallback(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: String(errors[key].type),
                        message: String(errors[key].message)
                    }
                }));
            }
            setLoading(false);
        })
    }

    const cadastrarNovaFonteDeDados = (data: any, errorCallback?: (fieldsValues: any, errorCallback?: (erros: {
        name: string,
        type: string,
        message: string
    }[]) => void, formEl?: HTMLFormElement | null) => void) => {
        setLoading(true);
        ApiService.post(`/fonte-de-dados/${props.projeto?.id}`, data).then(fonte => {
            dispatch(setFontes([...props.fontes, fonte], props.projeto.id));
            history.push("/projeto/" + props.projeto.id + "/config-fonte-de-dados");
            setLoading(false);
        }, error => {
            if (error && error.status === 400 && error.data && errorCallback && error.data.errors) {
                const errors = error.data.errors;
                errorCallback(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: String(errors[key].type),
                        message: String(errors[key].message)
                    }
                }));
            }
            setLoading(false);
        })
    }

    const removerFonte = () => {
        setLoading(true);
        ApiService.delete(`/fonte-de-dados/${props.projeto?.id}`, props.idFonteParaEditar || "").then(() => {
            const fontes = [...props.fontes] as IFonteDeDados[];
            const index = fontes.findIndex(x => x.id === props.idFonteParaEditar);
            if (index > -1) {
                fontes.splice(index, 1);
            }
            dispatch(setFontes(fontes, props.projeto.id));
            history.push("/projeto/" + props.projeto.id + "/config-fonte-de-dados");
            setLoading(false);
        }, error => {
            if (error && error.status === 400 && error.data) {
                window.alert(error.data);
            }
            setLoading(false);
        })
    }

    return <LayoutProjeto projeto={props.projeto}
                          toggleMenu={props.toggleMenu}
                          headlerToggleMenu={props.headlerToggleMenu}
                          titulo={props.idFonteParaEditar ? "Edição de fonte de dados" : "Cadastro de nova fonte de dados"}
                          administrador={props.administrador}><FormularioFonteDados
        fontes={props.fontes.filter(x => x.id !== props.idFonteParaEditar)}
        projeto={props.projeto}
        onSubmit={props.idFonteParaEditar ? editarFonteDeDados : cadastrarNovaFonteDeDados}
        onCancel={() => {
            history.push("/projeto/" + props.projeto.id + "/config-fonte-de-dados");
        }}
        fonteParaEdicao={props.fontes.find(x => x.id === props.idFonteParaEditar)}
        isLoading={loading}
        onDelete={removerFonte}/>
    </LayoutProjeto>
}
