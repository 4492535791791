import {IComponente, IDashboard} from "../models/Dashboard";
import React, {useEffect, useState} from "react";
import {ApiService} from "../services/ApiService";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export function GraficoPizza(props: { dashboard: IDashboard, componente: IComponente, filtros?: { [campo: string]: any } }) {
    const [valor, setValor] = useState<{ label: string, valor: number }[]>();
    const [legenda, setLegenda] = useState(false);
    const [tentarNovamente, setTentarNovamente] = useState(false);
    useEffect(() => {
        if (props.componente.id && props.dashboard.id) {
            const likeFilters = Object.keys(props.filtros || {});
            const p = {
                likeFilters: likeFilters,
                ...Object.keys(props.filtros || {}).reduce((pre, pos) => {
                    pre[pos] = (props.filtros || {})[pos];
                    return pre;
                }, {} as any),
            };
            setValor(undefined);
            ApiService.get("/dashboards/" + props.dashboard.id, props.componente.id, p).then(valores => {
                setValor(valores);
                setTentarNovamente(false);
            }, () => {
                setTentarNovamente(true);
            });
        }
    }, [props.filtros, props.componente.id, props.dashboard.id])

    let chart = am4core.create(`pizza${props.componente.id}`, am4charts.PieChart);
    if (valor) {
        chart.data = valor;
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        chart.innerRadius = am4core.percent(40);
        chart.align = "center";
        pieSeries.dataFields.value = "valor";
        pieSeries.dataFields.category = "label";
        pieSeries.labels.template.disabled = true;

        // pieSeries.ticks.template.disabled = true;
        // pieSeries.slices.template.tooltipText = "";
        const hs = pieSeries.slices.template.states.getKey("hover");
        if (hs) {
            hs.properties.scale = 1;
            hs.properties.fillOpacity = 0.5;
        }
        if (legenda) {
            chart.legend = new am4charts.Legend();
        }
    }
    return <div className='card'>
        <div className='card-body text-center bg-light'>
            <p className='d-flex'>
                {props.componente.titulo && <b>
                    {props.componente.titulo}
                </b>}
                <button className='btn btn-sm btn-primary ml-auto' onClick={() => setLegenda(l => !l)}>
                    {legenda && "Ocultar Legenda"}
                    {!legenda && "Exibir Legenda"}
                </button>
            </p>
            {!tentarNovamente && <div id={`pizza${props.componente.id}`} style={{
                width: "100%",
                height: '400px'
            }}/>}
            {tentarNovamente && <h1 className='mt-2'>
                <button className='btn btn-link text-black-50 btn-sm' onClick={() => {
                    setTentarNovamente(false);
                }}>
                    Tentar novamente
                </button>
            </h1>}
        </div>
    </div>
}
