import {IProjeto} from "../models/Projeto";
import {ActionProjeto} from "../reducers/ProjetoStore";
import {IFonteDeDados} from "../models/FonteDeDados";
import {IDashboard} from "../models/Dashboard";

export function setProjetos(projetos: IProjeto[]): ActionProjeto {
    return {
        type: "SET_PROJETOS",
        projetos: projetos
    }
}

export function setFontes(fontes: IFonteDeDados[], projetoId:string): ActionProjeto {
    return {
        type: "ADD_FONTES",
        fontes: {
            projetoId: projetoId,
            lista: fontes
        }
    }
}


export function setFonte(fonte: IFonteDeDados, projetoId:string): ActionProjeto {
    return {
        type: "EDITAR_FONTE",
        fonte: {
            projetoId: projetoId,
            fonte: fonte,
        }
    }
}


export function setDashboards(dashboards: IDashboard[], projetoId:string): ActionProjeto {
    return {
        type: "ADD_DASHBOARDS",
        dashboards: {
            projetoId: projetoId,
            lista: dashboards
        }
    }
}
