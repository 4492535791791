import React from "react";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";

export function ProjetoHomePage(props: PropsProjetoPages) {
    return (
        <LayoutProjeto administrador={props.administrador} projeto={props.projeto}
                       titulo={props.projeto.nome}
                       toggleMenu={props.toggleMenu}
                       headlerToggleMenu={props.headlerToggleMenu}
                       isHome={true}>
            <div className="ProjetoHomePage text-center">
                <p>
                    {props.projeto.descricao}
                    <br/>
                    <small>Criado
                        por {props.projeto.proprietario.nome} em {new Date(props.projeto.createdAt).toLocaleString()}</small>
                </p>
            </div>
        </LayoutProjeto>
    );
}
