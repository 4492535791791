import React from 'react';
import {IJsonFromProps, JsonForm} from "../../componets/JsonForm";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import history from "../../services/RouteService";

function CadastrarProjetoPage() {

    const jsonFromProps: IJsonFromProps = {
        onSubmit: (data, errorCallback) => {
            ApiService.post("/projeto", data).then(() => {
                history.push("/");
            }, (error) => {
                if (error && error.data && errorCallback && error.data.errors) {
                    const errors = error.data.errors;
                    errorCallback(Object.keys(errors).map(key => {
                        return {
                            name: key,
                            type: String(errors[key].type),
                            message: String(errors[key].message)
                        }
                    }));
                }
            });
        },
        name: "Cadastrar",
        textSubmitButton: "Cadastrar",
        fields: [
            {
                name: "nome",
                type: "text",
                label: "Nome",
                placeholder: "Insira aqui o nome do projeto",
                validators: {
                    required: {
                        value: true,
                        message: "Nome é obrigatório",
                    },
                    maxLength: {
                        value: 100,
                        message: "Insira no máximo 100 caracteres"
                    }
                }
            },
            {
                name: "descricao",
                type: "text-area",
                label: "Dercrição",
                placeholder: "Insira aqui a descrição do projeto",
                validators: {
                    required: {
                        value: true,
                        message: "Descrção é obrigatório",
                    },
                    maxLength: {
                        value: 1000,
                        message: "Insira no máximo 1000 caracteres"
                    }
                }
            }
        ]
    };

    return (
        <div className="Cadastrar">
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-9 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <h5>Cadastro de projeto</h5>
                                <hr/>
                                <JsonForm {...jsonFromProps}>
                                    <Link to="/projetos" className="btn btn-warning">Voltar</Link>
                                </JsonForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadastrarProjetoPage;
