import React from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";
import {useForm} from "react-hook-form";
import {CampoHelpers, MetaDataColuna} from "../helpers/CampoHelpers";
import {IComponente, IDashboard} from "../models/Dashboard";


export function FormularioCartaoDashboard(props: { fontes: IFonteDeDados[], dashboard: IDashboard, onSubmit: (data: any, errorCallback: (erros: any) => void) => void, onCancel: () => void, componenteEdicao?: IComponente }) {

    const choicesFontes = props.fontes.map(x => {
        return {
            label: x.nome,
            value: x.id
        }
    });

    const jsonFromsCampos: IJsonFromField[] = [
        {
            name: "titulo",
            label: "Título cartão",
            placeholder: "Informe um título para o cartão",
            type: "text",
            validators: {
                maxLength: {
                    value: 100,
                    message: "Informe no máximo 100 caracteres"
                }
            }
        },
        {
            name: "fonteId",
            label: "Fonte de dados",
            placeholder: "Fonte de dados de origem",
            type: "select",
            choices: choicesFontes,
            valueDefault: props.componenteEdicao ? choicesFontes.find(x => x.value === props.componenteEdicao?.fonteId) : undefined,
            disabled: !!props.componenteEdicao,
            validators: {
                required: {
                    value: true,
                    message: "Fonte é obrigatório"
                }
            }
        }
    ];


    const {register, handleSubmit, watch, errors, setError, setValue} = useForm(
        {
            validateCriteriaMode: "all",
            defaultValues: {
                titulo: props.componenteEdicao?.titulo,
                valor: props.componenteEdicao?.valor.campo,
                mascara: props.componenteEdicao?.mascara,
                fonteId: props.componenteEdicao?.fonteId,
                agrupamento: props.componenteEdicao?.valor.agrupameto
            }
        }
    );

    const colunaValor = watch('valor', props.componenteEdicao?.valor.campo)
    const fonteId = watch('fonteId', props.componenteEdicao?.fonteId.toString())

    const choicesFiltros = fonteId ? CampoHelpers.obterEscolhasDeCamposPorFonte({
        fontes: props.fontes,
        fonteId: fonteId as any
    }) : [];
    const choicesMascaras = [
        {
            label: "Moeda (R$)",
            value: "MOEDA"
        },
        {
            label: "Percentual (%)",
            value: "PERCENTUAL"
        }
    ];
    if (fonteId) {
        const metaDatas = props.fontes.find(f => f.id === fonteId)?.campos.reduce((previousValue, currentValue) => {
            return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
        }, {} as { [key: string]: MetaDataColuna });
        const choicesAgrupamento = colunaValor && metaDatas ? CampoHelpers.obterChoicesTiposDeAgrupamento(metaDatas[colunaValor].tipo) : []
        jsonFromsCampos.push({
                name: "valor",
                label: "Selecione qual a coluna deseja exibir no valor",
                type: "select",
                choices: choicesFiltros,
                valueDefault: props.componenteEdicao ? choicesFiltros.filter(x => props.componenteEdicao?.valor.campo === x.value) : undefined,
                validators: {
                    required: {
                        value: true,
                        message: "É necessário informar uma coluna para valor"
                    }
                }
            }, {
                name: "agrupamento",
                label: "Selecione qual a métrica deseja",
                type: "select",
                choices: choicesAgrupamento,
                valueDefault: props.componenteEdicao ? choicesFiltros.filter(x => props.componenteEdicao?.valor.agrupameto === x.value) : undefined,
                validators: {
                    required: {
                        value: true,
                        message: "É necessário informar uma coluna para valor"
                    }
                }
            },
            {
                name: "mascara",
                label: "Selecione uma mascara",
                type: "select",
                choices: choicesMascaras,
                valueDefault: choicesMascaras.find(x => x.value === props.componenteEdicao?.mascara)
            });
    }

    let submitForm = handleSubmit((data) => {
        props.onSubmit(data, (erros: any) => {
            if (erros) {
                setError(erros)
            }
        });
    });

    return (
        <>
            <form onSubmit={submitForm}>
                {jsonFromsCampos.map(field => {
                    return (
                        <FieldJsonForm watch={watch} field={field} errors={errors} register={register} key={field.name}
                                       setValue={setValue}/>);
                })}
                <div className="row">
                    < div className="col-6">
                        <p className="text-left">
                            <button className="btn btn-warning" type="button" onClick={() => {
                                props.onCancel();
                            }}>
                                Cancelar
                            </button>
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="text-right">
                            <button className="btn btn-primary" type="submit">
                                {!props.componenteEdicao && "Adicionar componente"}
                                {props.componenteEdicao && "Salvar"}
                            </button>
                        </p>
                    </div>
                </div>
            </form>
        </>
    );
}
