import {History, LocationState} from 'history';
import {combineReducers} from 'redux';
import {projetoReducer, ProjetoState} from "./ProjetoStore";
import {sessaoReducer, SessaoState} from "./SessaoStore";
import {connectRouter, RouterState} from 'connected-react-router'


export interface AppRootState {
    router: RouterState,
    projeto: ProjetoState,
    sessao: SessaoState
}

export default (history: History<LocationState>) => combineReducers<AppRootState>({
    router: connectRouter(history),
    projeto: projetoReducer,
    sessao: sessaoReducer,
});
