import React from 'react';
import {IJsonFromProps, JsonForm} from "../../componets/JsonForm";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";

function RecuperarSenhaPage() {


    const jsonFromProps: IJsonFromProps = {
        onSubmit: (data, errorCallback) => {
            ApiService.post("/recuperar-senha", data).then(() => {
                alert('Atenção! Foi enviado um email contendo a nova senha para acesso, recomendamos que faça uma nova senha assim que possível!')
            }, (error) => {
                if (errorCallback) {
                    const errors = error.data.errors;
                    errorCallback(Object.keys(errors).map(key => {
                        return {
                            name: key,
                            type: String(errors[key].type),
                            message: String(errors[key].message)
                        }
                    }));
                }
            });
        },
        name: "RecuperarSenha",
        textSubmitButton: "Recuperar Senha",
        fields: [
            {
                name: "email",
                type: "email",
                label: "E-mail",
                placeholder: "Insira aqui o seu e-mail"
            }
        ]
    };

    return (
        <div className="RecuperarSenha">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-6 mx-auto">
                        <div className="card">
                            <div className="card-header bg-primary text-white">
                                Informe seu e-mail para recuperar sua senha
                            </div>
                            <div className="card-body">
                                <JsonForm {...jsonFromProps}>
                                    <Link to="/" className="btn btn-warning">Voltar</Link>
                                </JsonForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecuperarSenhaPage;
