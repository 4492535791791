import React, {useCallback, useMemo} from "react";
import {ICampoDaFonteDeDados, IFonteDeDados} from "../models/FonteDeDados";
import DataGridFonte from "./DataGridFonte";
import {isArray} from "lodash";

type Option = {
    label: string,
    value: string
}

export function FiltroColunaDeRelacionamento(props: {
    campo: ICampoDaFonteDeDados,
    fontes: IFonteDeDados[],
    onSelect: (selected: Option | Option[] | null) => void,
    valores: Option | (Option[]),
    multiSelecao?: boolean
}) {
    const fonteDeTrabalho = useMemo(() => props.fontes.find(
        (x) => x.id === props.campo.fonte?.id
    ), [props.fontes, props.campo.fonte]);

    const campoVisiveis = useMemo(() => props.campo.fonte?.campos.map(x => x.keyOrigem)!, [props.campo]);

    const handleOnSelect = useCallback((data, columns) => {
        const selecionados = [];
        for(let row of (isArray(data) ? data : [data])){
            const selecioado = isArray(props.valores) ? props.valores.some(x => x.value === row.id) : props.valores?.value === row.id;
            if (selecioado) {
                continue;
            }
            const elemento = {
                value: row.id,
                label: Object.keys(row).reduce((previousValue, currentValue) => {
                    const idx = columns.findIndex((x: any) => x.name === currentValue)
                    if (currentValue !== "id" && idx > -1 && campoVisiveis.indexOf(currentValue) > -1) {
                        previousValue.push(`${columns[idx].title}: ${row[currentValue]}`)
                    }
                    return previousValue
                }, [] as string[]).join(" | ")
            };
            selecionados.push(elemento);
        }

        if (props.multiSelecao) {
            props.onSelect([...selecionados, ...(props.valores as [] || [])])
        } else if(selecionados.length > 0) {
            props.onSelect(selecionados[0]);
        }

    }, [campoVisiveis, props]);

    const handleOnRemove = useCallback((id) => {
        if (props.multiSelecao) {
            props.onSelect([...(props.valores as Option[]).filter(x => x.value !== id)])
        } else {
            props.onSelect(null);
        }
    }, [props]);

    return <DataGridFonte
        fontes={props.fontes}
        fonteDeTrabalho={fonteDeTrabalho!}
        onSelectModel={handleOnSelect}
        onRemoveModel={handleOnRemove}
        multiValor={props.multiSelecao}
        valores={props.valores ? isArray(props.valores) ? props.valores.map(x => x.value) : [props.valores?.value] : []}
    />

}
