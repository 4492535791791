import {DataTypeProvider} from "@devexpress/dx-react-grid";
import {TextRangeFilter} from "./TextRangeFilter";
import React from "react";

const TextFilter = (props: DataTypeProvider.ValueEditorProps) => {
    const [tipo, ...query] = props.value
        ? props.value.replace("text:", "").split("#")
        : [];
    return (
        <TextRangeFilter
            defaultValue={props.value ? {tipo, query: query.join("#")} : undefined}
            onChange={(data) =>
                data
                    ? props.onValueChange(`text:${data.tipo}#${data.query}`)
                    : props.onValueChange(null)
            }
        />
    );
};
export default TextFilter;