import React, {FormEvent, useState} from 'react';
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import history from "../../services/RouteService";
import {useDispatch} from "react-redux";
import {login} from "../../actions/SessaoActions";

export function LoginPage() {
    const dispatch = useDispatch();
    const [mensagemDeErro, setMensagemDeErro] = useState<any>(null);
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    const handlerSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!email || !senha) {
            setMensagemDeErro({
                email: "É necessário informar o e-mail cadastrado e sua senha!",
                senha: "É necessário informar o e-mail cadastrado e sua senha!"
            })
        } else {
            ApiService.post("/login", {email, senha}).then(usuario => {
                dispatch(login(usuario));
                history.push("/projetos");
            }, ({status, data}) => {
                if (status === 400) {
                    setMensagemDeErro(data)
                }
            })
        }
    }

    return (
        <div className="Login">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <p className="text-center mb-5">
                            <img src="logo.png" alt="Logo dyData"/>
                        </p>
                        <div className="card">
                            <div className="card-header bg-primary text-white">
                                Login - Informe seu e-mail e senha para entrar
                            </div>
                            <div className="card-body">
                                <form method="post" onSubmit={(event) => handlerSubmit(event)}>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-user-alt"/>
                                                </span>
                                            </div>
                                            <input className="form-control" type="email" name="email"
                                                   defaultValue={email}
                                                   onChange={(event) => setEmail(event.target.value)}
                                                   placeholder="E-mail"/>
                                        </div>
                                        {mensagemDeErro?.['email'] &&
                                            <p className="text-danger">{mensagemDeErro['email']?.join(". ")}</p>}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-key"/>
                                                </span>
                                            </div>
                                            <input className="form-control" type="password" name="senha"
                                                   defaultValue={senha}
                                                   onChange={(event) => setSenha(event.target.value)}
                                                   placeholder="Senha"/>
                                        </div>
                                        {mensagemDeErro?.['senha'] &&
                                            <p className="text-danger">{mensagemDeErro['senha']?.join(". ")}</p>}
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Link to="/recuperar-senha">
                                                Esqueci a senha!
                                            </Link>
                                            <br/>
                                            <Link to="/cadastrar">
                                                Cadastrar
                                            </Link>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit"
                                                    className="btn btn-secondary btn-lg">
                                                Entrar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
