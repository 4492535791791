import React from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";
import {useForm} from "react-hook-form";
import {IDashboard} from "../models/Dashboard";

export function FormularioDashboard(props: { fontes: IFonteDeDados[], onSubmit: (data: any, errorCallback: (erros: any) => void) => void, onCancel: () => void, dashboardEdicao?: IDashboard }) {

    const jsonFromsCampos: IJsonFromField[] = [
        {
            name: "nome",
            label: "Nome",
            placeholder: "Nome da Dashboard",
            type: "text",
            valueDefault: props.dashboardEdicao?.nome,
            validators: {
                required: {
                    value: true,
                    message: "Nome é obrigatório"
                },
                maxLength: {
                    value: 80,
                    message: "Máximo de 80 caracteres"
                }
            }
        },
        {
            name: "descricao",
            label: "Descrição",
            placeholder: "Descrição da dashboard",
            valueDefault: props.dashboardEdicao?.descricao,
            type: "text",
            validators: {
                maxLength: {
                    value: 100,
                    message: "Máximo de 100 caracteres"
                }
            }
        },
        {
            name: "tipo",
            label: "Tipo Dashboard",
            valueDefault: {
                label: props.dashboardEdicao?.tipo || "DYDATA",
                value: props.dashboardEdicao?.tipo || "DYDATA"
            },
            type: "select",
            choices: [
                {
                    label: "DYDATA",
                    value: "DYDATA"
                }, {
                    label: "POWERBI",
                    value: "POWERBI"
                }
            ],
            validators: {
                required: {
                    value: true,
                    message: "Obrigatório"
                }
            }
        }
    ];


    const {register, handleSubmit, watch, errors, setError, setValue, unregister} = useForm(
        {
            validateCriteriaMode: "all",
            defaultValues: {
                nome: props.dashboardEdicao?.nome,
                descricao: props.dashboardEdicao?.descricao,
                tipo: props.dashboardEdicao?.tipo,
                linkPowerBi: props.dashboardEdicao?.linkPowerBi
            }
        }
    );

    const tipo = watch("tipo", props.dashboardEdicao?.tipo)
    if (tipo === "POWERBI") {
        jsonFromsCampos.push({
            name: "linkPowerBi",
            label: "Link Incorporação Power BI",
            valueDefault: props.dashboardEdicao?.linkPowerBi,
            type: "text",
            validators: {
                required: {
                    value: true,
                    message: "Obrigatório"
                }
            }
        })
    } else {
        unregister("linkPowerBi")
    }

    let submitForm = handleSubmit((data) => {
        props.onSubmit(data, (erros: any) => {
            if (erros) {
                setError(erros)
            }
        });
    });

    return (
        <>
            <form onSubmit={submitForm}>
                {jsonFromsCampos.map(field => {
                    return (
                        <FieldJsonForm watch={watch} field={field} errors={errors} register={register} key={field.name}
                                       setValue={setValue}/>);
                })}
                <div className="row">
                    < div className="col-6">
                        <p className="text-left">
                            <button className="btn btn-warning" type="button" onClick={() => {
                                props.onCancel();
                            }}>
                                Cancelar
                            </button>
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="text-right">
                            <button className="btn btn-primary" type="submit">
                                {!props.dashboardEdicao && "Adicionar Dashboard"}
                                {props.dashboardEdicao && "Salvar"}
                            </button>
                        </p>
                    </div>
                </div>
            </form>
        </>
    );
}
