import {IEvento, IFonteDeDados} from "../models/FonteDeDados";
import {useContext, useEffect} from "react";
import {ApiService} from "../services/ApiService";
import {EventosContext} from "../contexts/eventos";

const useEventos = (fonte: IFonteDeDados): IEvento[] => {
    const [eventos, setEventos] = useContext(EventosContext);

    useEffect(() => {
        if (fonte && !eventos[fonte.id] && setEventos) {
            ApiService.getAll(`/eventos/${fonte.id}/`).then(evets => setEventos(
                ({...eventos, [fonte.id]: evets})
            ));
        }
    }, [fonte, eventos, setEventos]);

    return eventos[fonte.id] || []
};

export default useEventos;