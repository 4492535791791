import React from 'react'
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader, SubMenu} from 'react-pro-sidebar';
import '../css/sidebar.scss';
import {IProjeto} from "../models/Projeto";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Link} from "react-router-dom";
import {RotasInternas} from "../pages/Projeto/ProjetoPage";
import {IDashboard} from "../models/Dashboard";
import {useSelector} from "react-redux";
import {AppRootState} from "../reducers";
import {ProjetoState} from "../reducers/ProjetoStore";

const ordenarFontes = (fontes: IFonteDeDados[]) => {
    return fontes.sort((a, b) => {
        return a.nome > b.nome ? 1 : -1;
    })
}

const ordenarDashbaords = (dashboards: IDashboard[]) => {
    return dashboards.sort((a, b) => {
        return a.nome > b.nome ? 1 : -1;
    })
}

export function ProjetoSlidebar(props: { projeto: IProjeto, usuarioId: string, onToggle: (toggled: boolean) => void, toggled: boolean, collapsed: boolean, administrador: boolean }) {
    const projetoState = useSelector<AppRootState, ProjetoState>((state) => state?.projeto)
    const fontes = projetoState?.fontes[props.projeto.id]?.filter(x => String(x.tipo) !== "ESTATICA");
    const dashboards = projetoState?.dashboards[props.projeto.id];
    return fontes && dashboards ?
        <ProSidebar onToggle={props.onToggle} toggled={props.toggled} breakPoint={"md"} collapsed={props.collapsed}>
            <SidebarHeader>
                <div className={'d-flex p-2 mt-2'}>
                    <h3>{props.projeto.nome}</h3>
                    <button className='navbar-brand btn btn-link text-white ml-auto'
                            onClick={() => props.onToggle(!props.toggled)}>
                        <i className='oi oi-menu'/>
                    </button>
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="square">
                    <SubMenu title="Dasboards" className={(dashboards.length > 0) ? '' : 'd-none'}>
                        {ordenarDashbaords(dashboards).map(x => {
                            return <MenuItem key={x.id}>
                                {x.nome}
                                <Link to={`/projeto/${props.projeto.id}/dashboard/${x.id}`}
                                      onClick={() => props.onToggle(!props.toggled)}/>
                            </MenuItem>
                        })}
                    </SubMenu>
                    <SubMenu title="Formulários">
                        {ordenarFontes(fontes.filter(x => props.administrador || (String(x.usuarioCriador.id) === props.usuarioId || x.usuarios.find(u => String(u.id) === props.usuarioId)))).map(x => {
                            return <MenuItem key={x.id}>
                                {x.nome}
                                <Link to={`/projeto/${props.projeto.id}/formulario/${x.id}`}
                                      onClick={() => props.onToggle(!props.toggled)}/>
                            </MenuItem>
                        })}
                    </SubMenu>
                    <SubMenu title="Configuração" className={props.administrador ? '' : 'd-none'}>
                        <MenuItem>
                            Fonte de Dados
                            <Link to={`/projeto/${props.projeto.id}/${RotasInternas.FONTES}/`}/>
                        </MenuItem>
                        <MenuItem>
                            Dashboard's
                            <Link to={`/projeto/${props.projeto.id}/${RotasInternas.DASH}/`}/>
                        </MenuItem>
                        <MenuItem>
                            Compartilhamento
                            <Link to={`/projeto/${props.projeto.id}/${RotasInternas.COMPART}/`}/>
                        </MenuItem>
                    </SubMenu>
                </Menu>
            </SidebarContent>
        </ProSidebar> : <div/>;
}
