import {useMutation, useQuery, useQueryClient} from 'react-query';
import {fnApiCall} from '../config/networking';
import {useCallback} from 'react';

const useInvalidateQuery = (path: string) => {
    const queryClient = useQueryClient();

    const invalidate = useCallback(
        () => queryClient.invalidateQueries({queryKey: [path]}),
        [queryClient, path]
    );

    return {invalidate};
};

const useApiMutation = (
    method: string,
    url: string,
    id?: string,
    mutationOptions?: any
) => {
    const {invalidate} = useInvalidateQuery(url);
    const path = id ? `${url}/${id}` : url;
    return useMutation({
        mutationFn: (data) => fnApiCall(path, {method: method, data}),
        onSuccess: async () => {
            mutationOptions?.onSuccess();
            await invalidate();
        },
        ...mutationOptions
    });
};

export const useApiGet = <T>(url: string, options: any = {}) => {
    const {id, params} = options;
    const path = (id ? `${url}/${id}` : url);
    return useQuery<T>({
            queryKey: [url, params],
            queryFn: (options: any) => fnApiCall(path, {...options, params}),
            refetchOnWindowFocus: false,
            ...options
        }
    );
};

export const useApiPost = (url: string, mutationOptions: any = null) => {
    return useApiMutation('post', url, undefined, mutationOptions);
};

export const useApiPut = (url: string, id?: string, mutationOptions?: any) => {
    return useApiMutation('put', url, id, mutationOptions);
};

export const useApiDelete = (
    url: string,
    id: string,
    mutationOptions: any = null
) => {
    return useApiMutation('delete', url, id, mutationOptions);
};
