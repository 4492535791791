import React, {PropsWithChildren, useState} from "react";
import {Modal} from "react-bootstrap";

export function PopupTexto(props: PropsWithChildren<{ textoBtn: string }>) {

    const [show, setShow] = useState(false);
    return (<>
        <div className="d-inline-block">
            <button className="btn btn-link btn-sm" onClick={() => {
                setShow(true)
            }}>
                {props.textoBtn}
            </button>
            <Modal show={show} size={"xl"} onHide={() => {
                setShow(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.textoBtn}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        </div>
    </>);
}
