import React, {useCallback, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {JsonForm} from "./JsonForm";
import {ApiService} from "../services/ApiService";
import {IUsuario} from "../models/Usuario";
import {IJsonFromField} from "./FieldJsonForm";

export function ButtonAddUsuarioProjeto(props: { onAdd: (usuarioId: string) => void, projetoId: string, usuario?: { nome: string, usuarioId: string, administrador: boolean, compId: string } }) {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const choices = useCallback(async (filter) => {
        if (String(filter).match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            return ApiService.getAll("/usuarios", {filter: filter}).then((usuarios: IUsuario[]) => {
                return usuarios.map((u: IUsuario) => {
                    return {
                        label: `Nome: ${u.nome} | Email: ${u.email}`,
                        value: u.id,
                    }
                })
            })
        }
        return Promise.resolve([])
    }, []);

    const jsonField: IJsonFromField[] = useMemo(() => {
        return [{
            name: 'usuarioId',
            label: "Selecione qual usuário deseja inserir",
            placeholder: "Qual usuário deseja inserir?",
            type: "select",
            choices: choices,
            validators: {
                required: true
            }
        },
            {
                name: 'administrador',
                label: "Esse usuário é Administrador?",
                type: "select",
                choices: [
                    {
                        value: "true",
                        label: "Sim"
                    },
                    {
                        value: "false",
                        label: "Não"
                    }
                ],
                validators: {
                    required: true
                }
            }
        ];

    }, [choices])

    return (<>
        <div className="d-inline-block">
            <button className="btn btn-info" onClick={() => setShow(true)}>
                {props.usuario ? <>
                    <i className={"fa fa-pencil"}/>
                </> : <><i className={"fa fa-plus"}/> Adicionar novo usuário</>}
            </button>
            <Modal show={show} size={"xl"} onHide={() => {
                setShow(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Usuário no Projeto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.usuario && <h3>Alterando usuário {props.usuario.nome}</h3>}
                    <JsonForm name={"edicaoEmMassa"} fields={props.usuario ? [
                        {
                            name: 'administrador',
                            label: "Esse usuário é Administrador?",
                            type: "select",
                            valueDefault: {
                                value: String(props.usuario.administrador),
                                label: props.usuario.administrador ? "Sim" : "Não"
                            },
                            choices: [
                                {
                                    value: "true",
                                    label: "Sim"
                                },
                                {
                                    value: "false",
                                    label: "Não"
                                }
                            ],
                            validators: {
                                required: true
                            }
                        }
                    ] : jsonField} onSubmit={(data) => {
                        setShow(false);
                        if (props.usuario) {
                            ApiService.put("/projeto/add-usuario/" + props.projetoId, props.usuario.compId, {
                                ...data,
                                usuario: props.usuario.usuarioId,
                                projeto: props.projetoId,
                            }).then(data => {
                                setLoading(false);
                                props.onAdd(data['usuario'])
                            }, () => {
                                setLoading(false);
                            })
                        } else {
                            ApiService.post("/projeto/add-usuario/" + props.projetoId, data).then(data => {
                                setLoading(false);
                                props.onAdd(data['usuario'])
                            }, () => {
                                setLoading(false);
                            })
                        }
                    }} textSubmitButton={props.usuario ? "Salvar" : "Adicionar"} loading={loading}>
                        <button type="button" className="btn btn-warning" disabled={loading} onClick={() => {
                            setShow(false);
                        }}>Cancelar
                        </button>
                    </JsonForm>
                </Modal.Body>
            </Modal>
        </div>
    </>);
}
