import {DataTypeProvider} from "@devexpress/dx-react-grid";
import React from "react";
import {MultivalorRFilter} from "./MultivalorRFilter";

const MultivalorFilter = (props: DataTypeProvider.ValueEditorProps) => {
    const tipo = props.value?.replace("multivalor:", "");
    return (
        <MultivalorRFilter
            defaultValue={tipo}
            onChange={(tipo) =>
                tipo
                    ? props.onValueChange(`multivalor:${tipo}`)
                    : props.onValueChange(null)
            }
        />
    );
};
export default MultivalorFilter;