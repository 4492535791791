import React, {useCallback, useMemo, useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {FormInputFonteDedados} from "./FormInputFonteDedados";
import {useApiGet} from "../hooks/useApi";
import {IRegistro} from "../models/Registro";

export function ButtonNovoItemFormulario(props: {
    idRegistroParaEdicao?: string,
    fonteDeTrabalho: IFonteDeDados,
    fontes: IFonteDeDados[],
    onCancel: () => void,
    onSave: (data: any) => void
}) {
    const [show, setShow] = useState(false);
    const {
        data: registroParaEdicao,
        refetch,
        isRefetching,
        isLoading
    } = useApiGet("/fonte-de-dados/valores/" + props.fonteDeTrabalho.id, {
        id: props.idRegistroParaEdicao,
        cacheTime: 0,
        enabled: !!props.idRegistroParaEdicao && show
    });

    const handleCancel = () => {
        setShow(false);
        props.onCancel();
    }

    const handleSubmit = (data: any) => {
        props.onSave(data);
        setShow(false);
    }

    const handleShow = useCallback(() => {
        setShow(true);
        if (registroParaEdicao) {
            refetch();
        }
    }, [refetch, registroParaEdicao]);

    const showEditForm = useMemo(() => !isLoading &&
            props.idRegistroParaEdicao &&
            (registroParaEdicao as any)?.id === props.idRegistroParaEdicao,
        [registroParaEdicao, props.idRegistroParaEdicao, isLoading])

    const loading = useMemo(() => isRefetching || isLoading, [isRefetching, isLoading]);

    return (<>
        <div className="d-inline-block">
            {!props.idRegistroParaEdicao &&
                <button className="btn btn-success" onClick={handleShow}><i className="fa fa-plus"/> Novo
                </button>}
            {props.idRegistroParaEdicao &&
                <button className="btn btn-sm btn-link text-info" onClick={handleShow}><i
                    className="oi oi-pencil"/>
                </button>}
            <Modal show={show} size={"xl"} onHide={handleCancel}>
                <Modal.Header closeButton>
                    {!props.idRegistroParaEdicao &&
                        <Modal.Title>Novo Item Formulário | {props.fonteDeTrabalho.nome}</Modal.Title>}
                    {props.idRegistroParaEdicao &&
                        <Modal.Title>Edição Item Formulário | {props.fonteDeTrabalho.nome}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {loading && <p className="text-center"><i className="fa fa-spinner fa-pulse"/></p>}
                    {!loading && showEditForm && registroParaEdicao &&
                        <FormInputFonteDedados onSubmit={handleSubmit}
                                               registroParaEdicao={registroParaEdicao as IRegistro}
                                               fonteDeTrabalho={props.fonteDeTrabalho}
                                               fontes={props.fontes}>
                            <button type="button" className="btn btn-warning" onClick={handleCancel}>Cancelar
                            </button>
                        </FormInputFonteDedados>}

                    {!isLoading && props.idRegistroParaEdicao && !registroParaEdicao &&
                        <p>Falha ao carregar informação, tente novamente mais tarde!</p>}

                    {!props.idRegistroParaEdicao && <FormInputFonteDedados onSubmit={handleSubmit}
                                                                           fonteDeTrabalho={props.fonteDeTrabalho}
                                                                           fontes={props.fontes}>
                        <button type="button" className="btn btn-warning" onClick={handleCancel}>Cancelar
                        </button>
                    </FormInputFonteDedados>}
                </Modal.Body>
            </Modal>
        </div>
    </>);
}
