import React from 'react';
import {IJsonFromProps, JsonForm} from "../../componets/JsonForm";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import history from "../../services/RouteService";
import {useDispatch} from "react-redux";
import {login} from "../../actions/SessaoActions";

function CadastrarPage() {
    const dispatch = useDispatch();
    const jsonFromProps: IJsonFromProps = {
        onSubmit: (data, errorCallback) => {
            ApiService.post("/usuario", data).then(usuario => {
                dispatch(login(usuario));
                history.push("/projetos");
            }, (error) => {
                if (error && error.data && errorCallback) {
                    const errors = error.data;
                    errorCallback(Object.keys(errors).map(key => {
                        return {
                            name: key,
                            type: 'ValidationError',
                            message: String(errors[key])
                        }
                    }));
                }
            });
        },
        name: "Cadastrar",
        textSubmitButton: "Cadastrar",
        fields: [
            {
                name: "nome",
                type: "text",
                label: "Nome",
                placeholder: "Insira aqui o seu nome",
                validators: {
                    required: {
                        value: true,
                        message: "Nome é obrigatório",
                    },
                    maxLength: {
                        value: 100,
                        message: "Insira no máximo 100 caracteres"
                    }
                }
            },
            {
                name: "email",
                type: "email",
                label: "E-mail",
                placeholder: "Insira aqui o seu e-mail",
                validators: {
                    required: {
                        value: true,
                        message: "Email é obrigatório",
                    },
                    maxLength: {
                        value: 100,
                        message: "Insira no máximo 100 caracteres"
                    }
                }
            }, {
                name: "password",
                type: "password",
                label: "Senha",
                placeholder: "Insira aqui a sua senha",
                validators: {
                    required: {
                        value: true,
                        message: "Senha é obrigatório",
                    },
                    maxLength: {
                        value: 100,
                        message: "Insira no máximo 100 caracteres"
                    }
                }
            }
        ]
    };

    return (
        <div className="Cadastrar">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-6 mx-auto">
                        <div className="card">
                            <div className="card-header bg-primary text-white">
                                Informe seus dados para cadastro do seu login
                            </div>
                            <div className="card-body">
                                <JsonForm {...jsonFromProps}>
                                    <Link to="/" className="btn btn-warning">Voltar</Link>
                                </JsonForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadastrarPage;
