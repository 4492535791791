import React, {useState} from "react";
import {TipoDeFonteDeDados} from "../../componets/FormularioFonteDados";
import {CampoHelpers} from "../../helpers/CampoHelpers";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {PropsProjetoPages} from "./ProjetoPage";
import {Link, useHistory} from "react-router-dom";
import {Dropdown, Modal} from "react-bootstrap";
import {IFonteDeDados} from "../../models/FonteDeDados";


export function ProjetoFontesPage(props: PropsProjetoPages) {
    const history = useHistory();
    const [showApiInfo, setShowApiInfo] = useState<IFonteDeDados>();
    const fontesDinamicas = props.fontes.filter(x => TipoDeFonteDeDados[String(x.tipo) as keyof typeof TipoDeFonteDeDados] !== TipoDeFonteDeDados.ESTATICA)
    const fontesEstaticas = props.fontes.filter(x => TipoDeFonteDeDados[String(x.tipo) as keyof typeof TipoDeFonteDeDados] === TipoDeFonteDeDados.ESTATICA)
    return (
        <LayoutProjeto projeto={props.projeto}
                       toggleMenu={props.toggleMenu}
                       headlerToggleMenu={props.headlerToggleMenu}
                       administrador={props.administrador}
                       titulo="Configuração do Projeto - Fontes de dados">
            <div className="ProjetoFontesPages">
                <div className="row mb-2">
                    <div className="col-8">
                        <p><b>Fonte de dados dinâmicas</b></p>
                    </div>
                    <div className="col-4 text-right">
                        <Link to={"/projeto/" + props.projeto.id + "/cadastro-fonte/"} className="btn btn-primary">
                            <i className="fas fa-plus"/> Nova Fonte de Dados
                        </Link>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Ações</th>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Tipo</th>
                            <th>Campos/Colunas</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fontesDinamicas.length > 0 ? fontesDinamicas.map(fonte => {
                            return (
                                <tr key={fonte.id}>
                                    <td>
                                        <div className="btn-group">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                    <i className="fas fa-cog"/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            history.push("/projeto/" + props.projeto.id + "/cadastro-fonte/" + fonte.id)
                                                        }}
                                                    >
                                                        <i className="fa fa-pencil"/> Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            history.push("/projeto/" + props.projeto.id + "/config-colunas/" + fonte.id)
                                                        }}
                                                    >
                                                        <i className="oi oi-list-rich"/> Configurar colunas
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            history.push("/projeto/" + props.projeto.id + "/eventos-fonte/" + fonte.id)
                                                        }}
                                                    >
                                                        <i className="fa fa-check"/> Configurar eventos
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setShowApiInfo(fonte);
                                                        }}
                                                    >
                                                        <i className="fa fa-share"/> Doc Api
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                    </td>
                                    <td>{fonte.nome}</td>
                                    <td>{fonte.descricao || "-"}</td>
                                    <td>{TipoDeFonteDeDados[String(fonte.tipo) as keyof typeof TipoDeFonteDeDados]}</td>
                                    <td className="text-capitalize">{CampoHelpers.obterEscolhasDeCamposPorFonte({
                                        fontes: props.fontes as any,
                                        fonteId: fonte.id
                                    }).map(x => x.label).join(", ") || "-"}</td>
                                </tr>
                            );
                        }) : <tr>
                            <td colSpan={6}>
                                Nenhuma fonte dinâmica cadastrada!
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
                <hr/>
                <p><b>Fonte de dados estáticas</b></p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Ações</th>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Valores</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fontesEstaticas.length > 0 ? fontesEstaticas.map(fonte => {
                            return (
                                <tr key={fonte.id}>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <i className="fas fa-cog"/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        history.push("/projeto/" + props.projeto.id + "/cadastro-fonte/" + fonte.id)
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"/> Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setShowApiInfo(fonte);
                                                    }}
                                                >
                                                    <i className="fa fa-share"/> Doc Api
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>{fonte.nome}</td>
                                    <td>{fonte.descricao || "-"}</td>
                                    <td>{fonte.valoresEstaticos?.map(x => x.valor).join(", ") || "-"}</td>
                                </tr>
                            );
                        }) : <tr>
                            <td colSpan={6}>
                                Nenhuma fonte estática cadastrada!
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={!!showApiInfo} size={"xl"} onHide={() => setShowApiInfo(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>Documentação de API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {String(showApiInfo?.tipo) === "ESTATICA" ? <p>
                        Não é permitida a insersão/alteração de valores em fontes estáticas via API. <br/>
                        <br/>
                        Para utilizar os valores das fontes estáticas, utilize os IDs dos valores conforme abaixo:<br/>
                        <table className="table w-100">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                            {showApiInfo?.valoresEstaticos?.map(x => <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.valor}</td>
                            </tr>)}
                            </tbody>
                        </table>
                    </p> : <p>
                        <p>Para inserir dados na nesta fonte utilize o método POST confirme descrição abaixo: </p>
                        <p>
                            Endpoint: <b>https://www.dydata.com.br/api/fonte-de-dados/valores/{showApiInfo?.id}/</b><br/>
                            Método: <b>POST</b> <br/>
                            Content-Type: <b>application/json</b> <br/>
                            Authentication: <b>Basic authentication</b>
                        </p>
                        <p>Para editar dados na nesta fonte utilize o método PUT confirme descrição abaixo: </p>
                        <p>
                            Endpoint: <b>https://www.dydata.com.br/api/fonte-de-dados/valores/{showApiInfo?.id}/:ID_REGISTRO/</b><br/>
                            <small><i>Substituir :ID_REGISTRO na url pelo ID do registro</i></small><br/>
                            Método: <b>PUT</b> <br/>
                            Content-Type: <b>application/json</b> <br/>
                            Authentication: <b>Basic authentication</b>
                        </p>
                        <p>Para obter dados na nesta fonte utilize o método GET confirme descrição abaixo: </p>
                        <p>
                            Endpoint: <b>https://www.dydata.com.br/api/fonte-de-dados/valores/{showApiInfo?.id}/?page=1</b><br/>
                            Método: <b>GET</b> <br/>
                            Content-Type: <b>application/json</b> <br/>
                            Authentication: <b>Basic authentication</b>
                        </p>
                        <p>
                            Cada campo possui um ID em formato UUID, logo, é necessário informar o ID do campo conforme
                            mapa abaixo:<br/>
                            {showApiInfo?.campos.filter(x => !x.espelho).map(x => (<React.Fragment key={x.id}>
                                {String(x.tipo) === "AGRUPAMENTO" ? x.fonte?.campos.map(rel => <React.Fragment
                                    key={rel.id}>
                                    {rel.nome} - <b>{rel.key}</b><br/>
                                </React.Fragment>) : <>
                                    {x.nome} - <b>{x.id}</b><br/>
                                </>}
                            </React.Fragment>))
                            }
                        </p>
                        <p>
                            OBS: Para os campos de relacionamento (tipo lista) é necessário informar o ID da linha de
                            referência.
                        </p>
                    </p>}
                </Modal.Body>
            </Modal>
        </LayoutProjeto>
    );
}
