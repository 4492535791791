import React, {PropsWithChildren, useState} from "react";
import {IEvento} from "../models/FonteDeDados";

export type EventosStateType = { [key: string]: IEvento[] };
export type EventosContextStateType = [EventosStateType, React.Dispatch<EventosStateType>];

export const EventosContext = React.createContext<EventosContextStateType>([{}, () => {
}]);

export const EventosProvider = ({children}: PropsWithChildren<{}>) => {
    const state = useState<EventosStateType>({});
    return <EventosContext.Provider value={state}>{children}</EventosContext.Provider>
};