import {TipoDeMascara} from "../@types/models";

export class MascarasHelpers {

    static aplicarMascaraEmValor(mascara: string, valor: any) {
        if (!valor && valor !== 0) {
            return "-"
        }
        switch (mascara) {
            case TipoDeMascara.CAIXA_ALTA:
                return String(valor).toUpperCase()
            case TipoDeMascara.MOEDA:
                const moeda = Number(valor)
                return !moeda && moeda !== 0 ? valor : moeda.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
            case TipoDeMascara.PERCENTUAL:
                const perc = Number(valor)
                return !perc && perc !== 0 ? valor : `${(perc * 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}%`;
            default:
                return valor;
        }
    }

}
