import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Dropdown, Modal} from "react-bootstrap";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {IComponente, IDashboard} from "../models/Dashboard";
import {CampoHelpers, MetaDataColuna} from "../helpers/CampoHelpers";
import {FormularioGraficoBarraDashboard} from "./FormularioGraficoBarraDashboard";


export function ButtonGraficoBarraDashboard(props: { onAdd: (dashboard: IDashboard) => void, projeto: IProjeto, dashboard: IDashboard, fontes: IFonteDeDados[], componenteEdicao?: IComponente }) {

    const [show, setShow] = useState(false);

    const handlerSubmit = (data: any, callbackError: (error: any) => void) => {
        const dataSend = {
            ...props.dashboard
        }
        const metaDatas = props.fontes.find(f => f.id === data.fonteId)?.campos.reduce((previousValue, currentValue) => {
            return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
        }, {} as { [key: string]: MetaDataColuna }) || {};
        if (props.componenteEdicao) {
            const inx = dataSend.componentes.findIndex(x => x.id === props.componenteEdicao?.id)
            dataSend.componentes[inx] = {
                ...dataSend.componentes[inx],
                titulo: data.titulo,
                legenda: data.legenda,
                eixo: {
                    campo: data.eixo,
                    tipo: metaDatas[data.eixo].tipo
                },
                valor: {
                    campo: data.valor,
                    agrupameto: data.agrupamento
                },
                tituloLegenda: data.tituloLegenda,
                tituloEixoY: data.tituloEixoY,
                tituloEixoX: data.tituloEixoX
            } as IComponente
        } else {
            dataSend.componentes?.push({
                titulo: data.titulo,
                fonteId: data.fonteId,
                tipo: "BARRA",
                legenda: data.legenda,
                eixo: {
                    campo: data.eixo,
                    tipo: metaDatas[data.eixo].tipo
                },
                valor: {
                    campo: data.valor,
                    agrupameto: data.agrupamento
                },
                tituloLegenda: data.tituloLegenda,
                tituloEixoY: data.tituloEixoY,
                tituloEixoX: data.tituloEixoX
            } as IComponente)
        }
        ApiService.put("/dashboards/" + props.projeto.id, props.dashboard.id, dataSend).then(dash => {
            props.onAdd(dash);
            setShow(false);
        }, (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
                callbackError(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: "ValidationError" + key,
                        message: String(errors[key])
                    }
                }));
            }
        })
    }

    return (<div className="d-inline-block">
        {!props.componenteEdicao && <Dropdown.Item onClick={() => setShow(true)}>Gráfico de Barras</Dropdown.Item>}
        {props.componenteEdicao && <button className='btn btn-primary' onClick={() => setShow(true)}>
            <i className='fa fa-pencil'/>
        </button>}
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Gráfico de Barras</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && <FormularioGraficoBarraDashboard dashboard={props.dashboard} fontes={props.fontes}
                                                          componenteEdicao={props.componenteEdicao} onCancel={() => {
                    setShow(false);
                }} onSubmit={(data, onErr) => {
                    handlerSubmit(data, onErr);
                }}/>}
            </Modal.Body>
        </Modal>
    </div>);
}
