import React, {useEffect,} from 'react';
import './App.scss';
import {LoginPage} from "./pages/Login/LoginPage";
import CadastrarPage from "./pages/Cadastrar/CadastrarPage";
import RecuperarSenhaPage from "./pages/RecuperarSenha/RecuperarSenhaPage";
import {Route, Switch} from "react-router-dom";
import {HomePage} from "./pages/Home/HomePage";
import {ApiEvent} from "./events/AppEvent";
import {ApiEventsType} from "./services/ApiService";
import history from "./services/RouteService";
import Layout from "./componets/Layout";
import {ProjetoPage} from "./pages/Projeto/ProjetoPage";
import CadastrarProjetoPage from "./pages/CadastrarProjeto/CadastrarProjeto";
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import "@icon/open-iconic/open-iconic.css"
import {ProjetoFontesPage} from "./pages/Projeto/ProjetoFontesPage";
import {ProjetoHomePage} from "./pages/Projeto/ProjetoHomePage";
import {ProjetoFormularioPage} from "./pages/Projeto/ProjetoFormularioPage";
import {ProjetoCompartilhamentoPage} from "./pages/Projeto/ProjetoCompartilhamentoPage";
import {ProjetoCadastroFontePage} from "./pages/Projeto/ProjetoCadastroFontePage";
import {ProjetoConfigColunasPage} from "./pages/Projeto/ProjetoConfigColunasPage";
import {GlobalErrorAlert} from "./componets/GlobalErrorAlert";
import {ProjetoConfigDashboardPage} from "./pages/Projeto/ProjetoConfigDashboardPage";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import configureStore from "./stores";
import {ProjetoConfigComponentsDashboardPage} from "./pages/Projeto/ProjetoConfigComponentsDashboardPage";
import {ProjetoDashboard} from "./pages/Projeto/ProjetoDashboard";
import {EventosProvider} from "./contexts/eventos";
import {ProjetoEventosPage} from "./pages/Projeto/ProjetoEventosPage";
import {QueryClient, QueryClientProvider} from "react-query";

export const store = configureStore();
const queryClient = new QueryClient();

function App() {
    const verificarSessao = (data: any) => {
        if (data.status === 401) {
            history.push("/login");
        }
    }

    useEffect(() => {
        ApiEvent.addListener(ApiEventsType.HTTP_ERROR, verificarSessao);
        return () => {
            ApiEvent.removeListener(ApiEventsType.HTTP_ERROR, verificarSessao);
        }
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <EventosProvider>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <GlobalErrorAlert/>
                        <Switch>
                            <Route path="/cadastrar">
                                <CadastrarPage/>
                            </Route>
                            <Route path="/recuperar-senha">
                                <RecuperarSenhaPage/>
                            </Route>
                            <Route path="/projeto/add">
                                <Layout>
                                    <CadastrarProjetoPage/>
                                </Layout>
                            </Route>
                            <Route path="/projeto/:projetoId/config-fonte-de-dados" render={props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={props1 => {
                                                     return props1.administrador ?
                                                         <ProjetoFontesPage {...props1}/> :
                                                         <h4>Solicitação Inválida</h4>
                                                 }}/>
                                </Layout>
                            }}/>
                            <Route path="/projeto/:projetoId/config-colunas/:id" render={props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={props1 => {
                                                     return <ProjetoConfigColunasPage {...props1}
                                                                                      idFonteDeTrabalho={props.match.params.id}/>
                                                 }}/>
                                </Layout>
                            }}/>
                            <Route path="/projeto/:projetoId/cadastro-fonte/:id?" render={props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={props1 => {
                                                     return <ProjetoCadastroFontePage {...props1}
                                                                                      idFonteParaEditar={props.match.params.id}/>
                                                 }}/>
                                </Layout>
                            }}/>
                            <Route path="/projeto/:projetoId/eventos-fonte/:id" render={props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={props1 => {
                                                     return <ProjetoEventosPage {...props1}
                                                                                idFonteDeTrabalho={props.match.params.id}/>
                                                 }}/>
                                </Layout>
                            }}/>
                            <Route path="/projeto/:projetoId/formulario/:id" render={props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={props1 => {
                                                     return <ProjetoFormularioPage {...props1}
                                                                                   key={props.match.params.id}
                                                                                   fonteId={props.match.params.id}/>
                                                 }}/>
                                </Layout>
                            }}/>
                            <Route path="/projeto/:projetoId/config-compartilhamentos/" render={(props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={x =>
                                                     <ProjetoCompartilhamentoPage {...x}/>}/>
                                </Layout>
                            })}/>
                            <Route path="/projeto/:projetoId/config-dashboard/:id?" render={(props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={x => {
                                                     if (props.match.params.id) {
                                                         return <ProjetoConfigComponentsDashboardPage {...x}
                                                                                                      dashboardId={props.match.params.id}/>
                                                     }
                                                     return <ProjetoConfigDashboardPage {...x}/>
                                                 }}/>
                                </Layout>
                            })}/>
                            <Route path="/projeto/:projetoId/dashboard/:id?" render={(props => {
                                return <Layout>
                                    <ProjetoPage projetoId={props.match.params.projetoId}
                                                 render={x => {
                                                     return <ProjetoDashboard {...x} dashboardId={props.match.params.id}
                                                                              key={props.match.params.id}/>
                                                 }}/>
                                </Layout>
                            })}/>
                            <Route path="/projeto/:projetoId" render={(props => {
                                return <Layout>
                                    <ProjetoPage
                                        projetoId={props.match.params.projetoId}
                                        render={props1 => {
                                            return <ProjetoHomePage {...props1}/>
                                        }}/>
                                </Layout>
                            })}/>
                            <Route path="/projetos">
                                <Layout>
                                    <HomePage/>
                                </Layout>
                            </Route>
                            <Route path="/">
                                <LoginPage/>
                            </Route>
                        </Switch>
                    </ConnectedRouter>
                </Provider>
            </EventosProvider>
        </QueryClientProvider>
    );
}

export default App;
