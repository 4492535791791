import DataGrid from "./DataGrid";
import {Loading} from "./Loading";
import ConfiguracaoColunas from "./ConfiguracaoColunas";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Filter, Sorting, TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {FormularioPageHelpers} from "../helpers/FormularioPageHelpers";
import {AlertEvent, AlertEventType, DataTableEvent} from "../events/AppEvent";
import {ApiService} from "../services/ApiService";
import TabCell from "./TabCell";
import {TableHeaderRow, Toolbar} from "@devexpress/dx-react-grid-bootstrap4";
import ToolbarHeader from "./ToolbarHeader";
import {IFonteDeDados} from "../models/FonteDeDados";
import {IUsuario} from "../models/Usuario";
import {isString} from "lodash";
import {CampoHelpers} from "../helpers/CampoHelpers";
import useEventos from "../hooks/useEventos";
import useFetchRegistro from "../hooks/useFetchRegistro";

type Props = {
    fonteDeTrabalho: IFonteDeDados,
    fontes: IFonteDeDados[],
    administrador?: boolean,
    multiValor?: boolean,
    onSelectModel?: (row: any, columns: any) => void,
    onRemoveModel?: (id: string) => void,
    valores?: string[],
    usuario?: IUsuario,
    filtros?: any,
}
const DataGridFonte = (props: Props) => {
        const {fonteDeTrabalho} = props;
        const [loading, setLoading] = useState<boolean | string>(false);
        const [selection, setSelection] = useState([] as Array<string>);
        const [showConfVisualizacao, setShowConfVisualizacao] = useState(false);
        const [fetchState, setFetchState] = useState({
            pageSize: 20,
            currentPage: 0,
            pageSizes: [20, 50, 100, 250, 500],
            filters: [] as Filter[],
            sorting: [] as Sorting[],
        });

        const [userProfile, setUserProfile] = useState<{
            pageSize?: number;
            sorting?: Sorting[];
            columnsFix?: Array<string>;
            hiddenColumnNames?: Array<string>;
            columnOrder?: Array<string>;
            columnWidths?: Array<TableColumnWidthInfo>;
        }>();

        const eventos = useEventos(props.fonteDeTrabalho);

        const columns = useMemo(() => [
            {
                name: "opcoes",
                title: "Opções",
                tipo: "none"
            },
            ...FormularioPageHelpers.obterColunasParaGrid(
                props.fontes,
                fonteDeTrabalho
            ),
        ], [props.fontes, fonteDeTrabalho]);

        const orderDefault = useMemo(() => fonteDeTrabalho?.ordenacaoCampos
            ? ["opcoes", ...fonteDeTrabalho?.ordenacaoCampos]
            : columns.map((x) => x.name), [fonteDeTrabalho, columns]);

        const {dateColumns, textColumns, numberColumns, multivalorColumns} = useMemo(() => ({
            dateColumns: FormularioPageHelpers.obterColunasData(props.fontes, fonteDeTrabalho),
            textColumns: FormularioPageHelpers.obterColunasTexto(props.fontes, fonteDeTrabalho),
            numberColumns: FormularioPageHelpers.obterColunasNumero(props.fontes, fonteDeTrabalho),
            multivalorColumns: FormularioPageHelpers.obterColunasMultivalor(props.fontes, fonteDeTrabalho),
        }), [props.fontes, fonteDeTrabalho]);

        const {usuarioPodeEditar, usuarioPodeExcluir, usuarioPodeInserir} = useMemo(() => ({
            usuarioPodeEditar: props.administrador || fonteDeTrabalho?.usuarios.some(
                (x) => x.id === props.usuario?.id && x.edicao
            ),
            usuarioPodeExcluir: props.administrador || fonteDeTrabalho?.usuarios.some(
                (x) => x.id === props.usuario?.id && x.exclusao
            ),
            usuarioPodeInserir: props.administrador || fonteDeTrabalho?.usuarios.some(
                (x) => x.id === props.usuario?.id && x.insersao
            )
        }), [fonteDeTrabalho, props.administrador, props.usuario]);

        const opcoesParams = useMemo(() => ({
            ordenar: fetchState.sorting
                .filter((x: any) => x && !!x.columnName)
                .map((x: any) => `${x.direction === "desc" ? "-" : ""}${x.columnName}`)
                .join(","),
            fonteId: props.fonteDeTrabalho?.id,
            pagina: fetchState.currentPage + 1,
            tamanhoPagina: fetchState.pageSize,
            likeFilters: fetchState.filters.filter(x => x.value).map((x: any) => {
                return {
                    key: x.columnName,
                    value: x.value,
                };
            }),
            filters: props.filtros,
        }), [fetchState, props.fonteDeTrabalho, props.filtros]);

        const filters = useMemo(() => {
            const opcoes = opcoesParams;
            opcoes.pagina = opcoes.pagina || 1;
            opcoes.tamanhoPagina = opcoes.tamanhoPagina || 15;
            const parms = {
                page: String(opcoes.pagina),
                tamanhoPagina: opcoes.tamanhoPagina,
                tipoCondicao: "and"
            } as any;
            if (opcoes.ordenar) {
                parms['ordenar'] = opcoes.ordenar
            }
            if (opcoes.likeFilters && opcoes.likeFilters.length > 0) {
                parms['likeFilters'] = [];
                opcoes.likeFilters.forEach(x => {
                    parms['likeFilters'].push(x.key);
                    parms[x.key] = x.value
                });
            }
            if (opcoes.filters && opcoes.filters.length > 0) {
                parms['filters'] = [];
                opcoes.filters.forEach((x: any) => {
                    parms['filters'].push(x.key);
                    parms[x.key] = x.value
                });
            }
            return parms;
        }, [opcoesParams]);


        const {rows, isLoading, totalRegistros, refetch} = useFetchRegistro(fonteDeTrabalho, props.fontes, filters);

        const {
            linkExportCsv,
            linkExportXlsx
        } = useMemo(() => {
            return {
                linkExportCsv: FormularioPageHelpers.obterLinkParaExportacaoCsv(opcoesParams),
                linkExportXlsx: FormularioPageHelpers.obterLinkParaExportacaoXlsx(opcoesParams)
            }
        }, [opcoesParams]);

        const handleSelectItems = useCallback((sels: (string)[]) => {
            setSelection(sels);
            DataTableEvent.emit("changeSelectItem", sels.length);
        }, []);

        const commitProfile = useCallback(async (data: {
            pageSize?: number;
            sorting?: Sorting[];
            columnsFix?: Array<string>;
            hiddenColumnNames?: Array<string>;
            columnOrder?: Array<string>;
            columnWidths?: Array<TableColumnWidthInfo>;
        }) => {
            setUserProfile(x => ({...x, ...data}));
            await ApiService.put("/config-fonte", String(fonteDeTrabalho?.id), {
                configuracao: {
                    ...userProfile,
                    ...data,
                    columnOrder: (data?.columnOrder || userProfile?.columnOrder)?.filter(x => x !== "opcoes"),
                    columnsFix: (data?.columnsFix || userProfile?.columnsFix)?.filter(x => x !== "opcoes")
                }
            });
        }, [fonteDeTrabalho, userProfile]);

        const handleSortingChange = useCallback((s: Sorting[]) => {
            const novaOrdeancao = (fetchState.sorting
                ? [...fetchState.sorting.filter((x: any) => x.direction && x.columnName)]
                : []);
            s.forEach((x) => {
                const campo = fonteDeTrabalho.campos.find(campo => campo.id === x.columnName || campo.fonte?.campos.some(y => y.id === x.columnName));
                if (x.columnName === "opcoes" || campo?.multivalor || campo?.fonte?.campos.some(y => y.id === x.columnName && y.multivalor)) return;
                const col = fetchState.sorting.findIndex(
                    (y: any) => y.columnName === x.columnName
                );
                if (col >= 0 && fetchState.sorting[col].direction === "desc") {
                    novaOrdeancao.splice(col, 1);
                } else if (col >= 0) {
                    novaOrdeancao[col] = x;
                } else {
                    novaOrdeancao.push(x);
                }
            });
            setFetchState(x => ({...x, sorting: novaOrdeancao}));
            commitProfile({sorting: novaOrdeancao});
        }, [fetchState.sorting, commitProfile, fonteDeTrabalho.campos]);

        const onDelete = useCallback(async (data: any) => {
            const isArray = Array.isArray(data);
            if (
                // eslint-disable-next-line no-restricted-globals
                confirm(
                    isArray ? `Deseja realmente remover ${data.length} registros? Atenção, essa ação não poderá ser revertida!` :
                        "Deseja realmente remover esse registro? Atenção, essa ação não poderá ser revertida!"
                )
            ) {
                setLoading("Verificando relacionamentos entre tabelas, por favor, aguarde! Isso pode levar alguns segundos...");
                try {
                    const excluidos = await ApiService.delete("/fonte-de-dados/valores", String(fonteDeTrabalho?.id), {
                        id: isArray ? data : [data.id],
                    });

                    for (const excluido of excluidos) {
                        const linha = {
                            'id': excluido.id,
                        } as any;

                        for (const campo of props.fonteDeTrabalho.campos) {
                            linha[campo.nome] = await CampoHelpers.obterCampoParaFuncao(props.fontes, campo, excluido);
                        }

                        for (const evento of eventos.filter(x => x.onDelete)) {
                            try {
                                await CampoHelpers.processarFuncao(props.fontes, evento.metaDataFuncao, linha);
                            } catch (e) {
                                AlertEvent.emit(AlertEventType.DANGER, `Falha ao processar função do evento ${evento.nome}`);
                            }
                        }
                    }

                    refetch();
                } catch (error) {
                    if (error.status === 400) {
                        AlertEvent.emit(AlertEventType.DANGER, error.data?.error);
                    }
                } finally {
                    setLoading(false);
                }
            }
        }, [fonteDeTrabalho, refetch, eventos, props.fonteDeTrabalho.campos, props.fontes]);

        const isFetching = useMemo(() => isLoading || loading, [isLoading, loading]);

        const Cell = useCallback((propsCell) => {
            return <TabCell
                fonteDeTrabalho={fonteDeTrabalho}
                usuarioPodeInserir={usuarioPodeInserir}
                usuarioPodeEditar={usuarioPodeEditar}
                usuarioPodeExcluir={usuarioPodeExcluir}
                fontes={props.fontes}
                onLoadData={refetch}
                onDelete={onDelete}
                columns={columns}
                multiValor={props.multiValor}
                onSelect={props.onSelectModel}
                onRemove={props.onRemoveModel}
                valores={props.valores}
                {...propsCell} />
        }, [fonteDeTrabalho, usuarioPodeInserir, usuarioPodeEditar, usuarioPodeExcluir, props.fontes, columns,
            onDelete, props.onSelectModel, props.onRemoveModel, props.multiValor, props.valores, refetch]);

        const LocalToolbarHeader = useCallback((tbProps: Toolbar.RootProps) => (<ToolbarHeader
            columns={columns}
            fontes={props.fontes}
            fonteDeTrabalho={fonteDeTrabalho}
            onShowConfVisualizacao={setShowConfVisualizacao}
            usuarioPodeInserir={usuarioPodeInserir}
            usuarioPodeEditar={usuarioPodeEditar}
            usuarioPodeExcluir={usuarioPodeExcluir}
            onLoadData={refetch}
            selection={selection}
            onDelete={onDelete}
            onSelectItem={handleSelectItems}
            onCommitProfile={commitProfile}
            linkExportCsv={linkExportCsv}
            linkExportXlsx={linkExportXlsx}
            {...tbProps}
        />), [columns, props.fontes, fonteDeTrabalho, usuarioPodeInserir, usuarioPodeEditar, usuarioPodeExcluir,
            selection, onDelete, handleSelectItems, commitProfile, linkExportCsv, linkExportXlsx]);

        const HeaderCellComponent = useCallback((propsCell) => {
            if (propsCell.column.name === "opcoes") {
                return <TableHeaderRow.Cell {...propsCell} />;
            } else {
                return (
                    <TableHeaderRow.Cell {...propsCell}>
                        <button
                            className={
                                userProfile?.columnsFix && userProfile.columnsFix.indexOf(propsCell.column.name) > -1
                                    ? "btn text-primary btn-link btn-sm"
                                    : "btn text-black-50 btn-link btn-sm"
                            }
                            onClick={() => {
                                let cols;
                                if (userProfile?.columnsFix && userProfile.columnsFix?.indexOf(propsCell.column.name) > -1) {
                                    cols = [...userProfile.columnsFix.filter((x) => x !== propsCell.column.name)];
                                } else {
                                    cols = [...(userProfile?.columnsFix || []), propsCell.column.name];
                                }
                                commitProfile({columnsFix: cols});
                            }}
                        >
                            <i className="fas fa-map-pin"/>
                        </button>
                        <div className="d-inline-block w-100 text-wrap">
                            {propsCell.children}
                        </div>
                    </TableHeaderRow.Cell>
                );
            }
        }, [userProfile, commitProfile])

        useEffect(() => {
            if (fonteDeTrabalho && !userProfile && columns && orderDefault) {
                setLoading(true);
                ApiService.get("/config-fonte", fonteDeTrabalho?.id).then((cc: any) => {
                    const profile = cc.configuracao || {};
                    setUserProfile({
                        columnsFix: FormularioPageHelpers.getColumnsFix(profile.columnsFix),
                        columnOrder: FormularioPageHelpers.getColumnOrders(
                            orderDefault,
                            profile.columnOrder
                        ),
                        columnWidths: columns.map((x) => {
                            const saveWith = profile.columnWidths?.find(
                                (z: any) => z.columnName === x.name
                            )?.width;
                            const width = !saveWith || saveWith < 150 ? 150 : saveWith;
                            return {
                                columnName: x.name,
                                width: width,
                            };
                        }),
                        hiddenColumnNames: profile.hiddenColumnNames || ([] as any)
                    })
                    setFetchState(x => ({
                        ...x,
                        pageSize: profile.pageSize || 20,
                        sorting: ((profile.sorting || []) as Sorting[])
                    } as any))
                    setLoading(false);
                });
            }
        }, [fonteDeTrabalho, userProfile, columns, orderDefault]);

        return <div className="ProjetoFormularioPage">
            <div className="card" style={{position: "relative"}}>
                <DataGrid
                    rows={rows} columns={columns} dateColumns={dateColumns} textColumns={textColumns}
                    multivalorColumns={multivalorColumns} numberColumns={numberColumns} currentPage={fetchState.currentPage}
                    pageSizes={fetchState.pageSizes} columnsFix={userProfile?.columnsFix}
                    pageSize={fetchState.pageSize} hiddenColumnNames={userProfile?.hiddenColumnNames}
                    totalCount={totalRegistros || 0} sorting={fetchState.sorting}
                    cell={Cell} selection={props.onSelectModel ? undefined : selection} headerCell={HeaderCellComponent}
                    toolbarHeader={props.onSelectModel ? undefined : LocalToolbarHeader}
                    columnWidths={userProfile?.columnWidths}
                    columnOrder={userProfile?.columnOrder}
                    columnExtensions={[
                        {columnName: "opcoes", togglingEnabled: false},
                    ]}
                    onCurrentPageChange={(currentPage) => setFetchState(x => ({...x, currentPage}))}
                    onPageSizeChange={(p) => {
                        setFetchState(x => ({...x, pageSize: p}));
                        commitProfile({pageSize: p});
                    }}
                    onFiltersChange={(filters) => setFetchState(x => ({...x, filters}))}
                    onSortingChange={handleSortingChange}
                    onSelectionChange={(sel) => handleSelectItems(sel as string[])}
                    onColumnWidthsChange={(cols) => commitProfile({columnWidths: cols})}
                    onOrderChange={(nextOrder) => commitProfile({columnOrder: nextOrder})}
                    onHiddenColumnNamesChange={(ch) => commitProfile({hiddenColumnNames: ch})}
                    tableFilterRow={props.multiValor ? FormularioPageHelpers.CellFilterRowSelect({
                        onSelectAll: () => {
                            props.onSelectModel?.(rows, columns)
                        }
                    }) : undefined}
                />
            </div>
            <p className="text-right">{totalRegistros || 0} registros.</p>
            {isFetching && <Loading mensagem={isString(loading) ? loading : undefined}/>}
            <ConfiguracaoColunas
                showConfVisualizacao={showConfVisualizacao}
                setShowConfVisualizacao={setShowConfVisualizacao}
                userProfile={userProfile}
                commitProfile={commitProfile} columns={columns}
            />
        </div>
    }
;

export default DataGridFonte;