import React from "react";
import AsyncSelect from "react-select/async";
import {ApiService} from "../services/ApiService";
import {DataPickerRangeFilter} from "./DatePickerRangeFilter";

export function FiltroDashboard(props: { onChange: (value: any) => void, nome: string, colunaAgrupadora: string, fonteId: string, tipo: string }) {

    const choices = (filter: string) => {
        const params = {
            agrupar: props.colunaAgrupadora,
            likeFilters: props.colunaAgrupadora,
            [props.colunaAgrupadora]: filter
        } as any;

        return ApiService.get("/fonte-de-dados/valores", props.fonteId, params).then(valores => {
            return valores.map((reg: any) => {
                return {
                    value: reg.id,
                    label: reg.id
                }
            })
        });
    }

    let component: JSX.Element;
    switch (props.tipo) {
        case "DATA":
        case "DATA_COM_HORARIO":
            component = <DataPickerRangeFilter
                onChange={(data) => {
                    if (data) {
                        props.onChange(`dateRange:${data.startDate ? data.startDate.toISOString() : "null"}#${data.endDate ? data.endDate.toISOString() : "null"}`)
                    } else {
                        props.onChange(null)
                    }
                }}/>;
            break;
        default:
            component = (<AsyncSelect
                isMulti={true}
                defaultOptions={true}
                isClearable={true}
                onChange={select => {
                    if (select) {
                        props.onChange("text:igu#" + select.map((x: any) => {
                            return x.value
                        }).join("|"))
                    } else {
                        props.onChange(null);
                    }
                }}
                loadOptions={choices}/>);
    }

    return <div className="form-group">
        <label>{props.nome}</label>
        {component}
    </div>;
}
