import {ButtonGroup, Modal, ToggleButton} from "react-bootstrap";
import React from "react";

const ConfiguracaoColunas = ({
                                 showConfVisualizacao, setShowConfVisualizacao, userProfile, commitProfile, columns
                             }: any) => {
    return <Modal
        show={showConfVisualizacao}
        size={"xl"}
        onHide={() => setShowConfVisualizacao(false)}
    >
        <Modal.Header closeButton>
            <Modal.Title>Configuração de Colunas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-md-4">
                    <button
                        className="btn btn-warning"
                        onClick={() => {
                            commitProfile({hiddenColumnNames: []});
                        }}
                    >
                        Mostrar todas
                    </button>
                    <button
                        className="btn btn-info"
                        onClick={() => {
                            commitProfile({
                                hiddenColumnNames:
                                    columns
                                        .filter((x: any) => x.name !== "opcoes")
                                        .map((x: any) => x.name)
                            });
                        }}
                    >
                        Ocultar todas
                    </button>
                </div>
            </div>
            <br/>
            <br/>
            {columns.map((x: any) => {
                const visivel = !userProfile?.hiddenColumnNames?.find(
                    (y: string) => y === x.name
                );
                return (
                    <div className="row" key={x.name}>
                        <div className="col-md-3">
                            <p>
                                <b>{x.title}</b>
                            </p>
                        </div>
                        <div className="col-md-9">
                            <ButtonGroup
                                toggle
                                onChange={() => {
                                    let v = (userProfile?.hiddenColumnNames || []);
                                    if (visivel) {
                                        v = [...v, x.name];
                                    } else {
                                        v = v.filter((v: string) => v !== x.name)
                                    }
                                    commitProfile({
                                        hiddenColumnNames: v
                                    })
                                }}
                            >
                                <ToggleButton
                                    disabled={x.name === "opcoes" || visivel}
                                    type="radio"
                                    variant={
                                        x.name === "opcoes" || visivel ? "primary" : "light"
                                    }
                                    name={x.name}
                                    value={true}
                                    checked={visivel}
                                >
                                    Mostrar
                                </ToggleButton>
                                <ToggleButton
                                    type="radio"
                                    variant={
                                        x.name === "opcoes" || !visivel ? "primary" : "light"
                                    }
                                    name={x.name}
                                    value={false}
                                    checked={!visivel}
                                    disabled={x.name === "opcoes" || !visivel}
                                >
                                    Ocultar
                                </ToggleButton>
                            </ButtonGroup>
                        </div>
                    </div>
                );
            })}
        </Modal.Body>
    </Modal>
};

export default ConfiguracaoColunas;