import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";
import {ICampoDaFonteDeDados, IFonteDeDados,} from "../models/FonteDeDados";
import {TipoDeCampoFonteDeDados,} from "./FormularioCampoFonteDados";
import {IProjeto} from "../models/Projeto";
import {ButtonEditarCampoFormulario} from "./ButtonEditarCampoFormulario";
import {ButtonNovoCampoFormulario} from "./ButtonNovoCampoFormulario";

export enum TipoDeFonteDeDados {
    ESTATICA = "Estática",
    FORMULARIO = "Formulário",
    ESPELHO = "Espelhamento",
}

const jsonFromsFonteData: IJsonFromField[] = [
    {
        name: "nome",
        label: "Nome",
        placeholder: "Nome da fonte de dados",
        type: "text",
        validators: {
            required: {
                value: true,
                message: "Nome é obrigatório",
            },
            maxLength: {
                value: 80,
                message: "Máximo de 80 caracteres",
            },
        },
    },
    {
        name: "descricao",
        label: "Descrição",
        placeholder: "Descrição da fonte de dados",
        type: "text-area",
        validators: {
            required: {
                value: true,
                message: "Descrição é obrigatório",
            },
            maxLength: {
                value: 1000,
                message: "Máximo de 1000 caracteres",
            },
        },
    },
    {
        name: "tipo",
        label: "Tipo da fonte de dados",
        placeholder: "Selecione o tipo da fonte de dados",
        type: "select",
        choices: Object.keys(TipoDeFonteDeDados).map((key) => {
            return {
                value: key,
                label:
                    TipoDeFonteDeDados[key as keyof typeof TipoDeFonteDeDados].toString(),
            };
        }),
        validators: {
            required: {
                value: true,
                message: "Tipo é obrigatório",
            },
            maxLength: {
                value: 1000,
                message: "Máximo de 1000 caracteres",
            },
        },
    },
];

export function FormularioFonteDados(props: {
    projeto: IProjeto;
    fontes: IFonteDeDados[];
    onSubmit: (
        fieldsValues: any,
        errorCallback?: (
            erros: { name: string; type: string; message: string }[]
        ) => void,
        formEl?: HTMLFormElement | null
    ) => void;
    onCancel: () => void;
    onDelete?: () => void;
    fonteParaEdicao?: IFonteDeDados;
    isLoading?: boolean;
}) {
    const [fields] = useState(
        jsonFromsFonteData.map((f) => {
            const field = {...f};
            if (props.fonteParaEdicao) {
                field.valueDefault = (props.fonteParaEdicao as any)[field.name];
                if (field.name === "tipo") {
                    field.disabled = true;
                    field.valueDefault = {
                        value: (props.fonteParaEdicao as any)[field.name],
                        label:
                            TipoDeFonteDeDados[
                                (props.fonteParaEdicao as any)[
                                    field.name
                                    ] as keyof typeof TipoDeFonteDeDados
                                ].toString(),
                    };
                }
            }
            return field;
        }) as IJsonFromField[]
    );

    const jsonEspelho = {
        name: "espelho",
        label: "Selecione uma fonte para ser espelhada",
        placeholder: "Qual fonte será espelhada",
        type: "select",
        choices: props.fontes
            .filter(
                (x) =>
                    x.id !== props.fonteParaEdicao?.id && String(x.tipo) !== "ESTATICA"
            )
            .map((x) => {
                return {
                    value: x.id,
                    label: x.nome,
                };
            }),
        validators: {
            required: {
                value: true,
                message: "Selecione uma fonte para ser espelhada",
            },
        },
        disabled: !!props.fonteParaEdicao,
        valueDefault: props.fonteParaEdicao
            ? {
                value: props.fonteParaEdicao.espelho,
                label: props.fontes.find(
                    (x) => x.id === props.fonteParaEdicao?.espelho
                )?.nome,
            }
            : undefined,
    } as IJsonFromField;
    const jsonAnexo = {
        name: "permiteAnexo",
        label: "Essa fonte permite anexar arquivo por linha?",
        placeholder: "Permitir Anexo?",
        type: "select",
        choices: [
            {
                value: "true",
                label: "Sim",
            },
            {
                value: "false",
                label: "Não",
            },
        ],
        validators: {
            required: {
                value: true,
                message: "obrigatório",
            },
        },
        valueDefault: props.fonteParaEdicao ? {
            value: props.fonteParaEdicao?.permiteAnexo
                ? "true"
                : "false",
            label: props.fonteParaEdicao?.permiteAnexo ? "Sim" : "Não",
        } : undefined,
    } as IJsonFromField;

    const defaultValues = fields.reduce((prev, pros) => {
        if (Array.isArray(pros.valueDefault)) {
            prev[pros.name] = prev.valueDefault.map((x: any) =>
                x.hasOwnProperty("value") ? x.value : x
            );
        } else {
            prev[pros.name] =
                pros.valueDefault && pros.valueDefault.hasOwnProperty("value")
                    ? pros.valueDefault.value
                    : pros.valueDefault;
        }
        return prev;
    }, {} as any);

    const {register, handleSubmit, watch, errors, setError, setValue} = useForm(
        {
            validateCriteriaMode: "all",
            defaultValues: props.fonteParaEdicao
                ? {
                    ...defaultValues,
                    permiteAnexo: jsonAnexo.valueDefault?.value,
                    espelho: jsonEspelho.valueDefault?.value,
                }
                : {},
        }
    );

    const [state, setState] = useState({
        dadosStaticos: props.fonteParaEdicao?.valoresEstaticos || ([] as any[]),
        campos:
            props.fonteParaEdicao?.campos.filter((x) => !x.espelho) ||
            ([] as ICampoDaFonteDeDados[]),
        errorCampos: "",
    });

    const watchTipo = watch("tipo");
    const tipo = watchTipo
        ? TipoDeFonteDeDados[watchTipo as keyof typeof TipoDeFonteDeDados]
        : watchTipo;
    const watchEspelho = watch("espelho", props.fonteParaEdicao?.espelho);
    let complemento: JSX.Element = <div/>;
    if (tipo && tipo === TipoDeFonteDeDados.ESTATICA) {
        complemento = (
            <>
                <div className="card mb-3">
                    <div className="card-header">Valores Estáticos</div>
                    <div className="card-body">
                        <ul className="list-group mb-3">
                            {state.dadosStaticos.length === 0 ? (
                                <li className="list-group-item">Nenhum valor adicionado</li>
                            ) : (
                                state.dadosStaticos.map((x, index) => {
                                    return (
                                        <li className="list-group-item p-0" key={index}>
                                            <div className="row m-0">
                                                <div className="col-10 p-2">
                                                    <input type="text" className="form-control" disabled={!x.id}
                                                           value={x.valor || ''} onChange={({target}) => {
                                                        setState((s) => {
                                                            let array = [...s.dadosStaticos]; // make a separate copy of the array
                                                            const index = array.findIndex(y => y.id === x.id);
                                                            if (index !== -1) {
                                                                array[index].valor = target.value;
                                                                return {
                                                                    dadosStaticos: array,
                                                                    campos: s.campos,
                                                                    errorCampos: s.errorCampos,
                                                                };
                                                            }
                                                            return s;
                                                        });
                                                    }}/>
                                                </div>
                                                <div className="col-2 text-right p-0">
                                                    <button
                                                        type="button"
                                                        className="btn btn-link text-danger"
                                                        onClick={() => {
                                                            setState((s) => {
                                                                let array = [...s.dadosStaticos]; // make a separate copy of the array
                                                                const index = array.findIndex(y => (y.id && x.id && y.id === x.id) || y.valor === x.value);
                                                                if (index !== -1) {
                                                                    array.splice(index, 1);
                                                                    return {
                                                                        dadosStaticos: array,
                                                                        campos: s.campos,
                                                                        errorCampos: s.errorCampos,
                                                                    };
                                                                }
                                                                return s;
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-trash"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            )}
                        </ul>
                        <FieldJsonForm
                            watch={watch}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            field={{
                                name: "valor-estatico",
                                label: "Insira um valor ou vários separados por vírgula.",
                                placeholder: "Valor item estático",
                                type: "text",
                            }}
                        />
                        <p className="text-right">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                    const valores = watch("valor-estatico", "");
                                    if (String(valores).trim()) {
                                        setState((s) => {
                                            const erros = [] as string[];
                                            const valoresInput = [...s.dadosStaticos] as {
                                                valor: string;
                                            }[];
                                            valores.split(",").forEach((valor: string) => {
                                                if (
                                                    valoresInput.findIndex(
                                                        (x) =>
                                                            String(x.valor).trim().toLowerCase() ===
                                                            String(valor).trim().toLowerCase()
                                                    ) > -1
                                                ) {
                                                    erros.push(
                                                        `"O valor "${valor}" já foi inserido, informe um valor diferente.`
                                                    );
                                                } else if (valor.length > 100) {
                                                    erros.push(
                                                        `"O valor "${valor}" deve ser menor que 100 caracteres.`
                                                    );
                                                } else {
                                                    valoresInput.push({valor: valor.trim()});
                                                }
                                            });
                                            if (erros.length > 0)
                                                setError("valor-estatico", erros.join(";"));
                                            setValue("valor-estatico", "");
                                            return {
                                                dadosStaticos: valoresInput,
                                                campos: s.campos,
                                                errorCampos: s.errorCampos,
                                            };
                                        });
                                    }
                                }}
                            >
                                Adicionar valor estático
                            </button>
                        </p>
                    </div>
                </div>
            </>
        );
    } else if (tipo) {
        complemento = (
            <>
                {(tipo !== TipoDeFonteDeDados.ESPELHO || watchEspelho) && (
                    <div className="card mb-3">
                        <div className="card-header">Campos/Colunas</div>
                        <div className="card-body">
                            <ul className="list-group mb-3">
                                {state.campos.length === 0 ? (
                                    <li className={"list-group-item"}>
                                        Nenhum campo/coluna adicionado
                                    </li>
                                ) : (
                                    state.campos.map((x, index) => {
                                        return (
                                            <li className={"list-group-item p-0"} key={index}>
                                                <div className="row m-0">
                                                    <div className="col-auto text-left p-2">
                                                        {x.id && props.fonteParaEdicao && (
                                                            <ButtonEditarCampoFormulario
                                                                fontes={props.fontes}
                                                                registroParaEdicao={{...x} as any}
                                                                onSave={(data) => {
                                                                    setState((s) => {
                                                                        const campos = [...s.campos];
                                                                        const idx = campos.findIndex(
                                                                            (x) => x.id === data.id
                                                                        );
                                                                        campos[idx] = data;
                                                                        return {
                                                                            dadosStaticos: s.dadosStaticos,
                                                                            campos: campos,
                                                                            errorCampos: "",
                                                                        };
                                                                    });
                                                                }}
                                                                fonteDeTrabalho={props.fonteParaEdicao}
                                                                onCancel={() => {
                                                                }}
                                                            />
                                                        )}
                                                        <button
                                                            type="button"
                                                            className="btn btn-link text-danger"
                                                            onClick={() => {
                                                                if (x.podeRemover || !x.id) {
                                                                    setState((s) => {
                                                                        let array = [...s.campos]; // make a separate copy of the array
                                                                        const index = array.indexOf(x);
                                                                        if (index !== -1) {
                                                                            array.splice(index, 1);
                                                                            return {
                                                                                dadosStaticos: s.dadosStaticos,
                                                                                campos: array,
                                                                                errorCampos: s.errorCampos,
                                                                            };
                                                                        }
                                                                        return s;
                                                                    });
                                                                } else {
                                                                    window.alert("Essa coluna possuí relacionamento com outra tabela e não pode ser removida.")
                                                                }
                                                            }}
                                                        >
                                                            <i className="fas fa-trash"/>
                                                        </button>
                                                    </div>
                                                    <div className="col-auto p-2">
                                                        {x.nome} -{" "}
                                                        {
                                                            TipoDeCampoFonteDeDados[
                                                                String(
                                                                    x.tipo
                                                                ) as keyof typeof TipoDeCampoFonteDeDados
                                                                ]
                                                        }{" "}
                                                        {x.validacoes.required && "| obrigatório"}
                                                        <br/>
                                                        <small>{x.descricao}</small>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                )}
                            </ul>
                            <div>
                                {state.errorCampos ? (
                                    <div className="alert alert-danger">{state.errorCampos}</div>
                                ) : (
                                    ""
                                )}
                                <ButtonNovoCampoFormulario
                                    fontes={props.fontes.filter(
                                        (x) =>
                                            tipo !== TipoDeFonteDeDados.ESPELHO ||
                                            (x.id !== watchEspelho &&
                                                x.espelho !== watchEspelho)
                                    )}
                                    onSave={(data) => {
                                        setState((s) => {
                                            return {
                                                dadosStaticos: s.dadosStaticos,
                                                campos: [...s.campos, data],
                                                errorCampos: "",
                                            };
                                        });
                                    }}
                                    onCancel={() => {
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    let submitForm = handleSubmit((data) => {
        if (props.onSubmit) {
            const fields = {...data};
            if (
                TipoDeFonteDeDados[data.tipo as keyof typeof TipoDeFonteDeDados] ===
                TipoDeFonteDeDados.ESTATICA
            ) {
                fields.valoresEstaticos = [...state.dadosStaticos];
                if (!fields.valoresEstaticos || fields.valoresEstaticos.length === 0) {
                    return setError([
                        {
                            name: "valor-estatico",
                            type: "requires",
                            message: "É necessário inserir ao menos um valor",
                        },
                    ]);
                } else {
                    props.onSubmit(fields, (erros: any) => {
                        if (erros) {
                            setError(erros);
                        }
                    });
                }
            } else {
                fields.campos = state.campos;
                if (!fields.campos || fields.campos.length === 0) {
                    return setState((s) => {
                        return {
                            dadosStaticos: s.dadosStaticos,
                            campos: s.campos,
                            errorCampos: "É necessário inserir ao menos um campo/coluna",
                        };
                    });
                } else {
                    props.onSubmit(fields, (erros: any) => {
                        if (erros) {
                            setError(erros);
                        }
                    });
                }
            }
        }
    });

    const removerFonte = () => {
        if (
            window.confirm("Deseja realmente REMOVER essa fonte?") &&
            window.confirm(
                "Essa operação não poderá ser desfeita e todos os dados da fonte serão apagados e não poderão ser recuperados. Deseja realmente continuar e remover essa fonte?"
            )
        ) {
            props.onDelete?.();
        }
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p>
                    <b>
                        {props.fonteParaEdicao
                            ? "Editando fonte de dados"
                            : "Adicionando fonte de dados"}
                    </b>
                </p>
                <p>
                    <b>
                        {props.fonteParaEdicao && (
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => removerFonte()}
                            >
                                {" "}
                                <i className="fas fa-trash"/> Remover Fonte
                            </button>
                        )}
                    </b>
                </p>
            </div>
            <form onSubmit={submitForm}>
                {fields.map((field) => {
                    return (
                        <FieldJsonForm
                            watch={watch}
                            field={field}
                            errors={errors}
                            register={register}
                            key={field.name}
                            setValue={setValue}
                        />
                    );
                })}
                {tipo && tipo !== TipoDeFonteDeDados.ESTATICA && (
                    <FieldJsonForm
                        watch={watch}
                        field={jsonAnexo}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                    />
                )}
                {tipo && tipo === TipoDeFonteDeDados.ESPELHO && (
                    <FieldJsonForm
                        watch={watch}
                        field={jsonEspelho}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                    />
                )}
            </form>
            {complemento}
            <div className="row">
                <div className="col-6">
                    <p className="text-left">
                        <button
                            disabled={props.isLoading}
                            className="btn btn-warning"
                            type="button"
                            onClick={() => {
                                props.onCancel();
                            }}
                        >
                            Cancelar
                        </button>
                    </p>
                </div>
                <div className="col-6">
                    <p className="text-right">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                submitForm();
                            }}
                            disabled={props.isLoading}
                        >
                            {props.isLoading && <i className="fa fa-spinner fa-pulse"/>}
                            {props.fonteParaEdicao
                                ? "Salvar alterações"
                                : "Adicionar fonte de dados"}
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}
