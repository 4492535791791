import {Table} from "@devexpress/dx-react-grid-bootstrap4";
import {ButtonNovoItemFormulario} from "./ButtonNovoItemFormulario";
import {ButtonArquivosFonte} from "./ButtonArquivosFonte";
import {MascarasHelpers} from "../helpers/MascarasHelpers";
import React from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import moment from "moment";

type Props = {
    fonteDeTrabalho?: IFonteDeDados,
    usuarioPodeInserir?: boolean,
    usuarioPodeEditar?: boolean,
    usuarioPodeExcluir?: boolean,
    fontes: IFonteDeDados[],
    onLoadData: () => void,
    onDelete: (data: any) => void,
    multiValor?: boolean,
    onSelect?: (selected: { label: string, value: string }, columns: any) => void,
    onRemove?: (id: string) => void,
    valores: string[],
    columns: any[],
} & Table.DataCellProps;
const TabCell = (props: Props) => {
    if (props.column.name === "opcoes") {
        if (props.onSelect) {
            const selecionado = props.valores?.some(x => x === props.row.id);
            return (<Table.Cell {...(props as Table.DataCellProps)}>
                <button type="button" className={(selecionado ? "btn-secondary" : "btn-primary") + " btn btn-sm"}
                        onClick={() => {
                            if (selecionado) {
                                props.onRemove?.(props.row.id);
                            } else {
                                props.onSelect?.(props.row, props.columns);
                            }
                        }}>
                    <i className="oi oi-check"/> {selecionado ? "Selecionado" : "Selecionar"}
                </button>
            </Table.Cell>);
        } else {
            return (
                <Table.Cell {...(props as Table.DataCellProps)}>
                    {props.fonteDeTrabalho && props.usuarioPodeEditar && (
                        <ButtonNovoItemFormulario
                            idRegistroParaEdicao={props.row.id}
                            fontes={props.fontes}
                            fonteDeTrabalho={props.fonteDeTrabalho}
                            onSave={() => {
                                props.onLoadData();
                            }}
                            onCancel={() => {
                            }}
                        />
                    )}

                    {props.fonteDeTrabalho && props.usuarioPodeExcluir && !props.fonteDeTrabalho.espelho && (
                        <button
                            className={"btn btn-link text-danger  btn-sm"}
                            onClick={() => {
                                props.onDelete(props.row);
                            }}
                        >
                            <i className="oi oi-trash"/>
                        </button>
                    )}
                    {props.fonteDeTrabalho && props.fonteDeTrabalho.permiteAnexo && (
                        <ButtonArquivosFonte
                            fonte={props.fonteDeTrabalho}
                            registroId={props.row.id}
                            usuarioPodeExcluir={props.usuarioPodeExcluir}
                            usuarioPodeInserir={props.usuarioPodeInserir}
                        />
                    )}
                </Table.Cell>
            );
        }
    }
    const campo = props.fonteDeTrabalho?.campos.find(
        (c) =>
            c.id === props.column.name ||
            !!c.fonte?.campos.find((ce) => ce.key === props.column.name)
    );
    const exCampo =
        campo?.id === props.column.name
            ? campo
            : campo?.fonte?.campos.find((ce) => ce.key === props.column.name);
    const tipo = exCampo?.tipo;
    const mascara = exCampo?.tipoMascara;
    let valor;
    if (props.value && tipo?.toString() === "DATA") {
        valor = moment(props.value).format("DD/MM/YYYY");
    } else if (props.value && tipo?.toString() === "DATA_COM_HORARIO") {
        valor = moment(props.value).format("DD/MM/YYYY HH:mm:ss");
    } else {
        valor = MascarasHelpers.aplicarMascaraEmValor(
            mascara || "",
            props.value
        );
    }
    return <Table.Cell {...(props as Table.DataCellProps)} value={valor}/>;
};

export default TabCell;