import React, {PropsWithChildren} from "react";
import {IProjeto} from "../models/Projeto";
import "../css/sidebar.scss"

export function LayoutProjeto(props: PropsWithChildren<{ projeto: IProjeto, isHome?: boolean, titulo: string, administrador: boolean, toggleMenu:boolean, headlerToggleMenu: (t:boolean) => void}>) {
    return (
        <>
            <div className="card min-vh-100 mt-1">
                <div className="card-header p-2">
                    <div className="row">
                        <div className="col-4">
                            {!props.toggleMenu && <button className="navbar-brand btn btn-link text-dark"
                                                onClick={() => props.headlerToggleMenu(!props.toggleMenu)}>
                                <i
                                    className="oi oi-menu"/></button>}
                        </div>
                        <div className="col-4 text-center">
                            {props.titulo && <><p className="pt-2 mb-0"><b>{props.titulo}</b></p></>}
                        </div>
                        <div className="col-4 text-right">

                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {props.children}
                </div>
            </div>
        </>
    )
}
