import {DataTypeProvider} from "@devexpress/dx-react-grid";
import {DataPickerRangeFilter} from "./DatePickerRangeFilter";
import React from "react";

const DataFilter = (props: DataTypeProvider.ValueEditorProps) => {
    const [startDate, endDate] = props.value
        ? props.value.replace("dateRange:", "").split("#")
        : [];
    return (
        <DataPickerRangeFilter
            defaultValue={{
                startDate: startDate === "null" ? null : startDate,
                endDate: endDate === "null" ? null : endDate,
            }}
            onChange={(data) =>
                data
                    ? props.onValueChange(
                        `dateRange:${
                            data.startDate ? data.startDate.toISOString() : "null"
                        }#${data.endDate ? data.endDate.toISOString() : "null"}`
                    )
                    : props.onValueChange(null)
            }
        />
    );
};

export default DataFilter;