import {Action, Reducer} from 'redux'
import {IUsuario} from "../models/Usuario";
import {ACTION_TYPES_SESSAO} from "../actions/ActionTypes";

export interface ActionSessao extends Action<ACTION_TYPES_SESSAO> {
    usuario?: IUsuario
}

export interface SessaoState {
    usuario?: IUsuario
}

export const sessaoReducer: Reducer<SessaoState, ActionSessao> = function (state = {}, action) {
    switch (action.type) {
        case "LOGOUT":
            return {
                usuario: undefined
            } as SessaoState;
        case "LOGIN":
            return {
                usuario: action.usuario
            };
        default:
            return state || {
                usuario: undefined
            };
    }
};
