import React, {useState} from "react";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {FiltroDashboard} from "../../componets/FiltroDashboard";
import {CardDashboard} from "../../componets/CardDashboard";
import {CampoHelpers, MetaDataColuna} from "../../helpers/CampoHelpers";
import {GraficoPizza} from "../../componets/GraficoPizzaDashboard";
import {GraficoBarrasDashboard} from "../../componets/GraficoBarrasDashboard";

export function ProjetoDashboard(props: PropsProjetoPages & { dashboardId: string }) {
    const dashboard = props.dashboards.find(x => x.id === props.dashboardId);
    const [filtro, setFiltro] = useState<{ [fonte: string]: { [campo: string]: any } }>({});
    const aplicarFiltro = (fonte: string, campo: string, valor: any) => {
        setFiltro(s => {
            const f = {...s};
            const valoresFonte = f[fonte] || {};
            if (!valor) {
                delete valoresFonte[campo];
            } else {
                valoresFonte[campo] = valor
            }
            f[fonte] = valoresFonte;
            return f;
        })
    }
    return (
        <LayoutProjeto administrador={props.administrador} projeto={props.projeto}
                       titulo={dashboard?.nome || ""}
                       toggleMenu={props.toggleMenu}
                       headlerToggleMenu={props.headlerToggleMenu}
                       isHome={true}>
            {dashboard && dashboard.descricao && <p className='text-center'>
                {dashboard.descricao}
                <hr/>
            </p>}
            {dashboard && (!dashboard.tipo || dashboard.tipo === "DYDATA") && <div className="ProjetoHomePage">
                {dashboard.filtros && dashboard.filtros.length > 0 && <><p><b>Filtros:</b></p>
                    <div className='row'>
                        {dashboard.filtros?.map(x => {
                            const metaDatas = props.fontes.find(f => f.id === x.fonteId)?.campos.reduce((previousValue, currentValue) => {
                                return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
                            }, {} as { [key: string]: MetaDataColuna }) || {};
                            return <div className='col-md-3'>
                                <FiltroDashboard colunaAgrupadora={x.campo} fonteId={x.fonteId.toString()}
                                                 onChange={value => {
                                                     aplicarFiltro(x.fonteId.toString(), x.campo, value);
                                                 }} nome={x.titulo} key={x.campo}
                                                 tipo={metaDatas[x.campo].tipo}/>
                            </div>
                        })}
                    </div>
                    <hr/>
                </>}
                <div className='row'>
                    {dashboard.componentes?.filter(x => x.tipo === "CARD").map(x => {
                        return <div className="col-md-2 mx-auto mt-2">
                            <CardDashboard dashboard={dashboard} componente={x} filtros={filtro[x.fonteId.toString()]}
                                           key={x.id}/>
                        </div>
                    })}
                    <hr/>
                </div>
                <div className='row'>
                    {dashboard.componentes?.filter(x => x.tipo !== "CARD").map(x => {
                        return <div className="col-md-6 mx-auto mt-2">
                            {x.tipo === "PIZZA" && <GraficoPizza dashboard={dashboard} componente={x}/>}
                            {x.tipo === "BARRA" && <GraficoBarrasDashboard dashboard={dashboard} componente={x}/>}
                        </div>
                    })}
                </div>
            </div>}
            {dashboard && dashboard.tipo === "POWERBI" && <div className="table-responsive">
                <iframe style={{
                    height: "100vh",
                    minWidth: "800px",
                    width: "100%"
                }} src={dashboard.linkPowerBi}  frameBorder="0" allowFullScreen title="powerbi" />
            </div>}
        </LayoutProjeto>
    );
}
