import {useEffect, useState} from "react";

export const useDebouncedState = (initialValue: string | null, delay = 1000): any[] => {
    const [state, setState] = useState<string | null>(initialValue);
    const [nextState, setNextState] = useState<string | null>(initialValue);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (nextState !== state) {
                setState(nextState);
            }
        }, delay);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextState]);
    const setter = (text:string) =>{
        setNextState(text)
    }
    return [state, setter, nextState];
};