import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {IJsonFromField} from "./FieldJsonForm";
import {JsonForm} from "./JsonForm";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {setFonte} from "../actions/ProjetoActions";
import {useDispatch} from "react-redux";


export function ButtonAddFonteAoUsuario(props: { onAdd: (usuarioId: string) => void, projeto: IProjeto, fontes: IFonteDeDados[] }) {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const jsonField: IJsonFromField[] = [{
        name: 'usuarioId',
        label: "Selecione qual usuário deseja inserir",
        placeholder: "Qual usuário deseja inserir?",
        type: "select",
        choices: props.projeto.usuarios.map(x => {
            return {
                value: x.id,
                label: x.nome
            }
        }),
        validators: {
            required: true
        }
    },
        {
            name: 'fonte',
            label: "Selecione a fonte",
            type: "select",
            choices: props.fontes.map(x => {
                return {
                    value: x.id,
                    label: x.nome
                }
            }),
            validators: {
                required: true
            }
        },
        {
            name: 'permissoes',
            label: "Quais as pemissões?",
            type: "multi-select",
            choices: [
                {
                    value: "insersao",
                    label: "Permitir insersão de registros"
                },
                {
                    value: "exclusao",
                    label: "Permitir exclusão de registros"
                },
                {
                    value: "edicao",
                    label: "Permitir edição de registros"
                }
            ]
        }
    ];

    return (<div className="d-inline-block">
        <button className="btn btn-warning" onClick={() => setShow(true)}><i
            className={"fa fa-plus"}/> Adicionar permissões ao usuário
        </button>
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Permissões</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <JsonForm name={"edicaoEmMassa"} fields={jsonField} onSubmit={(data) => {
                    setShow(false);
                    ApiService.post("/fonte-de-dados/add-usuario-fonte/" + props.projeto.id, data).then(data => {
                        setLoading(false);
                        dispatch(setFonte(data, props.projeto.id));
                        props.onAdd(data['usuario']);
                    }, () => {
                        setLoading(false);
                    })
                }} textSubmitButton="Adicionar" loading={loading}>
                    <button type="button" className="btn btn-warning" disabled={loading} onClick={() => {
                        setShow(false);
                    }}>Cancelar
                    </button>
                </JsonForm>
            </Modal.Body>
        </Modal>
    </div>);
}
