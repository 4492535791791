import React, {useMemo,} from "react";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import DataGridFonte from "../../componets/DataGridFonte";

export function ProjetoFormularioPage(
    props: PropsProjetoPages & { fonteId: string, configuracaoUsuario?: any }
) {

    const fonteDeTrabalho = useMemo(() => props.fontes.find(
        (x) => x.id === props.fonteId
    ), [props.fontes, props.fonteId]);

    return (
        <LayoutProjeto
            projeto={props.projeto}
            toggleMenu={props.toggleMenu}
            headlerToggleMenu={props.headlerToggleMenu}
            titulo={fonteDeTrabalho?.nome || ""}
            administrador={props.administrador}
        >
            <DataGridFonte
                fonteDeTrabalho={fonteDeTrabalho!}
                fontes={props.fontes}
                administrador={props.administrador}
                usuario={props.usuario}
            />
        </LayoutProjeto>
    );
}
