import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {FieldJsonForm, IJsonFromField,} from "./FieldJsonForm";
import {ICampoDaFonteDeDados, ICampoRelacionamento, IFonteDeDados} from "../models/FonteDeDados";
import {ApiService} from "../services/ApiService";
import {CampoHelpers} from "../helpers/CampoHelpers";
import {IRegistro} from "../models/Registro";
import {FiltroColunaDeRelacionamento} from "./FiltroColunaDeRelacionamento";
import {isArray} from "lodash";
import useEventos from "../hooks/useEventos";
import {AlertEvent, AlertEventType} from "../events/AppEvent";
import {FiltroColunaAgrupamento} from "./FiltroColunaAgrupamento";


function complementarFiledParaEdicao(campo: ICampoDaFonteDeDados, field: IJsonFromField, fonteDeTrabalho: IFonteDeDados, fontes: IFonteDeDados[], registroParaEdicao: IRegistro) {

    if (String(campo.tipo) === "LISTA") {
        if (campo.multivalor && registroParaEdicao[campo.id]) {
            if (campo.fonte?.campos.length) {
                field.valueDefault = registroParaEdicao[campo.id].map((valorCampo: any) => {
                    let nomes = [] as string[];
                    campo.fonte?.campos.forEach((x: any) => {
                        let label = String(valorCampo[x.id]) === "undefined" ? "-" : valorCampo[x.id];
                        if (isArray(label)) {
                            label = label.join(", ");
                        }
                        nomes.push(`${x.nome}: ${(label ?? "-")}`);
                    });
                    return {value: valorCampo.id, label: nomes.join(" | ")}
                });
            } else {
                field.valueDefault = registroParaEdicao[campo.id].map((x: any) => ({value: x.id, label: x.valor}))
            }
        } else if (registroParaEdicao[campo.id]) {
            field.valueDefault = {value: registroParaEdicao[campo.id + "Id"], label: registroParaEdicao[campo.id]};
        } else {
            if (registroParaEdicao[campo.id + "Id"]) {
                let nomes = [] as string[];
                campo.fonte?.campos.forEach((x: any) => {
                    const valorCampo = registroParaEdicao[x.id];
                    let label = String(valorCampo) === "undefined" ? "-" : valorCampo;
                    if (isArray(label)) {
                        label = label.join(", ");
                    }
                    nomes.push(`${x.nome}: ${(label ?? "-")}`);
                });
                field.valueDefault = {value: registroParaEdicao[campo.id + "Id"], label: nomes.join(" | ")};
            }
        }
    } else {
        field.valueDefault = registroParaEdicao[field.name];
    }
    field.disabled = campo && !!campo.espelho
    return field;
}

function obterConfFormulario(fonteDeTrabalho: IFonteDeDados, fontes: IFonteDeDados[], parcial?: string[], registroParaEdicao?: IRegistro, fieldsExtraAgrupamento?: any): IJsonFromField[] {
    const lista = [] as IJsonFromField[];
    const idInseridos = [] as string[];

    fonteDeTrabalho?.ordenacaoCampos?.forEach((campoOrdenado) => {
        const campo = fonteDeTrabalho.campos.filter(x => (!!registroParaEdicao || x.tipo.toString() !== "NSU") && (!parcial || parcial.indexOf(x.id) > -1)).find(x => {
            return x.id === campoOrdenado || !!x.fonte?.campos.find(y => y.key === campoOrdenado)
        });
        if (!!idInseridos.find(y => y === campo?.id?.toString())) {
            return;
        }
        if (campo && String(campo.tipo) === "AGRUPAMENTO") {
            idInseridos.push(campo.id);
            campo.fonte?.campos.filter(x => x.tipoAgrupamento === "AGRUPAMENTO").forEach((x, i) => {
                const valor = registroParaEdicao?.[x.key];
                const fonte = fontes.find(x => x.id === campo.fonte?.id);
                let keyAgrupado = x.keyOrigem;
                if (fonte && String(fonte.tipo) === "ESTATICA") {
                    keyAgrupado = x.keyOrigem
                } else if (fonte && String(fonte.tipo) === "FORMULARIO") {
                    const campo = fonte.campos.find(c => c.id === x.keyOrigem);
                    const cc = campo?.fonte?.campos.find(cxc => cxc.key === x.key);
                    if (String(campo?.tipo) === "LISTA" && !cc) {
                        keyAgrupado = String(campo?.id)
                    }
                }
                const fieldExtra = fieldsExtraAgrupamento?.[x.key] ? fieldsExtraAgrupamento[x.key] : {};
                lista.push({
                    name: x.key,
                    label: x.nome,
                    type: x.multivalor ? "multi-select" : "select",
                    choices: i === 0 ? CampoHelpers.obterChoicesCampoAgrupado(campo, fontes, keyAgrupado) : [],
                    valueDefault: valor && Array.isArray(valor) ? valor.filter(Boolean).map(x => {
                        return {label: x, value: x}
                    }) : valor ? {label: valor, value: valor} : undefined,
                    disabled: campo && !!campo.espelho,
                    extraFilter: i === 0 ? (onSelected, onHide, valores) => <FiltroColunaAgrupamento
                        campo={campo}
                        fontes={fontes}
                        chaveAgrupamento={x.keyOrigem}
                        onSelect={onSelected}
                        onHide={onHide}
                        multivalor={x.multivalor}
                        valores={valores}
                    /> : undefined,
                    ...fieldExtra
                })
            });
            campo.fonte?.campos.filter(x => x.tipoAgrupamento === "RESULTADO").forEach(x => {
                const valor = registroParaEdicao?.[x.key]
                lista.push({
                    name: x.key,
                    label: x.nome,
                    type: "text",
                    readonly: true,
                    validators: {
                        required: true
                    },
                    valueDefault: valor || 0,
                    disabled: campo && !!campo.espelho
                })
            });

        } else if (campo) {
            idInseridos.push(campo.id);
            const field = {
                name: campo?.id?.toString() || "",
                label: campo.nome,
                helpText: campo.descricao,
                type: CampoHelpers.obterTipoCampo(campo),
                choices: CampoHelpers.obterChoicesCampo(campo, fontes),
                readonly: !!campo.metaDataFuncao || campo.tipo.toString() === "NSU",
            } as IJsonFromField;
            if (registroParaEdicao) {
                lista.push(complementarFiledParaEdicao(campo, field, fonteDeTrabalho, fontes, registroParaEdicao));
            } else {
                lista.push(field)
            }
        }
    })
    return lista
}

export function FormInputFonteDedados(props: PropsWithChildren<{
    fonteDeTrabalho: IFonteDeDados,
    fontes: IFonteDeDados[],
    onSubmit: (data: any) => void,
    registroParaEdicao?: IRegistro,
    parcial?: string[];
    idsEdicaoMassa?: string[]
}>) {

    const [funcoesEmProcessamento, setFuncoesEmProcessamento] = useState([] as string[]);
    const eventos = useEventos(props.fonteDeTrabalho);
    const [loading, setLoading] = useState(false);
    const [valoresAgrupados, setValoresAgrupados] = useState({} as any)
    const [fieldsExtraAgrupamento, setFieldsExtraAgrupamento] = useState({} as any)

    const fields = useMemo(() => obterConfFormulario(props.fonteDeTrabalho, props.fontes, props.parcial, props.registroParaEdicao, fieldsExtraAgrupamento), [props.fonteDeTrabalho, props.fontes, props.parcial, props.registroParaEdicao, fieldsExtraAgrupamento]);
    const defaultValues = useMemo(() => fields.reduce((prev, pros) => {
        if (pros.valueDefault || pros.valueDefault === 0) {
            if (Array.isArray(pros.valueDefault)) {
                prev[pros.name] = pros.valueDefault.filter(Boolean).map((x: any) => x?.value ? x.value : x)
            } else {
                prev[pros.name] = pros.valueDefault['value'] ? pros.valueDefault.value : pros.valueDefault
            }
        }
        return prev;
    }, {} as any), [fields]);
    const {register, handleSubmit, watch, errors, setError, setValue, getValues} = useForm(
        {
            validateCriteriaMode: "all",
            defaultValues: defaultValues
        }
    );

    const eventosFiltrados = useMemo(() => eventos.filter(x => props.registroParaEdicao ? x.onUpdate : x.onCreate), [eventos, props.registroParaEdicao])

    const camposAgrupamento: ICampoDaFonteDeDados[] = useMemo(() => {
        return props.fonteDeTrabalho.campos.filter(x => String(x.tipo) === "AGRUPAMENTO");
    }, [props.fonteDeTrabalho.campos]);

    const atualizarValorResultado = useCallback((camposResultado: ICampoRelacionamento[], filtros, fonteCampoId) => {
        const params = {} as any;
        params['filters'] = Object.keys(filtros).filter(x => {
            return filtros[x] && Array.isArray(filtros[x]) ? filtros[x].length > 0 : !!filtros[x]
        });
        Object.keys(filtros).forEach(key => {
            const v = filtros[key] && Array.isArray(filtros[key]) ? filtros[key].length > 0 : !!filtros[key];
            if (v)
                params[key] = filtros[key]
        });
        camposResultado.forEach(agr => {
            const p = {...params};
            p['tipoResultado'] = agr.tipoResultado
            p['resultado'] = agr.keyOrigem
            if (p['filters'] && p['filters'].length > 0) {
                ApiService.get("/fonte-de-dados/valores", fonteCampoId, p).then(valores => {
                    setValue(agr.key, valores);
                });
            } else {
                setValue(agr.key, 0);
            }
        });
    }, [setValue]);

    const atualizarChoicesAgrupamento = useCallback((campo: ICampoDaFonteDeDados) => {
        const camposResultado = campo.fonte?.campos.filter(x => String(x.tipoAgrupamento) === "AGRUPAMENTO")!;
        camposResultado.forEach((agr, iagr) => {
            if (iagr > 0) {
                const extra: any = {
                    readonly: !!props.registroParaEdicao,
                };
                const key = camposResultado[iagr - 1].key || "";
                const watchAnterior = watch(key);
                if (watchAnterior) {
                    extra['visivel'] = true;
                    extra['choices'] = (filterSelec: any) => {
                        const query = {} as any;
                        if (filterSelec)
                            query[agr.keyOrigem] = filterSelec
                        query[campo.fonte?.campos[iagr - 1].keyOrigem || ""] = watchAnterior
                        return CampoHelpers.obterValoresFonteDinamicaParaCampo(campo, query, agr.keyOrigem)
                    }
                    extra['key'] = watchAnterior
                    extra['extraFilter'] = (onSelected: any, onHide: any, valores: any) => <FiltroColunaAgrupamento
                        campo={campo}
                        fontes={props.fontes}
                        chaveAgrupamento={agr.keyOrigem}
                        onSelect={onSelected}
                        onHide={onHide}
                        valores={valores}
                        multivalor={agr.multivalor}
                        filtros={[{key: campo.fonte?.campos[iagr - 1].keyOrigem || "", value: watchAnterior}]}
                    />;
                } else {
                    extra['choices'] = [];
                }
                setFieldsExtraAgrupamento((x: any) => ({...x, [agr.key]: extra}))
            }
        });
    }, [props.registroParaEdicao, watch, props.fontes]);


    useEffect(() => {
        camposAgrupamento.forEach(campoAgrupamento => {
            const camposRelacionamentos = campoAgrupamento.fonte?.campos!
            const camposRelacionamentosAgrupamento = camposRelacionamentos.filter(x => String(x.tipoAgrupamento) === "AGRUPAMENTO");
            const alterou = camposRelacionamentosAgrupamento.some(campo => {
                const valorWatch = watch(campo.key);
                const alterado = (valorWatch || valoresAgrupados[campo.key]) && valoresAgrupados[campo.key] !== valorWatch;
                if (alterado) {
                    setValoresAgrupados((x: any) => ({...x, [campo.key]: valorWatch}));
                }
                return alterado;
            });
            if (alterou) {
                const filtros = camposRelacionamentosAgrupamento.reduce((previousValue, currentValue) => {
                    previousValue[currentValue.keyOrigem] = watch(currentValue.key)
                    return previousValue;
                }, {} as any);
                atualizarValorResultado(camposRelacionamentos.filter(x => String(x.tipoAgrupamento) === "RESULTADO"), filtros, campoAgrupamento.fonte?.id!);
                atualizarChoicesAgrupamento(campoAgrupamento);
            }
        });
        // eslint-disable-next-line
    }, [...camposAgrupamento.reduce((previousValue, currentValue) => {
        const valores = currentValue.fonte?.campos.filter(x => String(x.tipoAgrupamento) === "AGRUPAMENTO").map(x => watch(x.key));
        previousValue.push(...(valores || []))
        return previousValue;
    }, [] as any[]), atualizarValorResultado, camposAgrupamento, valoresAgrupados, watch])

    let formElement: HTMLFormElement | null;
    return (
        <form name={"FormInput"} ref={(el) => {
            formElement = el
        }} onSubmit={handleSubmit(async (data) => {
            setLoading(true);
            if (props.idsEdicaoMassa) {
                ApiService.put("/fonte-de-dados/valores/edicao-em-massa", props.fonteDeTrabalho.id, {
                    ...Object.keys(data).reduce((previousValue, currentValue) => {
                        if (previousValue[currentValue] === undefined) {
                            previousValue[currentValue] = null;
                        }
                        return previousValue;
                    }, data),
                    id: props.idsEdicaoMassa
                }).then(valor => {
                    if (formElement) {
                        formElement.reset();
                    }
                    props.onSubmit(valor);
                    setLoading(false);
                }, (error) => {
                    const errors = error.data?.errors;
                    if (error.status === 400 && errors) {
                        setError(Object.keys(errors).map(key => {
                            return {
                                name: key,
                                type: "ValidationError" + key,
                                message: String(errors[key])
                            }
                        }));
                    }
                    setLoading(false);
                });
            } else {
                const linha = {} as any;
                for (let i = 0; i < props.fonteDeTrabalho.campos.length; i++) {
                    const campo = props.fonteDeTrabalho.campos[i];
                    linha[campo.nome] = await CampoHelpers.obterCampoParaFuncao(props.fontes, campo, data);
                }
                let funcComErros = false;
                for (const cFdT of props.fonteDeTrabalho.campos.filter(x => !x.espelho)) {
                    if (!!cFdT.metaDataFuncao) {
                        try {
                            data[cFdT.id] = await CampoHelpers.processarFuncao(props.fontes, cFdT.metaDataFuncao, linha);
                            linha[cFdT.nome] = data[cFdT.id];
                        } catch (e) {
                            setError(cFdT.id, "validation", "Falha ao processar função!");
                            funcComErros = true;
                        }
                    }
                }

                const processarFuncoesDeEvento = async (idNovoRegistro: string) => {
                    for (const evento of eventosFiltrados) {
                        try {
                            await CampoHelpers.processarFuncao(props.fontes, evento.metaDataFuncao, {
                                ...linha,
                                id: idNovoRegistro
                            })
                        } catch (e) {
                            AlertEvent.emit(AlertEventType.DANGER, `Falha ao processar função do evento ${evento.nome}`);
                        }
                    }
                };

                const dataToRequest = props.fonteDeTrabalho.campos.filter(x => !x.espelho).reduce((previousValue, currentValue) => {
                    if (String(currentValue.tipo) === "AGRUPAMENTO") {
                        currentValue.fonte?.campos.forEach(x => {
                            previousValue[x.id] = data[x.id]
                        });
                    } else {
                        previousValue[currentValue.id] = data[currentValue.id]
                    }
                    return previousValue;
                }, {} as any)

                if (!funcComErros) {
                    if (props.registroParaEdicao) {
                        ApiService.put("/fonte-de-dados/valores/" + props.fonteDeTrabalho.id, props.registroParaEdicao.id, dataToRequest).then(valor => {
                            if (formElement) {
                                formElement.reset();
                            }
                            processarFuncoesDeEvento(props.registroParaEdicao?.id!);
                            props.onSubmit(valor);
                            setLoading(false);
                        }, (error) => {
                            const errors = error.data?.errors;
                            if (error.status === 400 && errors) {
                                setError(Object.keys(errors).map(key => {
                                    return {
                                        name: key,
                                        type: "ValidationError" + key,
                                        message: String(errors[key])
                                    }
                                }));
                            }
                            setLoading(false);
                        });
                    } else {
                        ApiService.post("/fonte-de-dados/valores/" + props.fonteDeTrabalho.id, data).then(valor => {
                            if (formElement) {
                                formElement.reset();
                            }
                            processarFuncoesDeEvento(valor?.id);
                            props.onSubmit(valor);
                            setLoading(false);
                        }, (error) => {
                            const errors = error.data?.errors;
                            if (error.status === 400 && errors) {
                                setError(Object.keys(errors).map(key => {
                                    return {
                                        name: key,
                                        type: "ValidationError" + key,
                                        message: String(errors[key])
                                    }
                                }));
                            }
                            setLoading(false);
                        });
                    }
                } else {
                    setLoading(false);
                }
            }
        })}>
            {fields.map(field => {
                    const campo = props.fonteDeTrabalho.campos.find(x => x.id === field.name);
                    if (campo && campo.metaDataFuncao && !field.disabled) {
                        return (
                            <div className="row">
                                <div className="col-md-9">
                                    <FieldJsonForm watch={watch} field={field} errors={errors} register={register}
                                                   key={field.name}
                                                   setValue={setValue}/>
                                </div>
                                <div className="col-md-3 pt-3">
                                    <button type="button" className="btn btn-info btn-block mt-2"
                                            disabled={!!funcoesEmProcessamento.find(x => x === field.name)}
                                            onClick={async () => {
                                                setFuncoesEmProcessamento(f => {
                                                    return [...f, field.name]
                                                })
                                                try {
                                                    const linha = {
                                                        'id': props.registroParaEdicao?.id
                                                    } as any;
                                                    for (let i = 0; i < props.fonteDeTrabalho.campos.length; i++) {
                                                        const c = props.fonteDeTrabalho.campos[i];
                                                        linha[c.nome] = await CampoHelpers.obterCampoParaFuncao(props.fontes, c, getValues());
                                                    }
                                                    setValue(campo?.id?.toString() || "", await CampoHelpers.processarFuncao(props.fontes, String(campo?.metaDataFuncao), linha));
                                                    setFuncoesEmProcessamento(f => {
                                                        return [...f].filter(x => x !== field.name)
                                                    })
                                                } catch (e) {
                                                    setError(field.name, "validation", "Falha ao processar função!");
                                                } finally {
                                                    setFuncoesEmProcessamento(f => {
                                                        return [...f].filter(x => x !== field.name)
                                                    })
                                                }
                                            }}>
                                        {!funcoesEmProcessamento.find(x => x === field.name) && <>Calc. Função</>}
                                        {!!funcoesEmProcessamento.find(x => x === field.name) &&
                                            <i className="fa fa-spinner fa-pulse"/>}
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    if (campo && campo.fonte?.campos?.length && !field.disabled && !field.readonly) {
                        field.extraFilter = (onSelected, onHide, valores) => <FiltroColunaDeRelacionamento
                            campo={campo}
                            fontes={props.fontes}
                            onSelect={onSelected}
                            valores={valores}
                            multiSelecao={campo.multivalor}
                        />
                        return (<FieldJsonForm watch={watch} field={field} errors={errors} register={register}
                                               key={field.name + (field.key ? field.key : "")}
                                               setValue={setValue}/>);
                    }

                    return (<FieldJsonForm
                            watch={watch} field={field} errors={errors} register={register}
                            key={field.name + (field.key ? field.key : "")}
                            setValue={setValue}/>
                    )
                        ;
                }
            )
            }
            <div className="row">
                <div className="col-6">
                    {!loading && props.children}
                </div>
                <div className="col-6">
                    <p className="text-right">
                        <button className="btn btn-primary" type="submit"
                                disabled={loading || funcoesEmProcessamento.length > 0}>
                            {!loading ? "Salvar" : <><i className="fa fa-spinner fa-pulse"/> Salvando...</>}
                        </button>
                    </p>
                </div>
            </div>
        </form>
    );
}
