import React, {useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';

registerLocale('pt', pt);

export function DataPickerRangeFilter(props: { defaultValue?: { startDate: Date | null, endDate: Date | null }, onChange: (dates?: { startDate: Date | null, endDate: Date | null }) => void }) {
    const [startDate, setStartDate] = useState<Date | null | undefined>(props.defaultValue && props.defaultValue.startDate ? new Date(props.defaultValue.startDate) : undefined);
    const [endDate, setEndDate] = useState<Date | null | undefined>(props.defaultValue && props.defaultValue.endDate ? new Date(props.defaultValue.endDate) : undefined);
    const [show, setShow] = useState(false)
    const defaultValue = props.defaultValue && props.defaultValue.startDate === null && props.defaultValue.endDate === null ? "Todos registros sem data" : `${startDate ? new Date(startDate).toLocaleDateString('pt-br') : '-'} até ${endDate ? new Date(endDate).toLocaleDateString('pt-br') : '-'}`
    const [value, setValue] = useState(defaultValue);
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setValue(`${start ? new Date(start).toLocaleDateString('pt-br') : '-'} até ${end ? new Date(end).toLocaleDateString('pt-br') : '-'}`)
            setShow(false);
            props.onChange({
                startDate: start,
                endDate: end
            })
        }
    };
    return <>
        <div className="input-group">
            <div className="input-group-prepend">
                <button className="input-group-text" onClick={() => setShow(s => !s)}>
                    <i className="oi oi-calendar"/>
                </button>
            </div>
            <input readOnly type="text" className="form-control bg-white"
                   value={value}/>
            <div className="input-group-append">
                <button className="input-group-text" onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                    props.onChange()
                    setValue(`- até -`);
                }}>
                    <i className="oi oi-circle-x"/>
                </button>
            </div>
        </div>
        <div className={"position-absolute mt-5 bg-white" + (show ? "" : " d-none")} style={{zIndex: 1}}>
            <button className="btn btn-warning btn-block" onClick={() => {
                setValue(`Todos registros sem data`);
                setShow(false);
                props.onChange({
                    startDate: null,
                    endDate: null
                })
            }}>
                Exibir registros sem data
            </button>
            <div className="w-100 text-center">
            </div>
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                dateFormat="dd/MM/yyyy"
                locale="pt"
            />
        </div>
    </>
}
