import React, {PropsWithChildren, useCallback, useMemo, useState,} from "react";
import {Button, Col, Modal, ProgressBar, Row} from "react-bootstrap";
import {useApiGet} from "../hooks/useApi";
import {IFonteDeDados} from "../models/FonteDeDados";
import moment from "moment";
import {ApiService} from "../services/ApiService";
import * as queryString from "query-string";

export function ButtonExport({
                                 linkExportXlsx,
                                 fonte
                             }: PropsWithChildren<{
    fonte?: IFonteDeDados;
    linkExportXlsx: string
}>) {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const {data, refetch} = useApiGet<any>("/exportacoes", {
        refetchOnWindowFocus: false,
        enabled: show,
        refetchInterval: 5000,
        params: {
            fonte: fonte?.id
        }
    });
    const hasAnyExportInProgress = useMemo(() => data?.some((linha: any) => !(["CANCELADO", "CONCLUIDO", "ERRO"].some(x => x == linha.status))), [data]);
    const statusDisplay = useCallback((exportacao: any) => {
        switch (exportacao.status) {
            case "PENDENTE":
                return "Aguardando processamento";
            case "PROCESSANDO":
                return "Gerando arquivo de exportação";
            case "CONCLUIDO":
                return "Exportação concluída";
            case "ERRO":
                return "Erro na exportação";
            case "CANCELADO":
                return "Exportação cancelada";
            case "FINALIZANDO":
                return "Finalizando exportação, aguarde...";
            default:
                return exportacao.status
        }
    }, []);
    return (
        <div className="d-inline-block">
            <Button color="info" onClick={() => setShow(true)}>
                <i className={"fa fa-download"}/> Exportar
            </Button>
            <Modal
                show={show}
                size={"xl"}
                onHide={() => {
                    setShow(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex justify-content-between flex-row w-100">
                        Exportações de {fonte?.nome}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-right">
                        <Button
                            className="btn btn-primary"
                            onClick={() => {
                                setLoading(true);
                                const [path, params] = linkExportXlsx.split("?");
                                ApiService.getAll(path, queryString.parse(params)).then(() => {
                                    refetch();
                                    setLoading(false);
                                })
                            }}
                            size="sm"
                            disabled={loading || hasAnyExportInProgress}
                        >
                            {(loading || hasAnyExportInProgress) && <i className="fas fa-spinner fa-pulse"/>} Iniciar
                            nova
                            exportação
                        </Button>
                    </p>
                    <hr/>
                    <Row className="font-weight-bold text-center">
                        <Col xs={4}>Solicitação</Col>
                        <Col xs={4}>Status</Col>
                        <Col xs={4}>Options</Col>
                    </Row>
                    <hr/>
                    {data?.length === 0 && <p>Nenhuma exportação realizada </p>}
                    {data && data.map((linha: any, index: number) => {
                        return <Row key={linha.id}
                                    style={{minHeight: "50px"}}
                                    className={"align-items-center border-bottom py-2" + (index % 2 == 0 ? " bg-secondary" : "")}>
                            <Col xs={4}>{moment(linha.createdAt).format("LLL")}</Col>
                            <Col xs={4}>
                                {statusDisplay(linha)}
                                {linha.status === "PROCESSANDO" && <>
                                    - <b>{linha.progresso}%</b>
                                    <br/>
                                    <ProgressBar
                                        color="primary"
                                        min={0}
                                        max={100}
                                        now={linha.progresso}
                                        style={{width: "100%"}}/>
                                </>}
                            </Col>
                            <Col xs={4} className="text-center">
                                {linha.status === "CONCLUIDO" && (
                                    <a
                                        href={linha.urlArquivo}
                                        download={linha.urlArquivo}
                                        className="btn btn-success btn-sm"
                                        target="_blank"
                                    >
                                        <i className={"fa fa-download"}/> Download
                                    </a>
                                )}
                                {(!(["CONCLUIDO", "ERRO", "CANCELADO"].some(x => x === linha.status))) && (
                                    <Button
                                        className="btn-sm btn btn-danger"
                                        onClick={() => {
                                            ApiService.delete("/exportacoes", linha.id).then(() => {
                                                refetch();
                                            })
                                        }}
                                    >
                                        <i className={"fa fa-close"}/> Cancelar
                                    </Button>
                                )}
                            </Col>
                        </Row>;
                    })}
                </Modal.Body>
            </Modal>
        </div>
    );
}
