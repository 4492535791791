import {IUsuario} from "../models/Usuario";
import {ActionSessao} from "../reducers/SessaoStore";


export const login = (usuario: IUsuario) : ActionSessao => {
    return {
        type: "LOGIN",
        usuario: usuario
    };
};

export const logout = () : ActionSessao => {
    return {
        type: "LOGOUT"
    };
};
