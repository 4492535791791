import React from "react";
import {IEvento, IFonteDeDados} from "../models/FonteDeDados";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";
import {useForm} from "react-hook-form";

export function FormularioEventoComponente(props: { fontes: IFonteDeDados[], evento?: IEvento, onSubmit: (data: any, errorCallback: (erros: any) => void) => void, onCancel: () => void }) {
    const choicesQuanto = [
        {value: "onCreate", label: "Quando criar um registro"},
        {value: "onUpdate", label: "Quando editar um registro"},
        {value: "onDelete", label: "Quando deletar um registro"},
    ];

    const jsonFromsCampos: IJsonFromField[] = [
        {
            name: "nome",
            label: "Nome do evento",
            placeholder: "Informe o nome que deseja",
            type: "text",
            validators: {
                required: {
                    value: true,
                    message: "Nome é obrigatório"
                }
            },
            valueDefault: props.evento?.nome
        }, {
            name: "metaDataFuncao",
            label: "Função",
            placeholder: "Informe uma função",
            type: "text-area",
            validators: {
                required: {
                    value: true,
                    message: "Função é obrigatório"
                }
            },
            valueDefault: props.evento?.metaDataFuncao
        },
        {
            name: "quando",
            label: "Quando executar?",
            type: "multi-select",
            choices: choicesQuanto,
            validators: {
                required: {
                    value: true,
                    message: "Relação é obrigatório"
                }
            },
            valueDefault: choicesQuanto.filter(x => (props.evento as any)?.[x.value])
        }
    ];


    const {register, handleSubmit, watch, errors, setError, setValue} = useForm(
        {
            validateCriteriaMode: "all",
            defaultValues: props.evento ? {
                quando: choicesQuanto.filter(x => (props.evento as any)[x.value]),
                metaDataFuncao: props.evento.metaDataFuncao,
                nome: props.evento.nome,
            } : undefined
        }
    );

    let submitForm = handleSubmit((data) => {
        props.onSubmit(data, (erros: any) => {
            if (erros) {
                setError(erros)
            }
        });
    });

    return (
        <>
            <form onSubmit={submitForm}>
                {jsonFromsCampos.map(field => {
                    return (
                        <FieldJsonForm watch={watch} field={field} errors={errors} register={register} key={field.name}
                                       setValue={setValue}/>);
                })}
                <div className="row">
                    < div className="col-6">
                        <p className="text-left">
                            <button className="btn btn-warning" type="button" onClick={() => {
                                props.onCancel();
                            }}>
                                Cancelar
                            </button>
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="text-right">
                            <button className="btn btn-primary" type="submit">
                                {props.evento ? 'Salvar' : 'Adicionar Filtro'}
                            </button>
                        </p>
                    </div>
                </div>
            </form>
        </>
    );
}
