import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {IDashboard, IFiltro} from "../models/Dashboard";
import {FormularioDashboard} from "./FormularioDashboard";
import {CampoHelpers, MetaDataColuna} from "../helpers/CampoHelpers";


export function ButtonEditarDashboard(props: { onEdit: (dashboard: IDashboard) => void, projeto: IProjeto, fontes: IFonteDeDados[], dashboard: IDashboard }) {

    const [show, setShow] = useState(false);

    const handlerSubmit = (data: any, callbackError: (error: any) => void) => {
        const filtros = [...(data.filtros || [])]
        const metaDatas = props.fontes.find(x => x.id === data.fonteId)?.campos.reduce((previousValue, currentValue) => {
            return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
        }, {} as { [key: string]: MetaDataColuna })
        const dataSend = {
            ...props.dashboard,
            ...data,
            filtros: filtros.map<IFiltro>(x => {
                const tipo = metaDatas ? metaDatas[x].tipo : ""
                return {
                    campo: x,
                    tipo: tipo as any,
                } as IFiltro
            })
        };
        ApiService.put("/dashboards/" + props.projeto.id, props.dashboard.id, dataSend).then(dash => {
            props.onEdit(dash);
            setShow(false);
        }, (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
                callbackError(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: "ValidationError" + key,
                        message: String(errors[key])
                    }
                }));
            }
        })
    }

    return (<div className="d-inline-block">
        <button className="btn btn-primary" onClick={() => setShow(true)}><i
            className={"fa fa-pencil"}/>
        </button>
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && <FormularioDashboard fontes={props.fontes} dashboardEdicao={props.dashboard} onCancel={() => {
                    setShow(false);
                }} onSubmit={(data, onErr) => {
                    handlerSubmit(data, onErr);
                }}/>}
            </Modal.Body>
        </Modal>
    </div>);
}
