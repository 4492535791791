import React, {PropsWithChildren} from "react";
import {useForm} from "react-hook-form";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";

const defaultProps: IJsonFromProps = {
    onSubmit: () => {
    },
    textSubmitButton: "Submit",
    name: "",
    fields: [],
};

export function JsonForm(props: PropsWithChildren<IJsonFromProps>) {

    const {register, handleSubmit, watch, errors, setError, setValue} = useForm(
        {
            validateCriteriaMode: "all"
        }
    );
    const localProps: IJsonFromProps = Object.assign(defaultProps, props);

    localProps.fields.forEach(x => {
        if (x.onChange)
            x.onChange(watch(x.name));
    });

    let formElement: HTMLFormElement | null;
    return (
        <form name={localProps.name} ref={(el) => {
            formElement = el
        }} onSubmit={handleSubmit((data) => {
            localProps.onSubmit(data, erros => {
                if (erros) {
                    setError(erros)
                }
            }, formElement);
        })}>
            {props.fields.map(field => {
                return (<FieldJsonForm watch={watch} field={field} errors={errors} register={register} key={field.name}
                                       setValue={setValue}/>);
            })}
            <div className="row">
                <div className="col-6">
                    {props.children}
                </div>
                <div className="col-6">
                    <p className="text-right">
                        <button className="btn btn-primary" type="submit" disabled={props.loading}>
                            {props.loading? <i className="fa fa-spinner fa-pulse"/> : localProps.textSubmitButton}
                        </button>
                    </p>
                </div>
            </div>
        </form>
    );
}

export interface IJsonFromProps {
    name: string;
    fields: IJsonFromField[];
    onSubmit: (fieldsValues: { [name: string]: any }, errorCallback?: (erros: { name: string, type: string, message: string }[]) => void, formEl?: HTMLFormElement | null) => void;
    textSubmitButton?: string,
    loading?: boolean
}
