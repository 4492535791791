import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers';
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    return createStore(reducers(history), {},
        composeEnhancers(applyMiddleware(...middlewares)));
}
export {history};
