import React, {useCallback, useEffect, useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal, ProgressBar} from "react-bootstrap";
import {IArquivo} from "../models/Arquivos";
import {ApiService} from "../services/ApiService";
import filesize from "filesize";
import {Loading} from "./Loading";
import {useDropzone} from "react-dropzone";
import axios from 'axios'


export function ButtonArquivosFonte(props: { fonte: IFonteDeDados, registroId: string, usuarioPodeExcluir?: boolean, usuarioPodeInserir?: boolean }) {

    const [show, setShow] = useState(false);
    const [arquivos, setArquivos] = useState<IArquivo[]>();
    const [uploads, setUploads] = useState<{ arquivo: string, id: number, progresso: number, error?: boolean }[]>([])
    const url = `/arquivos/${props.fonte.id}/${props.registroId}/`;
    useEffect(() => {
        if (arquivos === undefined && show) {
            ApiService.getAll(url).then(arqs => {
                setArquivos(arqs);
            }, () => {
                setArquivos([]);
            })
        }
    }, [arquivos, show, url]);

    const handlerSubmit = useCallback(async (file: File, index: number) => {
        const formPayload = new FormData()
        formPayload.append('file', file)
        formPayload.append('nome', file.name)
        formPayload.append('tamanho', String(file.size))
        setUploads(s => {
            return [...s, {
                arquivo: file.name,
                progresso: 0,
                id: index
            }]
        })
        try {
            let csrf = ApiService.getCookie('csrftoken');
            await axios({
                baseURL: ApiService.BASE_URL,
                url: url,
                method: 'post',
                data: formPayload,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...(csrf ? {'X-CSRFToken': csrf} : {})
                },
                onUploadProgress: progress => {
                    const {loaded, total} = progress
                    const percentageProgress = Math.floor((loaded / total) * 100)
                    setUploads(s => {
                        const lista = [...s];
                        const idx = s.findIndex(x => x.id === index && x.arquivo === file.name);
                        if (idx > -1) {
                            lista[idx].progresso = percentageProgress;
                        }
                        return lista;
                    })
                },
            }).then(d => {
                if (d.status === 201) {
                    setArquivos(s => {
                        return s ? [...s, d.data] : [d.data]
                    })
                }
            })
            setUploads(s => {
                const lista = [...s];
                const idx = s.findIndex(x => x.id === index && x.arquivo === file.name);
                if (idx > -1) {
                    lista.splice(idx, 1)
                }
                return lista;
            })
        } catch (error) {
            setUploads(s => {
                const lista = [...s];
                const idx = s.findIndex(x => x.id === index && x.arquivo === file.name);
                if (idx > -1) {
                    lista[idx].error = true;
                }
                return lista;
            })
        }
    }, [url])

    const onDrop = useCallback((acceptedFiles) => {
            if (handlerSubmit) {
                acceptedFiles.forEach((file: File, index: number) => {
                    handlerSubmit(file, index);
                })
            }
        }, [handlerSubmit]
    );

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (<div className="d-inline-block">
        <button className="btn btn-link text-black-50 btn-sm" onClick={() => setShow(true)}>
            <i className="oi oi-paperclip"/>
        </button>
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
            setArquivos(undefined);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Arquivos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.usuarioPodeInserir && <div {...getRootProps()} className="dropZone">
                    <input {...getInputProps()} />
                    <p>Arraste seus arquivos ou clique para selecionar</p>
                </div>}
                {arquivos && <table className="table">
                    <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Tamanho</th>
                        <th>Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    {arquivos.map(a => {
                        return <tr key={a.id}>
                            <td>{a.nome}</td>
                            <td>{filesize(a.tamanho)}</td>
                            <td>
                                <a className='btn btn-link text-primary' href={a.url} target="_blank"
                                   rel="noopener noreferrer">
                                    <i className="oi oi-data-transfer-download"/>
                                </a>
                                {props.usuarioPodeExcluir &&
                                    <button className='btn btn-link text-danger' onClick={() => {
                                        if (window.confirm('Deseja relamente excluir esse arquivo? Essa operação não é reversivel!')) {
                                            ApiService.delete(url, a.id).then(() => {
                                                setArquivos(x => {
                                                    return x ? [...x.filter(d => d.id !== a.id)] : x
                                                });
                                            });
                                        }
                                    }}>
                                        <i className="oi oi-trash"/>
                                    </button>}
                            </td>
                        </tr>
                    })}
                    {uploads.map(a => {
                        return <tr key={a.id + a.arquivo}>
                            <td>{a.arquivo}</td>
                            <td colSpan={2}>
                                {!a.error && <ProgressBar color="primary" min={0} max={100} now={a.progresso}
                                                          style={{width: "100%"}}/>}
                                {a.error && <p className="text-danger">Erro ao enviar arquivo!</p>}
                            </td>
                        </tr>
                    })}
                    {arquivos.length === 0 && uploads.length === 0 && <tr>
                        <td colSpan={3}>Nenhum arquivo anexado!</td>
                    </tr>}
                    </tbody>
                </table>}
                {!arquivos && <div style={{height: "200px"}}>
                    <Loading/>
                </div>}
            </Modal.Body>
        </Modal>
    </div>);
}
