import React from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {FieldJsonForm, IJsonFromField} from "./FieldJsonForm";
import {useForm} from "react-hook-form";
import {CampoHelpers} from "../helpers/CampoHelpers";
import {IComponente} from "../models/Dashboard";

export function FormularioFiltroComponente(props: { fontes: IFonteDeDados[], componente: IComponente, onSubmit: (data: any, errorCallback: (erros: any) => void) => void, onCancel: () => void}) {
    const choicesFiltros = CampoHelpers.obterEscolhasDeCamposPorFonte({
        fontes: props.fontes,
        fonteId: props.componente.fonteId.toString()
    });
    const relacaoChoices = [
        {
            label: "Contém Valor",
            value: "CONTEM_VALOR"
        },
        {
            label: "Não Contém Valor",
            value: "NAO_CONTEM_VALOR"
        }
    ];
    const jsonFromsCampos: IJsonFromField[] = [
        {
            name: "campo",
            label: "Coluna de filtro",
            placeholder: "Informe uma coluna que deseja",
            type: "select",
            choices: choicesFiltros,
            validators: {
                required: {
                    value: true,
                    message: "Coluna é obrigatório"
                }
            }
        },
        {
            name: "relacao",
            label: "Relação",
            type: "select",
            choices: relacaoChoices,
            validators: {
                required: {
                    value: true,
                    message: "Relação é obrigatório"
                }
            }
        }
    ];


    const {register, handleSubmit, watch, errors, setError, setValue} = useForm(
        {
            validateCriteriaMode: "all"
        }
    );

    let submitForm = handleSubmit((data) => {
        props.onSubmit(data, (erros: any) => {
            if (erros) {
                setError(erros)
            }
        });
    });

    return (
        <>
            <form onSubmit={submitForm}>
                {jsonFromsCampos.map(field => {
                    return (
                        <FieldJsonForm watch={watch} field={field} errors={errors} register={register} key={field.name}
                                       setValue={setValue}/>);
                })}
                <div className="row">
                    < div className="col-6">
                        <p className="text-left">
                            <button className="btn btn-warning" type="button" onClick={() => {
                                props.onCancel();
                            }}>
                                Cancelar
                            </button>
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="text-right">
                            <button className="btn btn-primary" type="submit">
                                Adicionar Filtro
                            </button>
                        </p>
                    </div>
                </div>
            </form>
        </>
    );
}
