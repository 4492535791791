import React, {useCallback} from "react";
import {PropsProjetoPages} from "./ProjetoPage";
import {LayoutProjeto} from "../../componets/LayoutProjeto";
import {ButtonAddDashboard} from "../../componets/ButtonAddDashboard";
import {useDispatch} from "react-redux";
import {setDashboards} from "../../actions/ProjetoActions";
import {ButtonEditarDashboard} from "../../componets/ButtonEditarDashboard";
import {Link} from "react-router-dom";
import {PopupTexto} from "../../componets/PopupTexto";
import {JsonForm} from "../../componets/JsonForm";
import {ApiService} from "../../services/ApiService";
import {IUsuario} from "../../models/Usuario";
import {IDashboard} from "../../models/Dashboard";


export function ProjetoConfigDashboardPage(props: PropsProjetoPages) {
    const dispatch = useDispatch();

    const updateDispatchDashboard = (dash: IDashboard) => {
        const dashs = [...props.dashboards];
        const indx = dashs.findIndex(x => x.id === dash.id);
        if (indx > -1) {
            dashs[indx] = dash
            dispatch(setDashboards(dashs, props.projeto.id));
        }
    }

    const atualizarDashboard = (dash: IDashboard) => {
        ApiService.put("/dashboards/" + props.projeto.id, dash.id, {
            ...dash,
            usuarios: dash.usuarios.map(x => x.id)
        }).then(d => {
            updateDispatchDashboard(d);
        })
    }

    const removerDash = (dash: IDashboard) => {
        if (window.confirm("Deseja realmente remover essa dashboard? Essa operação não poderá ser revertida!")) {
            ApiService.delete("/dashboards/" + props.projeto.id, dash.id).then(() => {
                const dashs = [...props.dashboards];
                dispatch(setDashboards([...dashs.filter(x => x.id !== dash.id)], props.projeto.id));
            });
        }
    }

    const getUsuarios = useCallback(filter => {
        return ApiService.getAll("/usuarios", {
            filter: filter,
            projeto_id: props.projeto.id
        }).then((usuarios: IUsuario[]) => {
            return usuarios.map((u: IUsuario) => {
                return {
                    label: `Nome: ${u.nome} | Email: ${u.email}`,
                    value: u.id,
                }
            })
        });
    }, [props.projeto]);

    return (
        <LayoutProjeto projeto={props.projeto}
                       toggleMenu={props.toggleMenu}
                       headlerToggleMenu={props.headlerToggleMenu}
                       titulo="Configuração de Dashboards" administrador={props.administrador}>
            <div className="ProjetoConfigDashboardPages">
                <div className="row mb-2">
                    <div className="col-8">
                        <p><b>Dashboards</b></p>
                    </div>
                    <div className="col-4 text-right">
                        <ButtonAddDashboard fontes={props.fontes} projeto={props.projeto} onAdd={(dashboard) => {
                            dispatch(setDashboards([...props.dashboards, dashboard], props.projeto.id));
                        }}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Tipo</th>
                            <th>Usuários</th>
                            <th>Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.dashboards.length > 0 && props.dashboards.map((dash) => {
                            return (
                                <tr key={dash.id}>
                                    <td>{dash.nome}</td>
                                    <td>{dash.descricao || "-"}</td>
                                    <td>{dash.tipo || "DYDATA"}</td>
                                    <td>
                                        <PopupTexto textoBtn={`${dash.usuarios ? dash.usuarios.length : 0} usuário(s)`}>
                                            <>
                                                Adicionar novo usuário:<br/>
                                                <JsonForm name={'addUser'} fields={[{
                                                    name: 'usuario',
                                                    label: "Selecione qual usuário deseja inserir",
                                                    placeholder: "Qual usuário deseja inserir?",
                                                    type: "select",
                                                    choices: getUsuarios,
                                                    validators: {
                                                        required: true
                                                    }
                                                }]} textSubmitButton={'Adicionar'} onSubmit={(data => {
                                                    const d = {...dash} as IDashboard
                                                    const [id] = data.usuario.split('|')
                                                    if (!d.usuarios) {
                                                        d.usuarios = [];
                                                    }
                                                    if (d.usuarios && !d.usuarios.find(i => i.id === id))
                                                        d.usuarios.push({
                                                            id: id
                                                        } as any);
                                                    atualizarDashboard(d);
                                                })}/>
                                                <br/>
                                                Usuários adicionados:
                                                <hr/>
                                                <div className='table-responsive'>
                                                    <table className='table'>
                                                        <thead>
                                                        <tr>
                                                            <td>Usuário</td>
                                                            <td>Ação</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {dash.usuarios && dash.usuarios.map(x => {
                                                            return <tr key={x.id}>
                                                                <td>{x.nome}</td>
                                                                <td>
                                                                    <button className='btn btn-danger' onClick={() => {
                                                                        if (window.confirm('Deseja relamente remover esse usuário dessa dasbhoard?')) {
                                                                            const d = {...dash} as IDashboard
                                                                            d.usuarios = (d.usuarios || []).filter(i => i.id !== x.id);
                                                                            atualizarDashboard(d);
                                                                        }
                                                                    }}>
                                                                        <i className='fa fa-trash'/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        })}
                                                        {dash.usuarios && dash.usuarios.length === 0 && <tr>
                                                            <td colSpan={2}>Nenhum usuário adicionado</td>
                                                        </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        </PopupTexto>
                                    </td>
                                    <td>
                                        <div className="btn-group">
                                            <ButtonEditarDashboard fontes={props.fontes} onEdit={(dash) => {
                                                updateDispatchDashboard(dash);
                                            }} projeto={props.projeto} dashboard={dash}/>
                                            {(!dash.tipo || dash.tipo === "DYDATA") &&
                                                <Link to={dash.id} className='btn btn-light'>
                                                    <i className="fas fa-chart-line"/>
                                                </Link>}
                                            <button className='btn btn-danger' onClick={() => {
                                                removerDash(dash);
                                            }}>
                                                <i className="fas fa-trash"/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        {props.dashboards.length === 0 && <tr>
                            <td colSpan={4}>
                                Nenhuma dashboard cadastrada!
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutProjeto>
    );
}
