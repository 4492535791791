import React, {useState} from "react";
import {IFonteDeDados} from "../models/FonteDeDados";
import {Modal} from "react-bootstrap";
import {ApiService} from "../services/ApiService";
import {IProjeto} from "../models/Projeto";
import {IDashboard, IFiltro} from "../models/Dashboard";
import {CampoHelpers, MetaDataColuna} from "../helpers/CampoHelpers";
import {FormularioFiltroDashboard} from "./FormularioFiltroDashboard";


export function ButtonAddEditFiltroDashboard(props: { onAdd: (dashboard: IDashboard) => void, projeto: IProjeto, dashboard: IDashboard, fontes: IFonteDeDados[], filtroEditar?: IFiltro }) {

    const [show, setShow] = useState(false);

    const handlerSubmit = (data: any, callbackError: (error: any) => void) => {
        if (!!props.dashboard.filtros?.filter(x => x.id !== props.filtroEditar?.id).find(x => x.campo === data.campo && data.fonteId === x.fonteId)) {
            callbackError([{
                name: "campo",
                type: "ValidationErrorCampo",
                message: "A coluna informada já está inserida e só pode ser selecionada uma vez!"
            }])
            return;
        }
        const metaDatas = props.fontes.find(f => f.id === data.fonteId)?.campos.reduce((previousValue, currentValue) => {
            return {...previousValue, ...CampoHelpers.obterMetaDataDeCamposPorFonte(currentValue)};
        }, {} as { [key: string]: MetaDataColuna });
        const tipo = metaDatas ? metaDatas[data.campo].tipo : undefined;
        const dataSend = {
            ...props.dashboard
        }
        if (props.filtroEditar) {
            const inx = props.dashboard.filtros.findIndex(x => x.id === props.filtroEditar?.id)
            props.dashboard.filtros[inx] = {
                ...props.filtroEditar,
                titulo: data.titulo,
                campo: data.campo,
                tipo: tipo as any,
            } as IFiltro
        } else {

            dataSend.filtros?.push({
                titulo: data.titulo,
                fonteId: data.fonteId,
                campo: data.campo,
                tipo: tipo as any,
            } as IFiltro)
        }
        ApiService.put("/dashboards/" + props.projeto.id, props.dashboard.id, dataSend).then(dash => {
            props.onAdd(dash);
            setShow(false);
        }, (error) => {
            const errors = error.data?.errors;
            if (error.status === 400 && errors) {
                callbackError(Object.keys(errors).map(key => {
                    return {
                        name: key,
                        type: "ValidationError" + key,
                        message: String(errors[key])
                    }
                }));
            }
        })
    }

    return (<div className="d-inline-block">
         <button className="btn btn-primary" onClick={() => setShow(true)}>
             {!props.filtroEditar && <><i
            className={"fa fa-plus"}/> Novo Filtro</>}
             {props.filtroEditar && <i
             className={"fa fa-pencil"}/>}
        </button>
        <Modal show={show} size={"xl"} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && <FormularioFiltroDashboard filtroEdicao={props.filtroEditar} dashboard={props.dashboard} fontes={props.fontes} onCancel={() => {
                    setShow(false);
                }} onSubmit={(data, onErr) => {
                    handlerSubmit(data, onErr);
                }}/>}
            </Modal.Body>
        </Modal>
    </div>);
}
