import {IComponente, IDashboard} from "../models/Dashboard";
import React, {useEffect, useState} from "react";
import {ApiService} from "../services/ApiService";
import {Bar} from "react-chartjs-2";

export function GraficoBarrasDashboard(props: { dashboard: IDashboard, componente: IComponente, filtros?: { [campo: string]: any } }) {
    const [valor, setValor] = useState<any[]>();
    const [legenda, setLegenda] = useState(false);
    const [tentarNovamente, setTentarNovamente] = useState(false);
    useEffect(() => {
        if (props.componente.id && props.dashboard.id) {
            const likeFilters = Object.keys(props.filtros || {});
            const p = {
                likeFilters: likeFilters,
                ...Object.keys(props.filtros || {}).reduce((pre, pos) => {
                    pre[pos] = (props.filtros || {})[pos];
                    return pre;
                }, {} as any),
            };
            setValor(undefined);
            ApiService.get("/dashboards/" + props.dashboard.id, props.componente.id, p).then(valores => {
                setValor(valores);
                setTentarNovamente(false);
            }, () => {
                setTentarNovamente(true);
            });
        }
    }, [props.filtros, props.componente.id, props.dashboard.id])

    return <div className='card'>
        <div className='card-body text-center bg-light' style={{
            maxHeight: "400px"
        }}>
            <Bar
                data={{
                    datasets: [
                        {
                            data: valor?.filter(x => !!x.eixo).map(x => {
                                if (props.componente.eixo?.tipo === "DATE") {
                                    const [mes, ano] = x.eixo.split("-")
                                    return {
                                        y: x.valor,
                                        x: new Date(ano, mes, 1),
                                    }
                                } else {
                                    return {
                                        y: x.valor,
                                        labels: x.legendas,
                                        x: x.eixo,
                                    }
                                }
                            })
                        }
                    ]
                }}
                options={{
                    maintainAspectRatio: false, scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                unit: 'month'
                            }
                        }]
                    }
                }}
            />
            <p className='d-flex'>
                {props.componente.titulo && <b>
                    {props.componente.titulo}
                </b>}
                <button className='btn btn-sm btn-primary ml-auto' onClick={() => setLegenda(l => !l)}>
                    {legenda && "Ocultar Legenda"}
                    {!legenda && "Exibir Legenda"}
                </button>
            </p>
            {tentarNovamente && <h1 className='mt-2'>
                <button className='btn btn-link text-black-50 btn-sm' onClick={() => {
                    setTentarNovamente(false);
                }}>
                    Tentar novamente
                </button>
            </h1>}
        </div>
    </div>
}
